import React from 'react';

import styles from './FormNumber.module.scss';

import InputIcon from '../../InputIcon/InputIcon';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

const FormNumber = (props) => {
  const {
    label,
    placeholder,
    icon,
    description,
    min,
    value,
    onChange,
    error,
  } = props;

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      <div className={styles.inputContainer}>
        <InputIcon
          type="number"
          placeholder={placeholder}
          icon={icon}
          min={min}
          value={value}
          onChange={(e) => onChange(e)}
        />
      </div>
      {error && (
        <div className="errorContainer">
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};

export default FormNumber;
