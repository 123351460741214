import axios from 'axios';

var backendURL = '';
if (process.env.REACT_APP_MODE === 'LIVE') {
  backendURL = 'https://avid-back-end.herokuapp.com';
} else {
  backendURL = 'https://test-avid-back-end.herokuapp.com';
}

/**
 * Create Stripe Connect account
 * @param {string} query - Object containing email, creatorID and origin
 * @return {Promise} Stripe Connect onboarding url
 */
const createStripeConnectAccount = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${backendURL}/stripe/create-connect-account?email=${query.email}&creator_id=${query.creatorID}&origin=${query.origin}`,
      )
      .then((res) => {
        if (res.status !== 200) {
          reject(res.data.raw);
        } else {
          resolve(res.data.url);
        }
      })
      .catch((error) => {
        console.log('ERROR:', error);
        reject(error);
      });
  });
};

/**
 * Fetch Stripe account
 * @param {string} accountID - Stripe account ID
 * @return {Promise} Stripe account
 */
const fetchStripeAccount = async (accountID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${backendURL}/stripe/fetch-account?account_id=${accountID}`)
      .then((res) => {
        if (res && res.data) {
          resolve(res.data);
          return;
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchAccountLink = async (accountId) => {
  try {
    const response = await axios.get(
      `${backendURL}/stripe/account-link?accountId=${accountId}`,
    );

    return response.data.url;
  } catch (e) {
    throw e;
  }
};

export { createStripeConnectAccount, fetchStripeAccount, fetchAccountLink };
