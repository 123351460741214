import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './screens/App/App';
import * as serviceWorker from './serviceWorker';

import { createStore, StoreProvider } from 'react-context-global-store';

const store = createStore({
  data: {
    creatorClosedStripePrompt: false,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
