import * as firebase from 'firebase/app';
import 'firebase/auth';

/**
 * Sign in user
 * @param {string} email - User email address
 * @param {string} password - User password
 * @return {Promise} If success it returns an object with auth and the user object
 */
const signIn = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Sign out user
 * @return {Promise} Void if success
 */
const signOut = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Create user in Firebase Auth
 * @param {string} email - User email address
 * @param {string} password - User password
 * @return {Promise} If success it returns the Firebase UserCredential object
 */
const createUserWithEmailAndPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Send password reset email
 * @param {string} email - User email address
 * @return {Promise} Void if success
 */
const sendPasswordResetEmail = (email) => {
  return new Promise((resolve, reject) => {
    var actionCodeSettings = {
      url: 'https://creator.avid.fm/login',
    };
    firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Confirm password reset
 * @param {string} actionCode - The verification code sent to the user via email.
 * @param {string} newPassword - User's new password
 * @return {Promise} Void if success
 */
const confirmPasswordReset = (actionCode, newPassword) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        firebase
          .auth()
          .confirmPasswordReset(actionCode, newPassword)
          .then(() => resolve(email))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

export {
  signIn,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
};
