import React from 'react';

import styles from './FormUploadImage.module.scss';

import Photograph from '../../../assets/img/photograph.svg';
import Upload from '../../../assets/img/upload.svg';
import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

export default class FormUploadImage extends React.Component {
  constructor(props) {
    super(props);

    this.handleBtnClick = this.handleBtnClick.bind(this);
  }

  handleBtnClick = (id) => {
    const { onImageLoaded } = this.props;

    var file = document.getElementById(`file-upload-${id}`).files[0];
    var reader = new FileReader();

    reader.onloadend = (e) => {
      document.getElementById(
        id,
      ).style.backgroundImage = `url(${e.target.result})`;
      if (document.getElementById(`${id}-placeholder`)) {
        document.getElementById(`${id}-placeholder`).style.display = 'none';
      }

      if (onImageLoaded) {
        onImageLoaded(e.target.result);
      }
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { label, description, buttonTitle, error, image } = this.props;
    const id = this.props.id ? this.props.id : 'cover-art';
    const altText = id?.replace(/-/g, ' ');

    return (
      <div className={styles.container}>
        <p className={styles.label}>{label}</p>
        <p className={styles.description}>{description}</p>
        <div
          id={id}
          className={styles.coverImage}
          style={{ backgroundImage: `url(${image})` }}
          onClick={() => {
            const event = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            document.getElementById(`file-upload-${id}`).dispatchEvent(event);
          }}
        >
          {image === null ? (
            <img src={Photograph} alt={altText} id={`${id}-placeholder`} />
          ) : null}
        </div>
        {error && (
          <div className="errorContainer">
            <Warning />
            <span className="errorMessage">{error}</span>
          </div>
        )}
        <label htmlFor={`file-upload-${id}`} className={styles.uploadButton}>
          <img
            src={Upload}
            className={styles.uploadIcon}
            alt={`Upload ${altText}`}
          />
          <span>{buttonTitle}</span>
        </label>
        <input
          id={`file-upload-${id}`}
          type="file"
          accept="image/png,image/jpeg"
          onChange={() => {
            this.handleBtnClick(id);
          }}
        />
      </div>
    );
  }
}
