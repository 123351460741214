import React from 'react';

import styles from './FormThingsToLearn.module.scss';

import Input from '../../Input/Input';

import Plus from '../../../assets/img/plus_secondary.svg';

const FormThingsToLearn = (props) => {
  const { label, description, skills, tip, addSkill, onChange } = props;
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      {description && <p className={styles.description}>{description}</p>}
      <div
        className={styles.inputsContainer}
        style={{ marginTop: description ? null : '8px' }}
      >
        {skills.map((item, index) => {
          return (
            <div className={styles.skillInputContainer} key={item.id}>
              <div
                style={{ width: '100%' }}
                className={`${
                  index % 2 === 0
                    ? styles.negativeMargin
                    : styles.positiveMargin
                }`}
              >
                <Input
                  type="text"
                  placeholder={item.placeholder}
                  hideLabel={true}
                  error={item.error ? item.error : null}
                  value={item.value}
                  onChange={(e) => onChange(e, index)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.addSkill} onClick={addSkill}>
        <img src={Plus} alt="Add more skills" />
        <span className={styles.addSkillLabel}>Add more skills</span>
      </div>
      {tip && <div className={styles.tipContainer}>{tip}</div>}
    </div>
  );
};

export default FormThingsToLearn;
