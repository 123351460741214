import React from 'react';
import styles from './Checkbox.module.scss';

import Check from '../../assets/img/check.svg';

const Checkbox = (props) => {
  const { label, selected, onClick } = props;
  return (
    <div className={styles.container} onClick={onClick ? onClick : null}>
      <div className={styles.checkbox}>
        {selected && <img src={Check} alt="Check" className={styles.icon} />}
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default Checkbox;
