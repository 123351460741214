import React from 'react';
import { Link } from 'react-router-dom';

import styles from './SignIn.module.scss';

import {
  signIn,
  sendPasswordResetEmail,
} from '../../services/FirebaseService/Auth';
import { checkIfCreatorExists } from '../../services/FirebaseService/Database';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';

export default class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      isLoading: false,
      showPasswordReset: false,
      passwordResetSuccess: false,
    };

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleSignIn = (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    this.setState({ isLoading: true });

    if (!email || !password) {
      this.setState({
        error: { message: 'Please enter your email and password' },
        isLoading: false,
      });
      return;
    }

    // Disable this account for Creator Studio
    if (email === 'test@avid.fm') {
      this.setState({
        error: { message: 'Account disabled' },
        isLoading: false,
      });
      return;
    }

    checkIfCreatorExists(email)
      .then(() => {
        signIn(email, password)
          .then(() => {
            // App.js will redirect to the entry point
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.setState({ error: error, isLoading: false });
          });
      })
      .catch((error) => {
        console.log('ERROR', error);
        this.setState({
          error: {
            message: 'User not found. Please try a different email address.',
          },
          isLoading: false,
        });
      });
  };

  handlePasswordReset = (e) => {
    e.preventDefault();

    const { email } = this.state;

    this.setState({ isLoading: true });

    sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          email: '',
          password: '',
          error: '',
          isLoading: false,
          showPasswordReset: false,
          passwordResetSuccess: true,
        });
      })
      .catch((error) => {
        console.log('ERROR', error);
        var passwordResetErrorMessage = error.message;
        if (error.code === 'auth/user-not-found') {
          passwordResetErrorMessage = 'User not found';
        }
        this.setState({
          isLoading: false,
          error: {
            code: error.code,
            message: passwordResetErrorMessage,
          },
        });
      });
  };

  resetState = () => {
    this.setState({
      email: '',
      password: '',
      error: '',
      isLoading: false,
    });
  };

  render() {
    const {
      email,
      password,
      error,
      isLoading,
      showPasswordReset,
      passwordResetSuccess,
    } = this.state;
    return (
      <>
        <Header />
        <div className={styles.formContainer}>
          {!showPasswordReset && !passwordResetSuccess && (
            <form onSubmit={(e) => this.handleSignIn(e)}>
              <h2 className={styles.title}>Log in to your creator account</h2>
              <Input
                label="Email"
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <Input
                label="Password"
                secondaryLabel="Forgot password?"
                placeholder="Enter your password"
                type="password"
                style={{ marginTop: '12px' }}
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
                secondaryLabelOnClick={() => {
                  this.resetState();
                  this.setState({
                    showPasswordReset: true,
                  });
                }}
              />
              {error && <p className={styles.error}>{error.message}</p>}
              <button
                type="submit"
                className={styles.signInButtonContainer}
                onClick={(e) => this.handleSignIn(e)}
              >
                <Button text="Log in" small loading={isLoading} />
              </button>
              <p to="/register" className={styles.signUp}>
                New to AVID? <Link to="/register">Sign up</Link>
              </p>
            </form>
          )}

          {showPasswordReset && !passwordResetSuccess && (
            <form onSubmit={(e) => this.handlePasswordReset(e)}>
              <h2 className={`${styles.title} ${styles.resetPasswordTitle}`}>
                Reset your password
              </h2>
              <p className={styles.description}>
                Enter the email address you use for AVID and we'll send you a
                link to reset your password.
              </p>
              <Input
                label="Email"
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              {error && <p className={styles.error}>{error.message}</p>}
              <button
                type="submit"
                className={styles.signInButtonContainer}
                onClick={(e) => this.handlePasswordReset(e)}
              >
                <Button text="Reset password" small loading={isLoading} />
              </button>
              <p className={styles.signUp}>
                Return to{' '}
                <span
                  onClick={() => {
                    this.resetState();
                    this.setState({ showPasswordReset: false });
                  }}
                >
                  Log in
                </span>
              </p>
            </form>
          )}

          {passwordResetSuccess && (
            <>
              <h2 className={`${styles.title} ${styles.resetPasswordTitle}`}>
                Request sent!
              </h2>
              <p className={styles.description}>
                Check your email and follow the instructions to reset your
                password.
              </p>

              <div
                className={styles.signInButtonContainer}
                onClick={() =>
                  this.setState({
                    showPasswordReset: false,
                    passwordResetSuccess: false,
                  })
                }
              >
                <Button text="Go to Log in" small loading={isLoading} />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
