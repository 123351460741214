import React from 'react';

import styles from './FormCategory.module.scss';

import Select from '../../Select/Select';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

const FormCategory = (props) => {
  const { label, description, options, onChange, error } = props;

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      <div className={styles.selectContainer}>
        <Select
          options={options}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
      {error && (
        <div className="errorContainer">
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};

export default FormCategory;
