import React from 'react';
import { Redirect } from 'react-router-dom';

import styles from './AddEpisode.module.scss';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';

import FormInputText from '../../components/Form/FormInputText/FormInputText';
import FormUpload from '../../components/Form/FormUpload/FormUpload';
import FormNumber from '../../components/Form/FormNumber/FormNumber';
import FormSegments from '../../components/Form/FormSegments/FormSegments';
import FormResources from '../../components/Form/FormResources/FormResources';
import FormCheckbox from '../../components/Form/FormCheckbox/FormCheckbox';
import Toast from '../../components/Toast/Toast';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import {
  fetchCreator,
  addEpisode,
  setEpisodeFile,
  setResourceFile,
} from '../../services/FirebaseService/Database';

import { storeResource } from '../../services/FirebaseService/Storage';

import axios from 'axios';

export default class AddEpisode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canAccessPage: false,
      isLoading: true,
      isEpisodeUploading: false,
      episodeDidFinishUpload: false,
      courseID: this.props.match.params.courseID,
      databaseError: null,
      error: {
        text: '',
        category: '',
      },
      episode: {
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        title: '',
        description: '',
        audioFile: null,
        number: '',
        segments: [
          {
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            title: '',
            start: null,
            end: null,
          },
        ],
        free: false,
      },
      episodeFreeOptions: [
        {
          label: 'Yes',
          value: true,
          selected: false,
        },
        {
          label: 'No',
          value: false,
          selected: true,
        },
      ],
      episodeStatus: null,
    };

    this.save = this.save.bind(this);
    this.addSegment = this.addSegment.bind(this);
    this.handleSegmentChange = this.handleSegmentChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.addFile = this.addFile.bind(this);
  }

  componentDidMount() {
    this.checkIfUserCanAccessPage().then((canAccess) => {
      this.setState({ canAccessPage: canAccess, isLoading: false });
    });
  }

  checkIfUserCanAccessPage = () => {
    const { courseID } = this.props.match.params;
    const { firebaseUser } = this.props;

    return new Promise((resolve, reject) => {
      fetchCreator(firebaseUser.id)
        .then((creator) => {
          if (creator && creator.courses) {
            var courses = creator.courses;
            var coursesIDs = Object.keys(courses);
            if (coursesIDs.includes(courseID)) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          console.log('ERROR', error);
          reject(error);
        });
    });
  };

  scrollToElement = (id) => {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  };

  save = () => {
    const { episode } = this.state;

    if (episode.title === '') {
      this.setState({
        error: {
          text: 'Please enter a title',
          category: 'EPISODE_TITLE',
        },
      });

      this.scrollToElement('episode_title');
      return;
    }
    if (episode.title.length > 80) {
      this.setState({
        error: {
          text: 'The title cannot be longer than 80 characters',
          category: 'EPISODE_TITLE',
        },
      });

      this.scrollToElement('episode_title');
      return;
    }

    if (episode.description === '') {
      this.setState({
        error: {
          text: 'Please enter a description',
          category: 'EPISODE_DESCRIPTION',
        },
      });

      this.scrollToElement('episode_description');
      return;
    }
    if (episode.description.length > 500) {
      this.setState({
        error: {
          text: 'The title cannot be longer than 500 characters',
          category: 'EPISODE_DESCRIPTION',
        },
      });

      this.scrollToElement('episode_description');
      return;
    }

    const fileLimit = 500000000; // 500MB
    if (episode.audioFile && episode.audioFile.size > fileLimit) {
      this.setState({
        error: {
          text: 'The size of the audio file cannot exceed 500MB',
          category: 'EPISODE_UPLOAD',
        },
      });

      this.scrollToElement('episode_audio_file');
      return;
    }

    if (episode.number <= 0 || isNaN(parseInt(episode.number))) {
      this.setState({
        error: {
          text: 'Please enter a valid number',
          category: 'EPISODE_NUMBER',
        },
      });

      this.scrollToElement('episode_number');
      return;
    }

    var segments = episode.segments;
    var errorTitle = segments.find((item) => item.title === '');
    var errorStart = segments.find((item) => item.start === null);
    var errorEnd = segments.find((item) => item.end === null);

    var segmentError = null;

    if (errorTitle) {
      if (errorTitle.start || errorTitle.end)
        segmentError = {
          id: errorTitle.id,
          error: 'Please enter a valid title',
          type: 'TITLE',
        };
    } else if (errorStart) {
      if (errorStart.title) {
        segmentError = {
          id: errorStart.id,
          error: 'Please select a value',
          type: 'START',
        };
      }
    } else if (errorEnd) {
      if (errorEnd.title) {
        segmentError = {
          id: errorEnd.id,
          error: 'Please select a value',
          type: 'END',
        };
      }
    }

    segments.forEach((segment) => {
      if (segment.start && segment.end) {
        var a = `${segment.start.minutes || '00'}:${
          segment.start.seconds || '00'
        }`.split(':');
        var startSeconds = +a[0] * 60 + +a[1];

        var a2 = `${segment.end.minutes || '00'}:${
          segment.end.seconds || '00'
        }`.split(':');
        var endSeconds = +a2[0] * 60 + +a2[1];

        if (startSeconds > endSeconds) {
          segmentError = {
            id: segment.id,
            error: 'Start value cannot be greater than end value',
            type: 'START',
          };
          return;
        }
      }
    });

    if (segmentError) {
      this.setState({
        segmentError: segmentError,
        error: {
          text: '',
          category: '',
        },
      });

      this.scrollToElement('episode_segments');
      return;
    }

    var resourcesError = null;
    var resourceSizeLimit = 20971520; // 20 Megabytes
    var resources = episode.resources;
    if (resources) {
      Object.keys(resources).forEach((key) => {
        var resource = resources[key];
        if (resource.file.size > resourceSizeLimit) {
          resourcesError = {
            id: resource.id,
            error: 'Resources must be under 20mb in size.',
          };
        }
      });
    }

    if (resourcesError) {
      this.setState({
        resourcesError: resourcesError,
        segmentError: null,
        error: {
          text: '',
          category: '',
        },
      });

      this.scrollToElement('episode_resources');
      return;
    }

    this.handleAddEpisode();
  };

  handleAddEpisode = () => {
    const { courseID, episode } = this.state;

    this.setState({
      isEpisodeUploading: true,
      error: {
        text: '',
        category: '',
      },
      segmentError: null,
      resourcesError: null,
    });

    addEpisode(courseID, episode)
      .then(() => {
        var episodeResourcesPromises = [];

        if (episode.resources) {
          episodeResourcesPromises = Object.keys(episode.resources).map(
            (key) => {
              return new Promise((resolve, reject) => {
                const resource = episode.resources[key];
                if (resource.needsToBeAddedToDB) {
                  storeResource(
                    `courses/${courseID}/chapters/${episode.id}/resources/${resource.id}/${resource.file.name}`,
                    resource.file,
                  )
                    .then((url) => {
                      setResourceFile(episode, courseID, resource, url)
                        .then(() => resolve())
                        .catch((error) => {
                          console.log('ERROR', error);
                          reject(error);
                        });
                    })
                    .catch((error) => {
                      console.log('ERROR', error);
                      reject(error);
                    });
                } else {
                  resolve();
                }
              });
            },
          );
        }

        var audioFile = episode.audioFile;

        if (audioFile && audioFile instanceof File) {
          const formData = new FormData();
          formData.append('audio_file', audioFile);
          formData.append(
            'additional_data',
            JSON.stringify({
              course_id: courseID,
              episode_id: episode.id,
              type: 'episode',
            }),
          );
          axios
            .post('https://avid-back-end.herokuapp.com/upload', formData)
            .then(() => {
              setEpisodeFile(courseID, episode.id, episode.audioFile)
                .then(() => {
                  Promise.all(episodeResourcesPromises)
                    .then(() => {
                      this.redirectToCourse();
                    })
                    .catch((error) => {
                      console.log('ERROR', error);
                      this.redirectToCourse();
                    });
                })
                .catch((error) => {
                  console.log('ERROR', error);
                  this.setState({
                    databaseError: error.message,
                    episodeDidFinishUpload: true,
                  });
                });
            })
            .catch((error) => {
              console.log('ERROR', error);
              this.setState({
                episodeDidFinishUpload: true,
                databaseError: error,
              });
            });
        } else {
          Promise.all(episodeResourcesPromises)
            .then(() => {
              console.log('ssss');
              this.redirectToCourse();
            })
            .catch((error) => {
              console.log('ERROR', error);
              this.redirectToCourse();
            });
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
        if (error.code === 'EPISODE_NUMBER_ALREADY_ASSIGNED') {
          this.setState({
            error: {
              text: error.message,
              category: 'EPISODE_NUMBER',
            },
            isEpisodeUploading: false,
          });
        } else {
          console.log('ERROR', error);
          var databaseError = '501';
          if (error.code) {
            databaseError = error.code;
          }
          this.setState({
            databaseError: databaseError,
            episodeDidFinishUpload: true,
          });
        }
      });
  };

  redirectToCourse = () => {
    const { courseID } = this.state;
    setTimeout(() => {
      this.setState({
        episodeDidFinishUpload: true,
        databaseError: null,
      });
    }, 1000);

    setTimeout(() => {
      window.location.href = `/course/${courseID}`;
    }, 2000);
  };

  addSegment = () => {
    const { episode } = this.state;
    var episodeCopy = episode;
    var ID =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    episodeCopy.segments.push({
      id: ID,
      title: '',
      start: null,
      end: null,
    });
    this.setState({
      episode: episodeCopy,
    });
  };

  removeSegment = (segment) => {
    const { episode } = this.state;
    var episodeCopy = { ...episode };
    var index = episodeCopy.segments.findIndex(
      (item) => item.id === segment.id,
    );
    episodeCopy.segments.splice(index, 1);
    this.setState({
      episode: episodeCopy,
    });
  };

  handleSegmentChange = (value, segment, property) => {
    const { episode } = this.state;
    var episodeCopy = episode;
    var segmentIndex = episodeCopy.segments.findIndex(
      (item) => item.id === segment.id,
    );
    episodeCopy.segments[segmentIndex][property] = value;
    this.setState({
      episode: episodeCopy,
    });
  };

  addFile = (file) => {
    const { episode } = this.state;
    var episodeCopy = episode;
    episodeCopy.audioFile = file;
    this.setState({
      episode: episodeCopy,
      episodeStatus: 'success',
    });
  };

  removeFile = () => {
    const { episode } = this.state;
    var episodeCopy = episode;
    episodeCopy.audioFile = null;
    this.setState({ episode: episodeCopy });
  };

  addResource = (file) => {
    const { episode } = this.state;
    var episodeCopy = episode;
    var ID =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var resources = episodeCopy.resources;
    if (!resources) {
      resources = {};
    }
    resources[ID] = {
      id: ID,
      file: file,
      needsToBeAddedToDB: true,
    };
    episodeCopy.resources = resources;
    this.setState({
      episode: episodeCopy,
    });
  };

  removeResource = (resource) => {
    const { episode } = this.state;
    var episodeCopy = { ...episode };
    var resourcesKeys = Object.keys(episodeCopy.resources);
    resourcesKeys.forEach((key) => {
      var resourceObject = episodeCopy.resources[key];
      if (resourceObject.id === resource.id) {
        delete episodeCopy.resources[key];
      }
    });
    this.setState({
      episode: episodeCopy,
    });
  };

  render() {
    const {
      canAccessPage,
      isLoading,
      isEpisodeUploading,
      episodeDidFinishUpload,
      error,
      databaseError,
      segmentError,
      resourcesError,
      episode,
      episodeFreeOptions,
      episodeStatus,
    } = this.state;

    if (isLoading) {
      return <LoadingScreen />;
    }

    if (!canAccessPage) {
      return <Redirect to="my-courses" />;
    }

    return (
      <>
        <Header user />

        {isEpisodeUploading && !episodeDidFinishUpload && (
          <Toast text="Adding your episode, please wait." />
        )}

        {episodeDidFinishUpload && (
          <Toast
            text={
              databaseError
                ? `Error ${databaseError}. Try again, please.`
                : 'Episode successfully added!'
            }
            error={databaseError}
            success={!databaseError}
          />
        )}

        <div
          className={styles.container}
          style={{ marginTop: isEpisodeUploading ? '-90px' : null }}
        >
          <h2 className={styles.title}>Add episode</h2>
          <div className={styles.formsContainer}>
            <div className={styles.formContainer} id="episode_title">
              <FormInputText
                label="Episode title"
                placeholder="Enter your episode title"
                description="Give your episode a name. Make it concise and memorable, up to 80 characters."
                error={error.category === 'EPISODE_TITLE' ? error.text : null}
                value={episode.title}
                onChange={(e) => {
                  var episodeCopy = episode;
                  episodeCopy.title = e.target.value;
                  this.setState({
                    episode: episodeCopy,
                  });
                }}
              />
            </div>

            <div className={styles.formContainer} id="episode_description">
              <FormInputText
                label="Episode description"
                placeholder="Enter your episode description"
                description="Provide a brief summary of what your episode is about."
                long={true}
                error={
                  error.category === 'EPISODE_DESCRIPTION' ? error.text : null
                }
                value={episode.description}
                onChange={(e) => {
                  var episodeCopy = episode;
                  episodeCopy.description = e.target.value;
                  this.setState({
                    episode: episodeCopy,
                  });
                }}
              />
            </div>

            <div className={styles.formContainer} id="episode_audio_file">
              <FormUpload
                label="Episode audio file"
                description="Recommended format is .mp3, up to 500MB in size."
                buttonTitle="Click to upload audio file"
                error={error.category === 'EPISODE_UPLOAD' ? error.text : null}
                file={episode.audioFile}
                addFile={this.addFile}
                removeFile={this.removeFile}
                status={episodeStatus}
              />
            </div>
            <div className={styles.formContainer} id="episode_number">
              <FormNumber
                label="Episode number"
                placeholder="Enter your episode number"
                description="This number defines the order of your episodes."
                icon="#"
                min="1"
                error={error.category === 'EPISODE_NUMBER' ? error.text : null}
                value={episode.number}
                onChange={(e) => {
                  var episodeCopy = episode;
                  episodeCopy.number = e.target.value;
                  this.setState({
                    episode: episodeCopy,
                  });
                }}
              />
            </div>
            <div className={styles.formContainer} id="episode_segments">
              <FormSegments
                label="Episode timestamps (optional)"
                description="A timestamp is a link to a specific point in your episode. Using timestamps makes it easy for learners to skip to parts of an episode you feel are most valuable."
                titlePlaceholder="Enter timestamp title"
                startPlaceholder="00:00:00"
                endPlaceholder="00:00:00"
                addSegment={this.addSegment}
                segments={episode.segments}
                onTitleChange={(e, segment) =>
                  this.handleSegmentChange(e.target.value, segment, 'title')
                }
                onStartChange={(start, segment) => {
                  this.handleSegmentChange(start, segment, 'start');
                }}
                onEndChange={(seconds, segment) =>
                  this.handleSegmentChange(seconds, segment, 'end')
                }
                onRemoveSegment={(segment) => this.removeSegment(segment)}
                error={segmentError ? segmentError : null}
              />
            </div>
            <div className={styles.formContainer} id="episode_resources">
              <FormResources
                label="Episode resources"
                description="Upload helpful resources that relate to the content of this episode. PDF, JPG or PNG under 20mb in size."
                buttonTitle="Upload a resource"
                error={resourcesError ? resourcesError : null}
                resources={episode.resources}
                addResource={this.addResource}
                removeResource={this.removeResource}
              />
            </div>
            <div className={styles.formContainer}>
              <FormCheckbox
                label="Is this a free episode?"
                description="Free episodes help learners to preview your content and teaching style before purchasing your course."
                options={episodeFreeOptions}
                onCheckboxClick={(option) => {
                  var episodeCopy = episode;
                  var episodeFreeOptionsCopy = episodeFreeOptions;

                  episodeFreeOptionsCopy.forEach((item) => {
                    item.selected = false;
                  });

                  var freeOptionIndex = episodeFreeOptions.findIndex(
                    (item) => item.label === option.label,
                  );
                  episodeFreeOptionsCopy[
                    freeOptionIndex
                  ].selected = !episodeFreeOptionsCopy[freeOptionIndex]
                    .selected;

                  var optionSelected = episodeFreeOptionsCopy.find(
                    (item) => item.selected,
                  );
                  episodeCopy.free = optionSelected.value;

                  this.setState({
                    episodeFreeOptions: episodeFreeOptionsCopy,
                    episode: episodeCopy,
                  });
                }}
              />
            </div>
          </div>
          <div
            className={styles.saveButtonContainer}
            onClick={isEpisodeUploading ? null : this.save}
          >
            <Button text="Save" loading={isEpisodeUploading} />
          </div>
        </div>
      </>
    );
  }
}
