import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import moment from 'moment';

import { CSVLink } from 'react-csv';

import styles from './Course.module.scss';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import EmptyBox from '../../components/EmptyBox/EmptyBox';
import Episode from '../../components/Episode/Episode';
import Select from '../../components/Select/Select';
import AnalyticsTable from '../../components/AnalyticsTable/AnalyticsTable';
import CourseStatusAlert from '../../components/CourseStatusAlert/CourseStatusAlert';
import ShareCoursePopup from '../../components/ShareCoursePopup/ShareCoursePopup';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import Plus from '../../assets/img/plus_secondary_white.svg';
import ListeningMusic from '../../assets/img/listening_music.svg';
import { ReactComponent as AVIDSound } from '../../assets/img/avid_sound.svg';
import { ReactComponent as Share } from '../../assets/img/share.svg';

import {
  fetchCreator,
  fetchCourse,
  fetchUser,
  fetchStudentsTransactionsForCourse,
  fetchCourseAnalytics,
  fetchProcess,
  listenForProcess,
  updateCourseStatus,
} from '../../services/FirebaseService/Database';

export default class Course extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canAccessPage: false,
      isLoading: true,
      showChangeCourseStatus: false,
      showShareCourseLink: false,
      shareCourseLink: null,
      sectionSelected: 'Episodes',
      sections: ['Episodes', 'Learners', 'Details'],
      analyticsPeriod: 'All',
      course: {},
      students: [],
      processes: {},
      analytics: {
        listenTime: {
          id: 0,
          value: '0',
          description: 'Course listen time',
        },
        views: {
          id: 1,
          value: '0',
          description: 'Course views',
        },
        plays: {
          id: 2,
          value: '0',
          description: 'Course plays',
        },
        purchases: {
          id: 3,
          value: '0',
          description: 'Course purchases',
        },
        sales: {
          id: 4,
          value: '0',
          description: 'Course sales',
        },
      },
      conversion: [
        {
          id: 0,
          name: 'Visited course page',
          value: '0',
        },
        {
          id: 1,
          name: 'Signed up to receive emails',
          value: '0',
          secondaryValue: '0',
          data: [],
          action: true,
        },
        {
          id: 2,
          name: 'Skipped email signup page',
          value: '0',
          secondaryValue: '0',
        },
        {
          id: 3,
          name: "Clicked 'Buy' button",
          value: '0',
        },
        {
          id: 4,
          name: 'Purchased course',
          value: '0',
          action: true,
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'All',
          value: '0',
        },
        {
          id: 1,
          name: 'Direct',
          value: '0',
          secondaryValue: '0',
        },
        {
          id: 2,
          name: 'Twitter',
          value: '0',
          secondaryValue: '0',
        },
        {
          id: 3,
          name: 'Facebook',
          value: '0',
          secondaryValue: '0',
        },
        {
          id: 4,
          name: 'Instagram',
          value: '0',
          secondaryValue: '0',
        },
      ],
    };

    this.renderSection = this.renderSection.bind(this);
  }

  componentDidMount() {
    this.checkIfUserCanAccessPage().then((canAccess) => {
      if (canAccess) {
        this.setState({ canAccessPage: true });
        this.setupCourse();

        const { courseID } = this.props.match.params;
        // When an episode's status (uploading, completed, error) changes, update the UI.
        listenForProcess(
          courseID,
          () => {
            this.setupCourse();
          },
          (error) => {
            console.log('ERROR:', error);
          },
        );
      } else {
        this.setState({ canAccessPage: false, isLoading: false });
      }
    });
  }

  checkIfUserCanAccessPage = () => {
    const { courseID } = this.props.match.params;
    const { firebaseUser } = this.props;

    return new Promise((resolve, reject) => {
      fetchCreator(firebaseUser.id)
        .then((creator) => {
          if (creator && creator.courses) {
            var courses = creator.courses;
            var coursesIDs = Object.keys(courses);
            if (coursesIDs.includes(courseID)) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          console.log('ERROR', error);
          reject(error);
        });
    });
  };

  setupCourse = () => {
    const { courseID } = this.props.match.params;

    this.fetchCourseFromFirebase(courseID)
      .then((course) => {
        if (!course) {
          this.setState({ isLoading: false, courseFound: false });
          return;
        }
        this.fetchAnalytics(course);
        this.fetchStudentsTransactionsForCourseFromFirebase(course)
          .then((students) => {
            var studentsFound = [];
            if (students) {
              studentsFound = students;
            }
            fetchProcess(course.id)
              .then((process) => {
                this.setState({
                  isLoading: false,
                  courseFound: true,
                  course: course,
                  students: studentsFound,
                  processes: process,
                });
              })
              .catch((error) => {
                console.log('ERROR', error);
                this.setState({
                  isLoading: false,
                  courseFound: true,
                  course: course,
                  students: studentsFound,
                });
              });
          })
          .catch((error) => {
            console.log('ERROR', error);
            var courseFound = false;
            var courseFetched = {};
            if (course) {
              courseFound = true;
              courseFetched = course;
            }
            this.setState({
              isLoading: false,
              courseFound: courseFound,
              course: courseFetched,
            });
          });
      })
      .catch((error) => {
        console.log('ERROR', error);
        this.setState({ isLoading: false, courseFound: false });
      });
  };

  fetchCourseFromFirebase = (courseID) => {
    return new Promise((resolve, reject) => {
      fetchCourse(courseID)
        .then((res) => {
          if (!res) {
            resolve();
          } else {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  fetchAnalytics = (course) => {
    const courseID = course.id;
    const courseCreators = Object.keys(course.creators);
    const { analyticsPeriod } = this.state;
    fetchCourseAnalytics(courseID, analyticsPeriod, courseCreators).then(
      (analytics) => {
        var analyticsCopy = this.state.analytics;
        var analyticsSalesFormatted = (analytics.sales / 100).toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: course.currency,
          },
        );
        analyticsCopy.listenTime.value = analytics.listenTime;
        analyticsCopy.sales.value = analyticsSalesFormatted;
        analyticsCopy.purchases.value = analytics.purchases;
        analyticsCopy.plays.value = analytics.coursePlays;
        analyticsCopy.views.value = analytics.courseViews;
        var conversionCopy = this.state.conversion;

        var totalEmailSignUpViews = analytics.courseViews;

        conversionCopy[0].value = analytics.conversion.courseViews;
        conversionCopy[1].value = analytics.conversion.emailSignUp.value;
        conversionCopy[1].data = analytics.conversion.emailSignUp.data;
        conversionCopy[1].secondaryValue = `(${(
          (analytics.conversion.emailSignUp.value * 100) /
          (totalEmailSignUpViews || 1)
        ).toFixed(2)}%)`;

        conversionCopy[2].value = analytics.conversion.skippedEmailSignUp;
        conversionCopy[2].secondaryValue = `(${(
          (analytics.conversion.skippedEmailSignUp * 100) /
          (totalEmailSignUpViews || 1)
        ).toFixed(2)}%)`;

        conversionCopy[3].value = analytics.conversion.clickBuyCourse;

        conversionCopy[4].value = analytics.conversion.purchases;

        var trafficSourcesCopy = this.state.trafficSources;
        trafficSourcesCopy[0].value = analytics.trafficSources.all;

        trafficSourcesCopy[1].value = analytics.trafficSources.web;
        trafficSourcesCopy[1].secondaryValue = `(${(
          (analytics.trafficSources.web * 100) /
          (analytics.trafficSources.all || 1)
        ).toFixed(2)}%)`;

        trafficSourcesCopy[2].value = analytics.trafficSources.twitter;
        trafficSourcesCopy[2].secondaryValue = `(${(
          (analytics.trafficSources.twitter * 100) /
          (analytics.trafficSources.all || 1)
        ).toFixed(2)}%)`;

        trafficSourcesCopy[3].value = analytics.trafficSources.facebook;
        trafficSourcesCopy[3].secondaryValue = `(${(
          (analytics.trafficSources.facebook * 100) /
          (analytics.trafficSources.all || 1)
        ).toFixed(2)}%)`;

        trafficSourcesCopy[4].value = analytics.trafficSources.instagram;
        trafficSourcesCopy[4].secondaryValue = `(${(
          (analytics.trafficSources.instagram * 100) /
          (analytics.trafficSources.all || 1)
        ).toFixed(2)}%)`;

        this.setState({
          analytics: analyticsCopy,
          conversion: conversionCopy,
        });
      },
    );
  };

  fetchStudentsTransactionsForCourseFromFirebase = (course) => {
    return new Promise((resolve, reject) => {
      fetchStudentsTransactionsForCourse(course.id)
        .then((transactions) => {
          if (transactions) {
            const usersPromises = transactions.map((transaction) => {
              return fetchUser(transaction.user_id);
            });
            Promise.all(usersPromises).then((users) => {
              if (users) {
                var studentsWithTransactions = [];
                users.forEach((user) => {
                  if (user.courses) {
                    var courses = Object.keys(user.courses);

                    // Double check that user actually owns this course
                    if (courses.includes(course.id)) {
                      var name = `${user.first_name} ${user.last_name}`;
                      var email = user.email;

                      var transaction = transactions.find(
                        (item) => item.user_id === user.id,
                      );

                      if (transaction) {
                        var courseCreators = Object.keys(course.creators);
                        // Exclude creators' listener accounts
                        if (!courseCreators.includes(transaction.user_id)) {
                          studentsWithTransactions.push({
                            name: name,
                            email: email,
                            timestamp: transaction.timestamp,
                            amount: transaction.amount,
                            currency: transaction.currency,
                          });
                        }
                      }
                    }
                  }
                });
                resolve(studentsWithTransactions);
              } else {
                resolve();
              }
            });
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  changeCourseStatus = (status) => {
    const { course } = this.state;
    updateCourseStatus(course.id, status)
      .then(() => {
        var courseCopy = course;
        course.status = status;
        this.setState({ course: courseCopy, courseStatusAlertSuccess: true });
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  };

  renderSection = () => {
    const {
      sectionSelected,
      course,
      analyticsPeriod,
      analytics,
      // conversion,
      trafficSources,
      students,
      processes,
    } = this.state;

    if (sectionSelected === 'Episodes') {
      var episodesArray = [];
      if (course.episodes) {
        var keys = Object.keys(course.episodes);
        episodesArray = keys
          .map((key) => course.episodes[key])
          .sort((a, b) => (a.episode_number > b.episode_number ? 1 : -1));
      }
      return (
        <>
          <div className={styles.episodeSectionHeader}>
            <h3 className="zeroMargin">
              Episodes{' '}
              {episodesArray.length > 0 ? `(${episodesArray.length})` : null}
            </h3>
            <Link to={`/course/${course.id}/add-episode`}>
              <div className={styles.addEpisodeContainer}>
                <Button
                  text="Add an episode"
                  small
                  zeroHorizontalPadding
                  icon={Plus}
                />
              </div>
            </Link>
          </div>
          {episodesArray.length === 0 && (
            <EmptyBox
              img={ListeningMusic}
              title="No episodes yet"
              description={`You can add episodes without uploading an audio file right away.
              This is a great way to show your course structure before it goes live.`}
              isEmptyEpisodesSection={true}
            />
          )}

          {episodesArray.length > 0 &&
            episodesArray.map((episode) => {
              var process = null;
              if (
                processes &&
                processes.episodes &&
                processes.episodes[`${episode.id}`]
              ) {
                process = processes.episodes[`${episode.id}`];
              }
              return (
                <div className={styles.episodeElement} key={episode.id}>
                  <Episode episode={episode} process={process} />
                </div>
              );
            })}
        </>
      );
    }
    if (sectionSelected === 'Analytics') {
      var analyticsKeys = Object.keys(analytics);
      return (
        <>
          <div className={styles.analyticsFilterOptionsContainer}>
            <p className={styles.analyticsPeriodLabel}>{analyticsPeriod}</p>
            <Select
              autoWidth
              options={[
                {
                  value: 'All',
                  selected: analyticsPeriod === 'All',
                },
                {
                  value: 'Today',
                  selected: analyticsPeriod === 'Today',
                },
                {
                  value: 'Last week',
                  selected: analyticsPeriod === 'Last week',
                },
                {
                  value: 'Last month',
                  selected: analyticsPeriod === 'Last month',
                },
              ]}
              onChange={(e) => {
                this.setState(
                  {
                    analyticsPeriod: e.target.value,
                  },
                  () => this.fetchAnalytics(course),
                );
              }}
            />
          </div>
          <span className={styles.analyticsType}>At a glance</span>
          <div className={styles.analyticsContainer}>
            {analyticsKeys.map((key) => {
              var analytic = analytics[key];
              return (
                <div className={styles.analyticsElement} key={analytic.id}>
                  <Analytics analytic={analytic} />
                </div>
              );
            })}
          </div>

          <div className={styles.analyticsHorizontalContainer}>
            {/* <div className={styles.analyticsTypeContainer}>
              <span className={styles.analyticsType}>Activity</span>
              <div className={styles.analyticsTableContainer}>
                <AnalyticsTable
                  sections={conversion}
                  onSectionClick={(section) => {
                    if (section.name === 'Signed up to receive emails') {
                      this.props.history.push(
                        `/course/${course.id}/analytics/signed-up-emails`,
                        { period: analyticsPeriod },
                      );
                    }
                    if (section.name === 'Purchased course') {
                      this.props.history.push(
                        `/course/${course.id}/analytics/purchased-course`,
                        { period: analyticsPeriod },
                      );
                    }
                  }}
                />
              </div>
            </div> */}
            <div className={styles.analyticsTypeContainer}>
              <span className={styles.analyticsType}>Traffic sources</span>
              <div className={styles.analyticsTableContainer}>
                <AnalyticsTable sections={trafficSources} />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (sectionSelected === 'Learners') {
      if (students) {
        if (students.length > 0) {
          var csvData = students
            .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
            .map((student) => {
              var transactionTimestampNumber = student.timestamp;
              var missingDigits = 13 - `${transactionTimestampNumber}`.length;
              if (missingDigits > 0) {
                transactionTimestampNumber =
                  transactionTimestampNumber * Math.pow(10, missingDigits);
              }
              var transactionTimestamp = moment(transactionTimestampNumber);
              var transactionTimestampFormatted = transactionTimestamp.format(
                'DD MMM, YYYY - hh:mma',
              );
              var priceFormatted = (student.amount / 100).toLocaleString(
                'en-GB',
                {
                  style: 'currency',
                  currency: student.currency,
                },
              );
              return {
                Name: student.name,
                Email: student.email,
                'Purchase date': transactionTimestampFormatted,
                Price: priceFormatted,
              };
            });
          return (
            <>
              <div className={styles.episodeSectionHeader}>
                <h3 className="zeroMargin">Learners ({students.length})</h3>
                <CSVLink
                  data={csvData}
                  filename={`${course.title}-Students.csv`}
                >
                  <div className={styles.addEpisodeContainer}>
                    <Button
                      text="Download CSV"
                      small
                      secondary
                      zeroHorizontalPadding
                    />
                  </div>
                </CSVLink>
              </div>
              <StudentsTable students={students} />
            </>
          );
        } else {
          return (
            <EmptyBox
              img={ListeningMusic}
              title="No learners yet"
              description="Once you have some learners, they will show up here."
            />
          );
        }
      } else {
        return (
          <EmptyBox
            img={ListeningMusic}
            title="No learners yet"
            description="Once you have some learners, they will show up here."
          />
        );
      }
    }
    if (sectionSelected === 'Details') {
      return <EditCourse course={course} />;
    }
  };

  renderShare() {
    const { course } = this.state;

    const link = course.slug ? `s/${course.slug}` : course.id;

    return (
      <div
        className={styles.shareCourseButton}
        onClick={() =>
          this.setState({
            showShareCourseLink: true,
            shareCourseLink: `https://learner.avid.fm/course/${link}`,
          })
        }
      >
        <Share />
      </div>
    );
  }

  render() {
    const {
      canAccessPage,
      isLoading,
      courseFound,
      sectionSelected,
      sections,
      course,
      showChangeCourseStatus,
      courseStatusAlertSuccess,
      showShareCourseLink,
      shareCourseLink,
    } = this.state;

    const { firebaseUser } = this.props;

    if (isLoading) {
      return <LoadingScreen />;
    }

    if (!canAccessPage) {
      return <Redirect to="/my-courses" />;
    }

    if (!courseFound) {
      return <LoadingScreen text="Course not found" />;
    }

    const isFree = course.full_price === 0 || course.full_price === '0';

    let shouldConnectStripe = true;

    if (firebaseUser.stripe) {
      if (firebaseUser.stripe.pending_requirements !== null) {
        shouldConnectStripe = firebaseUser.stripe.pending_requirements;
      }
    }

    if (isFree) {
      shouldConnectStripe = false;
    }

    const link = course.slug ? `s/${course.slug}` : course.id;

    return (
      <>
        {showShareCourseLink && (
          <ShareCoursePopup
            courseLink={shareCourseLink}
            onClose={() =>
              this.setState({
                showShareCourseLink: false,
                shareCourseLink: null,
              })
            }
          />
        )}
        {showChangeCourseStatus && (
          <CourseStatusAlert
            courseID={course.id}
            status={course.status}
            slug={course.slug}
            shouldConnectStripe={shouldConnectStripe}
            courseStatusAlertSuccess={courseStatusAlertSuccess}
            onSave={(statusSelected) => this.changeCourseStatus(statusSelected)}
            onClose={() =>
              this.setState({
                showChangeCourseStatus: false,
                courseStatusAlertSuccess: false,
              })
            }
          />
        )}
        <Header user />
        <div className={styles.container}>
          <div className={styles.courseInformationContainer}>
            <div
              className={styles.courseImage}
              style={{
                backgroundImage: `url(${course.image.square.url})`,
              }}
            >
              {!course.image.square.url && (
                <div className={styles.coverArtPlaceholderContainer}>
                  <AVIDSound />
                </div>
              )}
            </div>
            <div className={styles.courseInformation}>
              <h1 className={styles.courseTitle}>{course.title}</h1>
            </div>
            <div className={styles.headerButtonsContainer}>
              <div className={styles.courseStatusContainer}>
                <div>
                  <span>Status:</span>
                  <span className={styles.courseStatus}>{course.status}</span>
                </div>
                <div
                  className={styles.changeCourseStatusButton}
                  onClick={() =>
                    this.setState({ showChangeCourseStatus: true })
                  }
                >
                  Change
                </div>
              </div>
              <div className={styles.courseUrlsContainer}>
                <a
                  className={styles.viewMyCourseButton}
                  href={`https://learner.avid.fm/course/${link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button text="View my course" secondary small />
                </a>
                {this.renderShare()}
              </div>
            </div>
          </div>
          <ul className={styles.navigation}>
            {sections.map((section) => {
              return (
                <li
                  className={`${styles.navigationItem} ${
                    section === sectionSelected
                      ? styles.navigationItemSelected
                      : null
                  }`}
                  key={section}
                  onClick={() => {
                    this.setState({
                      sectionSelected: section,
                    });
                  }}
                >
                  <span>{section}</span>
                  {section === sectionSelected && (
                    <span className={styles.navigationItemSelectedIndicator} />
                  )}
                </li>
              );
            })}
          </ul>
          {this.renderSection()}
        </div>
      </>
    );
  }
}

const Analytics = (props) => {
  const { analytic } = props;
  return (
    <div className={styles.analyticsBox}>
      <h1 className={styles.analyticsValue}>{analytic.value}</h1>
      <p className={styles.analyticsDescription}>{analytic.description}</p>
    </div>
  );
};

const StudentsTable = (props) => {
  const { students } = props;
  var sortedStudents = students.sort((a, b) =>
    a.timestamp < b.timestamp ? 1 : -1,
  );
  return (
    <table>
      <thead>
        <tr className={styles.studentsTableHeader}>
          <th scope="col">NAME</th>
          <th scope="col">EMAIL</th>
          <th scope="col">PURCHASE DATE</th>
          <th scope="col">PRICE</th>
        </tr>
      </thead>
      <tbody>
        {sortedStudents.map((student) => {
          var transactionTimestampNumber = student.timestamp;
          var missingDigits = 13 - `${transactionTimestampNumber}`.length;
          if (missingDigits > 0) {
            transactionTimestampNumber =
              transactionTimestampNumber * Math.pow(10, missingDigits);
          }
          var transactionTimestamp = moment(transactionTimestampNumber);
          var transactionTimestampFormatted = transactionTimestamp.format(
            'DD MMM, YYYY - hh:mma',
          );
          var priceFormatted = (student.amount / 100).toLocaleString('en-GB', {
            style: 'currency',
            currency: student.currency,
          });
          return (
            <tr key={student.name}>
              <td data-label="NAME">{student.name}</td>
              <td data-label="EMAIL">{student.email}</td>
              <td data-label="PURCHASE DATE">
                {transactionTimestampFormatted}
              </td>
              <td data-label="PRICE">{priceFormatted}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const EditCourse = (props) => {
  const { course } = props;
  return (
    <div className={styles.editCourseContainer}>
      <div className={styles.editCourseInfoContainer}>
        <h3 className={styles.editCourseTitle}>Edit course details</h3>
        <p className={styles.editCourseDescription}>
          Edit the title, overview, cover image, pricing, URL and more...
        </p>
      </div>
      <Link
        className={styles.editButtonContainer}
        to={{ pathname: `/course/${course.id}/edit`, course: course }}
      >
        <Button text="Edit" small tertiary />
      </Link>
    </div>
  );
};
