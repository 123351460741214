import React from 'react';
import styles from './Input.module.scss';

const Input = (props) => {
  const {
    required,
    disabled,
    label,
    secondaryLabel,
    placeholder,
    type,
    long,
    veryLong,
    hideLabel,
    style,
    inputStyle,
    value,
    onChange,
    error,
    min,
    onClick,
    secondaryLabelOnClick,
  } = props;

  var className = styles.input;

  if (long) {
    className = `${styles.input} ${styles.long}`;
  }

  if (veryLong) {
    className = `${styles.input} ${styles.veryLong}`;
  }

  if (error) {
    className += ` ${styles.error}`;
  }

  var component = (
    <input
      style={inputStyle}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
      className={className}
      value={value}
      min={min ? min : null}
      onChange={(e) => (onChange ? onChange(e) : null)}
      onClick={() => (onClick ? onClick() : null)}
    />
  );

  if (long || veryLong) {
    component = (
      <textarea
        style={inputStyle}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        className={className}
        value={value}
        min={min ? min : null}
        onChange={(e) => (onChange ? onChange(e) : null)}
      />
    );
  }

  var labelComponent = (
    <label htmlFor={label} className={styles.label}>
      {label}
    </label>
  );

  if (secondaryLabel) {
    labelComponent = (
      <div className={styles.labelsContainer}>
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
        <label
          htmlFor={label}
          className={`${styles.label} ${styles.secondaryLabel}`}
          style={{ cursor: secondaryLabelOnClick ? 'pointer' : 'default' }}
          onClick={() =>
            secondaryLabelOnClick ? secondaryLabelOnClick() : null
          }
        >
          {secondaryLabel}
        </label>
      </div>
    );
  }
  return (
    <div className={styles.inputContainer} style={style}>
      {!hideLabel && labelComponent}
      {component}
      {error && <p className="errorLabel">{error}</p>}
    </div>
  );
};

export default Input;
