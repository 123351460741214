import React from 'react';

import styles from './FormSocialAccounts.module.scss';

import Input from '../../Input/Input';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

const FormSocialAccounts = (props) => {
  const { label, description, socialAccounts, onChange, error } = props;

  var socials = Object.keys(socialAccounts);

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      {socials.map((key, index) => {
        var social = socialAccounts[key];
        var socialError = error.find(
          (item) => item.type.toUpperCase() === social.type.toUpperCase(),
        );
        var errorText = '';
        if (socialError) {
          errorText = socialError.text;
        }

        return (
          <div key={social.type}>
            <Input
              hideLabel={true}
              type="url"
              placeholder={social.placeholder}
              value={social.url}
              onChange={(e) => (onChange ? onChange(social.type, e) : null)}
              style={{ marginBottom: index === socials.length - 1 ? 0 : '8px' }}
            />
            {errorText !== '' && (
              <div className="errorContainer" style={{ marginBottom: '8px' }}>
                <Warning />
                <span className="errorMessage">{errorText}</span>
              </div>
            )}
          </div>
        );
      })}
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default FormSocialAccounts;
