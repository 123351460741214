import React from 'react';

import styles from './FormUpload.module.scss';

import { ReactComponent as Upload } from '../../../assets/img/upload.svg';
import { ReactComponent as Warning } from '../../../assets/img/warning.svg';
import { ReactComponent as Clip } from '../../../assets/img/clip.svg';
import UploadInProgress from '../../../assets/img/upload_in_progress.gif';

const FormUpload = (props) => {
  const { label, description, error } = props;

  function handleBtnClick() {
    const { addFile } = props;
    var file = document.getElementById('file-upload-audio').files[0];
    addFile(file);
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  function renderComponent() {
    const { file, buttonTitle, removeFile, status } = props;

    if (!file) {
      return (
        <>
          <label htmlFor="file-upload-audio" className={styles.uploadButton}>
            <Upload className={styles.uploadIcon} alt="Upload cover art" />
            <span>{buttonTitle}</span>
          </label>
          <input
            id="file-upload-audio"
            type="file"
            accept="audio/mp3"
            onChange={handleBtnClick}
          />
        </>
      );
    } else {
      if (status === 'success') {
        return (
          <div className={styles.fileInfo}>
            <div className={styles.fileInfoLeft}>
              <Clip className={styles.fileIcon} />
              <span className={styles.fileName}>
                {file.name} ({bytesToSize(file.size)})
              </span>
            </div>
            <div
              className={styles.removeFile}
              onClick={() => (removeFile ? removeFile() : null)}
            >
              Delete
            </div>
          </div>
        );
      } else if (status === 'uploading') {
        return (
          <div className={styles.fileInfo}>
            <div className={styles.fileInfoLeft}>
              <img
                src={UploadInProgress}
                className={`${styles.fileIcon} ${styles.fileStatusIconUploading}`}
                style={{ height: '20px' }}
                alt="Uploading audio"
              />
              <span
                className={`${styles.fileName} ${styles.fileStatusTextUploading}`}
              >
                Uploading audio
              </span>
            </div>
            <div className={styles.removeFile} style={{ visibility: 'hidden' }}>
              Cancel
            </div>
          </div>
        );
      } else if (status === 'failed') {
        return (
          <div className={styles.fileInfo}>
            <div className={styles.fileInfoLeft}>
              <Warning
                className={`${styles.fileIcon} ${styles.fileStatusIconFailed}`}
              />
              <span
                className={`${styles.fileName} ${styles.fileStatusTextFailed}`}
              >
                Audio upload failed
              </span>
            </div>
            <div
              className={styles.removeFile}
              onClick={() => (removeFile ? removeFile() : null)}
            >
              Re-upload
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    }
  }

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      {renderComponent()}
      {error && (
        <div className="errorContainer" style={{ marginTop: '20px' }}>
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};

export default FormUpload;
