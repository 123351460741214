import React from 'react';

import styles from './FormFAQs.module.scss';

import Input from '../../Input/Input';

import Plus from '../../../assets/img/plus_secondary.svg';
import { ReactComponent as Warning } from '../../../assets/img/warning.svg';
import { ReactComponent as Bin } from '../../../assets/img/bin.svg';

const FormFAQs = (props) => {
  const {
    label,
    description,
    questionPlaceholder,
    answerPlaceholder,
    addFaq,
    faqs,
    onQuestionChange,
    onAnswerChange,
    onRemoveFAQ,
    error,
  } = props;
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      {faqs.map((item) => {
        var errorMessage = null;
        var errorType = null;
        if (error) {
          if (error.id === item.id) {
            errorMessage = error.error;
            errorType = error.type;
          }
        }
        return (
          <FAQ
            questionPlaceholder={questionPlaceholder}
            answerPlaceholder={answerPlaceholder}
            questionValue={item.question}
            answerValue={item.answer}
            key={item.id}
            onQuestionChange={(e) => onQuestionChange(e, item)}
            onAnswerChange={(e) => onAnswerChange(e, item)}
            onRemoveFAQ={() => (onRemoveFAQ ? onRemoveFAQ(item) : null)}
            error={errorMessage}
            errorType={errorType}
          />
        );
      })}
      <div className={styles.addFaq} onClick={addFaq}>
        <img src={Plus} alt="Add faq" />
        <span className={styles.addFaqLabel}>Add FAQ</span>
      </div>
    </div>
  );
};

export default FormFAQs;

const FAQ = (props) => {
  const {
    questionPlaceholder,
    answerPlaceholder,
    questionValue,
    answerValue,
    onQuestionChange,
    onAnswerChange,
    onRemoveFAQ,
    error,
    errorType,
  } = props;
  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputContentContainer}>
        <Input
          type="text"
          hideLabel={true}
          placeholder={questionPlaceholder}
          value={questionValue}
          onChange={(e) => onQuestionChange(e)}
          inputStyle={{ paddingRight: '50px' }}
        />
        <div className={styles.removeButton}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (onRemoveFAQ) {
                onRemoveFAQ();
              }
            }}
          >
            <Bin />
          </div>
        </div>
      </div>

      {errorType === 'QUESTION' && (
        <div className="errorContainer" style={{ marginBottom: '16px' }}>
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
      <Input
        type="text"
        placeholder={answerPlaceholder}
        value={answerValue}
        onChange={(e) => onAnswerChange(e)}
        long
        hideLabel
      />
      {errorType === 'ANSWER' && (
        <div className="errorContainer" style={{ marginBottom: '16px' }}>
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};
