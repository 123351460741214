import * as firebase from 'firebase/app';
import 'firebase/storage';

/**
 * Store file in Firebase Storage
 * @param {string} path - Firebase file path
 * @param {string} file - Data string to upload
 * @return {Promise} File URL
 */
const storeFile = (path, file) => {
  return new Promise((resolve, reject) => {
    var storageRef = firebase.storage().ref();

    var extension = file.substring(
      file.indexOf('/') + 1,
      file.indexOf(';base64'),
    );

    var fileRef = storageRef.child(`${path}.${extension}`);

    fileRef
      .putString(file, 'data_url')
      .then(function (snapshot) {
        snapshot.ref
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Store resource in Firebase Storage
 * @param {string} path - Firebase file path
 * @param {string} file - File to upload
 * @return {Promise} File URL
 */
const storeResource = (path, file) => {
  return new Promise((resolve, reject) => {
    var storageRef = firebase.storage().ref();

    var fileRef = storageRef.child(`${path}`);

    fileRef
      .put(file)
      .then(function (snapshot) {
        snapshot.ref
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.log(2);
            reject(error);
          });
      })
      .catch((error) => {
        console.log(1);
        reject(error);
      });
  });
};

/**
 * Remove file from Firebase Storage
 * @param {string} path - Firebase file path
 * @return {Promise} Void if success
 */
const removeContent = (path) => {
  return new Promise((resolve, reject) => {
    const ref = firebase.storage().ref(path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          const ref2 = firebase.storage().ref(ref.fullPath);
          const childRef = ref2.child(fileRef.name);
          childRef.delete().then(() => resolve());
        });
        dir.prefixes.forEach((folderRef) => {
          removeContent(folderRef.fullPath);
        });
      })
      .catch((error) => {
        reject(error);
      });

    // firebase
    //   .storage()
    //   .ref(path)
    //   .listAll()
    //   .then((snapshot) => {
    //     console.log('00', path);
    //     console.log('aa', snapshot.items);
    //     if (snapshot && snapshot.items && snapshot.items.length > 0) {
    //       snapshot.items.forEach((item) => {
    //         console.log('ss', item);
    //         var itemPath = item.location.path;
    //         firebase
    //           .storage()
    //           .ref(itemPath)
    //           .delete()
    //           .catch((error) => {
    //             console.log('ERROR', error);
    //           });
    //       });
    //       resolve();
    //     }
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

/**
 * Remove file from Firebase Storage
 * @param {string} path - Firebase file path
 * @return {Promise} File URL
 */
const fetchContent = (path) => {
  return new Promise((resolve, reject) => {
    firebase
      .storage()
      .ref(path)
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((error) => reject(error));
  });
};

export { storeFile, storeResource, removeContent, fetchContent };
