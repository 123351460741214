import React from 'react';
import { Link } from 'react-router-dom';

import styles from './MyCourses.module.scss';

import Header from '../../components/Header/Header';
// import FeedbackCTA from '../../components/FeedbackCTA/FeedbackCTA';
import Course from '../../components/Course/Course';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import ShareCoursePopup from '../../components/ShareCoursePopup/ShareCoursePopup';
import Prompt from '../../components/Prompt/Prompt';

import Plus from '../../assets/img/plus.svg';
import Creativity from '../../assets/img/creativity.svg';

import {
  fetchCreator,
  fetchCourse,
  calculateCourseRevenue,
} from '../../services/FirebaseService/Database';

export default class MyCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showStripePrompt: false,
      showShareCourseLink: false,
      shareCourseLink: null,
      isLoading: true,
      courses: [],
    };
  }

  componentDidMount() {
    this.fetchCourses();
  }

  fetchCourses = () => {
    const { store, firebaseUser } = this.props;
    var shouldShowStripePrompt = false;

    if (firebaseUser) {
      fetchCreator(firebaseUser.id)
        .then((creator) => {
          if (store.data.creatorClosedStripePrompt) {
            // Hide this prompt until next login
            shouldShowStripePrompt = false;
          } else {
            // Show this prompt if Creator hasn't connected Stripe account
            if (!creator.stripe) {
              shouldShowStripePrompt = true;
            } else {
              if (creator.stripe.pending_requirements) {
                shouldShowStripePrompt = true;
              }
            }
          }

          if (creator.courses) {
            var IDs = Object.keys(creator.courses);
            var promises = IDs.map((id) => fetchCourse(id));
            Promise.all(promises)
              .then((courses) => {
                var coursesFetched = [];
                if (courses) {
                  coursesFetched = courses;
                }

                var revenuePromises = coursesFetched.map((course) => {
                  const courseCreators = Object.keys(course.creators);
                  return calculateCourseRevenue(course.id, courseCreators);
                });

                Promise.all(revenuePromises)
                  .then((revenues) => {
                    revenues.forEach((revenue) => {
                      var index = coursesFetched.findIndex(
                        (course) => course.id === revenue.course_id,
                      );
                      if (index > -1) {
                        coursesFetched[index].revenue = revenue.amount;
                      }
                    });

                    if (coursesFetched.length > 0) {
                      coursesFetched.sort((a, b) =>
                        a.creation_timestamp < b.creation_timestamp ? 1 : -1,
                      );
                    }

                    this.setState({
                      showStripePrompt: shouldShowStripePrompt,
                      courses: coursesFetched,
                      isLoading: false,
                    });
                  })
                  .catch((error) => {
                    console.log('ERROR', error);
                    this.setState({
                      showStripePrompt: false,
                      courses: coursesFetched,
                      isLoading: false,
                    });
                  });
              })
              .catch((error) => {
                console.log('ERROR', error);
                this.setState({
                  showStripePrompt: shouldShowStripePrompt,
                  courses: [],
                  isLoading: false,
                });
              });
          } else {
            this.setState({
              showStripePrompt: shouldShowStripePrompt,
              courses: [],
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log('ERROR', error);
          this.setState({
            showStripePrompt: shouldShowStripePrompt,
            courses: [],
            isLoading: false,
          });
        });
    } else {
      this.setState({
        showStripePrompt: shouldShowStripePrompt,
        courses: [],
        isLoading: false,
      });
    }
  };

  renderCourses() {
    const { courses } = this.state;

    if (!courses || courses.length === 0) {
      return null;
    }

    return courses.map((course) => {
      const link = course.slug ? `s/${course.slug}` : course.id;

      return (
        <div className={styles.courseContainer} key={course.id}>
          <Course
            course={course}
            onShareCourseLink={() =>
              this.setState({
                showShareCourseLink: true,
                shareCourseLink: `https://learner.avid.fm/course/${link}`,
              })
            }
          />
        </div>
      );
    });
  }

  render() {
    const {
      isLoading,
      courses,
      showStripePrompt,
      showShareCourseLink,
      shareCourseLink,
    } = this.state;

    const { store, setStore } = this.props;

    if (isLoading) {
      return <LoadingScreen />;
    }

    return (
      <>
        {showShareCourseLink && (
          <ShareCoursePopup
            courseLink={shareCourseLink}
            onClose={() =>
              this.setState({
                showShareCourseLink: false,
                shareCourseLink: null,
              })
            }
          />
        )}
        <Header user />
        <div className={styles.coursesContainer}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>My courses</h2>
            <Link
              role="presentation"
              className={styles.createCourseButton}
              onClick={this.createCourse}
              to="/create-course"
            >
              <img
                src={Plus}
                alt="Create a course"
                style={{ marginRight: '5px' }}
              />
              Create a course
            </Link>
          </div>
          {showStripePrompt && (
            <div className={styles.promptContainer}>
              <Prompt
                onConnect={() =>
                  this.props.history.push('/settings?section=payments')
                }
                onClose={() => {
                  this.setState({ showStripePrompt: false });
                  var storeCopy = { ...store };
                  storeCopy.data.creatorClosedStripePrompt = true;
                  setStore(storeCopy);
                }}
              />
            </div>
          )}
          {courses.length === 0 && (
            <div className={styles.emptyCourses}>
              <img src={Creativity} alt="No courses yet" />
              <p className={styles.noCoursesLabel}>No courses yet</p>
              <p className="textSM" style={{ opacity: 0.6 }}>
                Once you create a course, it will show up here.
              </p>
            </div>
          )}
          {this.renderCourses()}
        </div>
      </>
    );
  }
}
