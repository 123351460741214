import React from 'react';

import styles from './ShareCoursePopup.module.scss';

import { ReactComponent as Close } from '../../assets/img/close.svg';
import { ReactComponent as Info } from '../../assets/img/info.svg';

export default class ShareCoursePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkCopied: null,
    };
  }

  render() {
    const { linkCopied } = this.state;
    const { courseLink, onClose } = this.props;

    const copyLink = (link) => {
      var copyText = document.getElementById(link);
      copyText.select();
      document.execCommand('copy');
      this.setState({ linkCopied: link });
    };

    return (
      <div className={styles.popupContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => onClose()} />
          <h3 className={styles.title}>Share your course</h3>
          <div className={styles.linkContainer}>
            <Link
              label="Course link"
              link={courseLink}
              isCopied={linkCopied === courseLink}
              onCopy={(link) => copyLink(link)}
            />
          </div>
          <div className={styles.linkContainer}>
            <div className={styles.tipContainer}>
              <div className={styles.tipIcon}>
                <Info />
              </div>
              <p className={`${styles.tipText} ${styles.tipTextWithEmptyText}`}>
                Sharing your course on social media? Use these links to track
                views from each channel.
              </p>
            </div>
            <div className={styles.linkElement}>
              <Link
                label="Twitter"
                link={`${courseLink}?utm_source=twitter`}
                isCopied={linkCopied === `${courseLink}?utm_source=twitter`}
                onCopy={(link) => copyLink(link)}
              />
            </div>
            <div className={styles.linkElement}>
              <Link
                label="Facebook"
                link={`${courseLink}?utm_source=facebook`}
                isCopied={linkCopied === `${courseLink}?utm_source=facebook`}
                onCopy={(link) => copyLink(link)}
              />
            </div>
            <div className={styles.linkElement}>
              <Link
                label="Instagram"
                link={`${courseLink}?utm_source=instagram`}
                isCopied={linkCopied === `${courseLink}?utm_source=instagram`}
                onCopy={(link) => copyLink(link)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Link = (props) => {
  const { label, link, isCopied, onCopy } = props;

  return (
    <>
      <span className={styles.label}>{label}</span>
      <div className={styles.fieldContainer}>
        <div className={styles.inputContainer}>
          <input
            type="text"
            className={styles.input}
            defaultValue={link}
            id={link}
          />
          <div className={styles.gradient} />
        </div>

        <div
          className={`${styles.copyButton} ${
            isCopied ? styles.copiedButton : null
          }`}
          onClick={() => onCopy(link)}
        >
          <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </div>
      </div>
    </>
  );
};
