import React from 'react';

import styles from './FormResources.module.scss';

import { ReactComponent as Upload } from '../../../assets/img/upload.svg';
import { ReactComponent as Warning } from '../../../assets/img/warning.svg';
import { ReactComponent as Clip } from '../../../assets/img/clip.svg';

const FormResources = (props) => {
  const { label, description, error } = props;

  function handleBtnClick() {
    const { addResource } = props;
    var file = document.getElementById('resource-file-upload').files[0];
    addResource(file);
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  function renderComponent() {
    const { resources, buttonTitle, removeResource } = props;

    var resourcesArray = [];

    if (resources) {
      var resourcesKeys = Object.keys(resources);
      resourcesArray = resourcesKeys
        .map((key) => resources[key])
        .sort((resource1, resource2) =>
          resource1.file.upload_timestamp > resource2.file.upload_timestamp
            ? 1
            : -1,
        );
    }

    return (
      <>
        {resourcesArray.map((resource) => {
          return (
            <div key={resource.id}>
              <div className={styles.fileInfo}>
                <div className={styles.fileInfoLeft}>
                  <Clip className={styles.fileIcon} />
                  <span className={styles.fileName}>
                    {resource.file.name} ({bytesToSize(resource.file.size)})
                  </span>
                </div>
                <div
                  className={styles.removeFile}
                  onClick={() =>
                    removeResource ? removeResource(resource) : null
                  }
                >
                  Delete
                </div>
              </div>
              {error && error.id === resource.id && (
                <div
                  className="errorContainer"
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                >
                  <Warning />
                  <span className="errorMessage">{error.error}</span>
                </div>
              )}
            </div>
          );
        })}
        <label htmlFor="resource-file-upload" className={styles.uploadButton}>
          <Upload className={styles.uploadIcon} alt={buttonTitle} />
          <span>{buttonTitle}</span>
        </label>
        <input
          id="resource-file-upload"
          type="file"
          accept="application/pdf,image/png,image/jpeg"
          onChange={handleBtnClick}
        />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      {renderComponent()}
    </div>
  );
};

export default FormResources;
