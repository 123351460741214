import React from 'react';
import styles from './InputIcon.module.scss';

const InputIcon = (props) => {
  const {
    placeholder,
    type,
    pattern,
    icon,
    min,
    value,
    onChange,
    error,
  } = props;

  var className = styles.inputContainer;

  if (error) {
    className += ` ${styles.error}`;
  }

  return (
    <>
      <div className={className}>
        <span className={styles.icon}>{icon}</span>
        <input
          placeholder={placeholder}
          type={type}
          pattern={pattern}
          className={styles.input}
          min={min}
          value={value}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
      {error && <p className="errorLabel">{error}</p>}
    </>
  );
};

export default InputIcon;
