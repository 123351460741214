import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Episode.module.scss';

import Button from '../Button/Button';

import { ReactComponent as Clip } from '../../assets/img/clip.svg';
import { ReactComponent as Warning } from '../../assets/img/warning.svg';
import UploadInProgress from '../../assets/img/upload_in_progress.gif';

const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const Episode = (props) => {
  const { episode, process } = props;

  var episodeStatus = null;

  if (process) {
    if (process.error) {
      episodeStatus = 'failed';
    } else if (!process.completed && process.processing) {
      episodeStatus = 'uploading';
    } else if (episode.file && episode.url !== '') {
      episodeStatus = 'success';
    }
  }

  var episodeStatusIcon = null;
  var episodeStatusText = null;

  if (episodeStatus === 'success') {
    episodeStatusIcon = <Clip />;
    episodeStatusText = (
      <span className={`${styles.episodeStatusText}`}>
        {episode.file.name} ({bytesToSize(episode.file.size)})
      </span>
    );
  } else if (episodeStatus === 'uploading') {
    episodeStatusIcon = (
      <img
        src={UploadInProgress}
        className={styles.episodeStatusIconUploading}
        style={{ height: '20px' }}
        alt="Uploading audio"
      />
    );
    episodeStatusText = (
      <span
        className={`${styles.episodeStatusText} ${styles.episodeStatusTextUploading}`}
      >
        Uploading audio
      </span>
    );
  } else if (episodeStatus === 'failed') {
    episodeStatusIcon = <Warning className={styles.episodeStatusIconFailed} />;
    episodeStatusText = (
      <span
        className={`${styles.episodeStatusText} ${styles.episodeStatusTextFailed}`}
      >
        Audio upload failed
      </span>
    );
  }

  var episodeLength = null;
  var episodeLengthSeparator = null;
  if (episode.file && episode.duration > 0) {
    episodeLengthSeparator = (
      <span className={styles.episodeDetailsSeparator}>| {` `}</span>
    );
    episodeLength = `Length: ${toHHMMSS(episode.duration)} `;
  }

  return (
    <div className={styles.episodeContainer}>
      <div className={styles.episodeInfoContainer}>
        <h3 className={styles.episodeTitle}>{episode.title}</h3>
        <p className={styles.episodeDetails}>
          Episode number: {episode.episode_number} {episodeLengthSeparator}
          {episodeLength}
        </p>
        {episodeStatus && (
          <div className={styles.episodeStatus}>
            <div className={styles.episodeStatusIcon}>{episodeStatusIcon}</div>
            {episodeStatusText}
          </div>
        )}
      </div>
      <Link
        className={styles.editButtonContainer}
        to={`/course/${episode.course_id}/episode/${episode.id}/edit`}
      >
        <Button text="Edit episode" small tertiary />
      </Link>
    </div>
  );
};

export default Episode;
