import React from 'react';
import { Link } from 'react-router-dom';

import styles from './SignUp.module.scss';

import { createUserWithEmailAndPassword } from '../../services/FirebaseService/Auth';
import { createUserDatabase } from '../../services/FirebaseService/Database';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: '',
      error: '',
      isLoading: false,
    };

    this.handleSignUp = this.handleSignUp.bind(this);
  }

  handleSignUp = (e) => {
    e.preventDefault();
    const { name, email, password } = this.state;

    this.setState({ isLoading: true });

    var splittedName = name.split(' ');
    if (splittedName.length === 0) {
      this.setState({
        error: {
          code: 'fullname',
          message: 'Please enter your first and last name',
        },
        isLoading: false,
      });
      return;
    }
    if (splittedName.length > 0) {
      if (splittedName[0] === '') {
        this.setState({
          error: {
            code: 'fullname',
            message: 'Please enter your first and last name',
          },
          isLoading: false,
        });
        return;
      }
    }

    var firstName = splittedName[0];
    var lastName = splittedName.slice(1, splittedName.length).join(' ');
    createUserWithEmailAndPassword(email, password)
      .then((res) => {
        if (res && res.user) {
          var UID = res.user.uid;
          createUserDatabase(UID, email, firstName, lastName)
            .then(() => {
              // We need to reload the app to trigger the onAuthStateChanged observer in App.js
              window.location.reload(true);
            })
            .catch((error) => {
              console.log('ERROR', error);
            });
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
        if (error) {
          if (error.code === 'auth/email-already-in-use') {
            this.setState({
              error: {
                code: 'auth/email-already-in-use',
                message:
                  'An account with the given email address already exists',
              },
              isLoading: false,
            });
          }
          if (error.code === 'auth/invalid-email') {
            this.setState({
              error: {
                code: 'auth/invalid-email',
                message: 'The email address is not valid',
              },
              isLoading: false,
            });
          }
          if (error.code === 'auth/operation-not-allowed') {
            this.setState({
              error: {
                code: 'auth/operation-not-allowed',
                message: 'Operation not allowed',
              },
              isLoading: false,
            });
          }
          if (error.code === 'auth/weak-password') {
            this.setState({
              error: {
                code: 'auth/weak-password',
                message: 'The password is not strong enough',
              },
              isLoading: false,
            });
          }
        }
      });
  };

  render() {
    const { name, email, password, error, isLoading } = this.state;

    var nameError = null;
    var emailError = null;
    var passwordError = null;

    if (error) {
      if (
        error.code === 'auth/email-already-in-use' ||
        error.code === 'auth/invalid-email'
      ) {
        emailError = error.message;
      } else if (error.code === 'fullname') {
        nameError = error.message;
      } else {
        passwordError = error.message;
      }
    }

    return (
      <form onSubmit={(e) => this.handleSignUp(e)}>
        <Header />
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Sign up for a creator account</h2>
          <Input
            label="Full name"
            placeholder="Enter your full name"
            type="text"
            value={name}
            error={nameError}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <Input
            label="Email"
            placeholder="Enter your email"
            type="email"
            style={{ marginTop: '12px' }}
            value={email}
            error={emailError}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <Input
            label="Password"
            placeholder="Enter your password"
            type="password"
            style={{ marginTop: '12px' }}
            value={password}
            error={passwordError}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <button
            type="submit"
            className={styles.signUpButtonContainer}
            onClick={(e) => this.handleSignUp(e)}
          >
            <Button text="Sign up" small loading={isLoading} />
          </button>
          <p className={styles.termsAndConditions}>
            By signing up, you agree to our{' '}
            <a
              className={styles.termsAndConditionsLink}
              href="http://www.avid.fm/?open=terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </a>
            .
          </p>
          <div className={styles.separator} />
          <p to="/login" className={styles.login}>
            Already have an account? <Link to="/login">Log in</Link>
          </p>
        </div>
      </form>
    );
  }
}
