import React from 'react';
import styles from './ConfirmationPopup.module.scss';

import Button from '../../components/Button/Button';

import { ReactComponent as Close } from '../../assets/img/close.svg';

const ConfirmationPopup = (props) => {
  const { message, onConfirm, onCancel } = props;
  return (
    <div className={styles.popupContainer}>
      <div className={styles.container}>
        <Close className={styles.closeButton} onClick={() => onCancel()} />
        <p className={styles.message}>{message}</p>
        <div className={styles.buttonsContainer}>
          <div className={styles.button} onClick={() => onCancel()}>
            <Button text="Cancel" secondary />
          </div>
          <div className={styles.button} onClick={() => onConfirm()}>
            <Button text="Delete" secondary red />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
