import React from 'react';

import './Select.module.scss';

import Arrow from '../../assets/img/select_arrow_down.svg';

const Select = (props) => {
  const { options, onChange, autoWidth, error } = props;
  const selectedOption = options.find((item) => item.selected);
  return (
    <>
      <select
        style={{
          backgroundImage: `url(${Arrow})`,
          width: autoWidth ? 'auto' : null,
        }}
        onChange={(e) => (onChange ? onChange(e) : null)}
        value={selectedOption.value}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.value}
          </option>
        ))}
      </select>
      {error && <p className="errorLabel">{error}</p>}
    </>
  );
};

export default Select;
