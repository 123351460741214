import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/database';

import slugify from 'slugify';
import moment from 'moment';

import { sendSignUpEmail } from '../../NetworkService';

import { storeFile, fetchContent } from '../Storage';

const database = firebase.database();

/**
 * Fetch creator from database
 * @param {string} id - Creator ID
 * @return {Promise} The creator object
 */
const fetchCreator = (id) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`creators/${id}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch user from database
 * @param {string} id - User ID
 * @return {Promise} The user object
 */
const fetchUser = (id) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`users/${id}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch course from database
 * @param {string} id - Course ID
 * @return {Promise} The course object
 */
const fetchCourse = (id) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`courses/${id}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch episode from database
 * @param {string} courseID - Course ID
 * @param {string} episodeID - Episode ID
 * @return {Promise} The episode object
 */
const fetchEpisode = (courseID, episodeID) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`courses/${courseID}/episodes/${episodeID}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch tags from database
 * @return {Promise} The tags objects
 */
const fetchTags = () => {
  return new Promise((resolve, reject) => {
    database
      .ref(`tags`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch process from database
 * @param {string} process - Process path
 * @return {Promise} The process object
 */
const fetchProcess = (process) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`processes/${process}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Listen for data changes for process
 * @param {string} process - Process path
 * @param {callback} callback - Success callback
 * @param {callback} errorCallback - Error callback
 * @return {Promise} The process object
 */
const listenForProcess = (process, callback, errorCallback) => {
  return database.ref(`processes/${process}`).on(
    'value',
    (snapshot) => {
      callback(snapshot.val());
    },
    (error) => {
      errorCallback(error);
    },
  );
};

/**
 * Fetch course transactions
 * @param {string} id - Course ID
 * @return {Promise} Array of transactions
 */
const fetchStudentsTransactionsForCourse = (id) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`transactions/${id}`)
      .once('value')
      .then((snapshot) => {
        var snapshotVal = snapshot.val();
        var transactions = [];
        if (snapshotVal) {
          var transactionsKeys = Object.keys(snapshotVal);
          transactionsKeys.forEach((key) => {
            var transaction = snapshotVal[key];
            if (transaction) {
              if (
                transaction.user_id &&
                transaction.amount >= 0 &&
                transaction.timestamp
              ) {
                transactions.push({
                  user_id: transaction.user_id,
                  amount: transaction.amount,
                  timestamp: transaction.timestamp,
                  currency: transaction.currency,
                });
              }
            }
          });
        }
        resolve(transactions);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch course analytics
 * @param {string} courseID - Course ID
 * @param {string} period - Data period ("Last week", "Last month" or "All")
 * @param {[string]} courseCreators - IDs of creators
 * @return {Promise} The analytics object
 */
const fetchCourseAnalytics = (courseID, period, courseCreators) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`transactions/${courseID}`)
      .once('value')
      .then((snapshot) => {
        var snapshotVal = snapshot.val();
        var transactions = [];
        if (snapshotVal) {
          var transactionsKeys = Object.keys(snapshotVal);
          transactionsKeys.forEach((key) => {
            var transaction = snapshotVal[key];
            if (transaction) {
              if (courseCreators) {
                if (!courseCreators.includes(transaction.user_id)) {
                  transactions.push(transaction);
                }
              } else {
                transactions.push(transaction);
              }
            }
          });
        }
        var filteredTransactions = [];
        transactions.forEach((transaction) => {
          var transactionTimestampNumber = transaction.timestamp;
          var missingDigits = 13 - `${transactionTimestampNumber}`.length;
          if (missingDigits > 0) {
            transactionTimestampNumber =
              transactionTimestampNumber * Math.pow(10, missingDigits);
          }
          var transactionTimestamp = moment(transactionTimestampNumber).format(
            'YYYY-MM-DD',
          );
          var periodTimestamp = moment().format('YYYY-MM-DD');
          if (period === 'Last week') {
            periodTimestamp = moment()
              .subtract(1, 'weeks')
              .format('YYYY-MM-DD');
          } else if (period === 'Last month') {
            periodTimestamp = moment()
              .subtract(1, 'months')
              .format('YYYY-MM-DD');
          } else if (period === 'All') {
            periodTimestamp = moment('2019-01-01').format('YYYY-MM-DD');
          }

          if (moment(transactionTimestamp).isAfter(periodTimestamp)) {
            filteredTransactions.push({ amount: transaction.amount });
          }
        });

        var analytics = {};
        analytics.purchases = filteredTransactions.length;
        analytics.sales = filteredTransactions
          .map((item) => item.amount)
          .reduce((a, b) => a + b, 0);

        var dataPeriod = 'all';
        if (period === 'Today') {
          dataPeriod = 'today';
        } else if (period === 'Last week') {
          dataPeriod = 'last_week';
        } else if (period === 'Last month') {
          dataPeriod = 'last_month';
        }

        var interestedUsers = [];
        fetchInterestedUsers(courseID)
          .then((users) => {
            if (users) {
              var usersIDs = Object.keys(users);

              usersIDs.forEach((id) => {
                var user = users[id];

                var userTimestampNumber = user.timestamp;
                var missingDigits = 13 - `${userTimestampNumber}`.length;
                if (missingDigits > 0) {
                  userTimestampNumber =
                    userTimestampNumber * Math.pow(10, missingDigits);
                }

                var userTimestamp = moment(userTimestampNumber).format(
                  'YYYY-MM-DD',
                );
                var periodTimestamp = moment().format('YYYY-MM-DD');

                if (period === 'Last week') {
                  periodTimestamp = moment()
                    .subtract(1, 'weeks')
                    .format('YYYY-MM-DD');
                } else if (period === 'Last month') {
                  periodTimestamp = moment()
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD');
                } else if (period === 'All') {
                  periodTimestamp = moment('2019-01-01').format('YYYY-MM-DD');
                }

                if (moment(userTimestamp).isAfter(periodTimestamp)) {
                  interestedUsers.push(users);
                }
              });
            }
          })
          .catch((error) => {
            console.log('ERROR', error);
          });

        fetchContent(`analytics/raw_data/${dataPeriod}.json`)
          .then((url) => {
            axios.get(url).then((response) => {
              var data = response.data;

              var courseData = data.filter(
                (event) => event.properties['COURSE_ID'] === courseID,
              );
              var screenViews = courseData.filter(
                (event) => event.event === 'SCREEN_VIEW',
              );
              var courseViews = screenViews.length;

              var coursePlays = courseData.filter(
                (event) => event.event === 'START_PLAYING_EPISODE',
              ).length;

              var forwardData = data.filter(
                (event) =>
                  event.properties['NEXT_COURSE_ID'] === courseID &&
                  event.properties['PREVIOUS_COURSE_ID'] === courseID,
              );
              var forward = forwardData.filter(
                (event) => event.event === 'FORWARD_EPISODE',
              );
              var forwardSeconds = forward
                .map((event) => event.properties['PLAYER_TIME'])
                .reduce((a, b) => a + b, 0);
              var backward = forwardData.filter(
                (event) => event.event === 'BACKWARD_EPISODE',
              );
              var backwardSeconds = backward
                .map((event) => event.properties['PLAYER_TIME'])
                .reduce((a, b) => a + b, 0);

              analytics.listenTime = Math.floor(
                backwardSeconds + forwardSeconds,
              );

              var skippedEmailSignUp = courseData.filter(
                (event) => event.event === 'SKIP_COLLECT_EMAIL',
              ).length;

              var clickBuyCourse = courseData.filter(
                (event) => event.event === 'CLICK_BUY_COURSE',
              ).length;

              analytics.courseViews = courseViews;
              analytics.coursePlays = coursePlays;

              analytics.conversion = {};
              analytics.conversion.courseViews = courseViews;
              analytics.conversion.emailSignUp = {};
              analytics.conversion.emailSignUp.value = interestedUsers.length;
              analytics.conversion.emailSignUp.data = interestedUsers;
              analytics.conversion.skippedEmailSignUp = skippedEmailSignUp;
              analytics.conversion.clickBuyCourse = clickBuyCourse;
              analytics.conversion.purchases = filteredTransactions.length;

              var trafficAll = courseViews;

              var trafficWeb = screenViews.filter(
                (item) =>
                  item.properties['SOURCE'] === 'DIRECT' ||
                  item.properties['SOURCE'] === 'direct' ||
                  !item.properties['SOURCE'],
              ).length;

              var trafficTwitter = screenViews.filter(
                (item) =>
                  item.properties['SOURCE'] === 'TWITTER' ||
                  item.properties['SOURCE'] === 'twitter',
              ).length;

              var trafficFacebook = screenViews.filter(
                (item) =>
                  item.properties['SOURCE'] === 'FACEBOOK' ||
                  item.properties['SOURCE'] === 'fcaebook',
              ).length;

              var trafficInstagram = screenViews.filter(
                (item) =>
                  item.properties['SOURCE'] === 'INSTAGRAM' ||
                  item.properties['SOURCE'] === 'instagram',
              ).length;

              analytics.trafficSources = {};
              analytics.trafficSources.all = trafficAll;
              analytics.trafficSources.web = trafficWeb;
              analytics.trafficSources.twitter = trafficTwitter;
              analytics.trafficSources.facebook = trafficFacebook;
              analytics.trafficSources.instagram = trafficInstagram;

              resolve(analytics);
            });
          })
          .catch((error) => {
            console.log('ERROR', error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch course purchases
 * @param {string} courseID - Course ID
 * @param {string} period - Data period ("Last week", "Last month" or "All")
 * @param {[string]} courseCreators - IDs of creators
 * @return {Promise} Array of purchases
 */
const fetchCoursePurchases = (courseID, period, courseCreators) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`transactions/${courseID}`)
      .once('value')
      .then((snapshot) => {
        var snapshotVal = snapshot.val();
        var transactions = [];
        if (snapshotVal) {
          var transactionsKeys = Object.keys(snapshotVal);
          transactionsKeys.forEach((key) => {
            var transaction = snapshotVal[key];
            if (transaction) {
              if (courseCreators) {
                if (!courseCreators.includes(transaction.user_id)) {
                  transactions.push(transaction);
                }
              } else {
                transactions.push(transaction);
              }
            }
          });
        }

        var filteredTransactions = [];
        transactions.forEach((transaction) => {
          var transactionTimestampNumber = transaction.timestamp;
          var missingDigits = 13 - `${transactionTimestampNumber}`.length;
          if (missingDigits > 0) {
            transactionTimestampNumber =
              transactionTimestampNumber * Math.pow(10, missingDigits);
          }
          var transactionTimestamp = moment(transactionTimestampNumber).format(
            'YYYY-MM-DD',
          );
          var periodTimestamp = moment().format('YYYY-MM-DD');
          if (period === 'Last week') {
            periodTimestamp = moment()
              .subtract(1, 'weeks')
              .format('YYYY-MM-DD');
          } else if (period === 'Last month') {
            periodTimestamp = moment()
              .subtract(1, 'months')
              .format('YYYY-MM-DD');
          } else if (period === 'All') {
            periodTimestamp = moment('2019-01-01').format('YYYY-MM-DD');
          }

          if (moment(transactionTimestamp).isAfter(periodTimestamp)) {
            filteredTransactions.push(transaction);
          }
        });

        var formattedTransactions = filteredTransactions.map((transaction) => {
          return fetchUser(transaction.user_id).then((user) => {
            transaction.user = user;
            return transaction;
          });
        });

        Promise.all(formattedTransactions)
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

/**
 * Fetch interested users from database
 * @param {string} courseID - Course ID
 * @return {Promise} Emails of interested users
 */
const fetchInterestedUsers = (courseID) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`courses/${courseID}/interested_users`)
      .once('value')
      .then((snapshot) => resolve(snapshot.val()))
      .catch((error) => reject(error));
  });
};

/**
 * Create user in database
 * @param {string} id - User ID
 * @param {string} email - User email address
 * @param {string} firstName - User first name
 * @param {string} lastName - User last name
 * @return {Promise} Void if success
 */
const createUserDatabase = (id, email, firstName, lastName) => {
  var signupTimestamp = Math.round(+new Date() / 1000);

  return new Promise((resolve, reject) => {
    database
      .ref(`users/${id}`)
      .update({
        id: id,
        email: email,
        first_name: firstName,
        last_name: lastName,
        signup_timestamp: signupTimestamp,
        image: {
          url:
            'https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/person.png?alt=media&token=affe89c1-5b83-4fd2-9081-f2bb2c2a56e0',
        },
        signup_source: 'creator',
      })
      .then(() => {
        database
          .ref(`creators/${id}`)
          .update({
            bio: '',
            courses_sold: 0,
            students_number: 0,
            id: id,
            email: email,
            first_name: firstName,
            last_name: lastName,
            image: {
              url:
                'https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/person.png?alt=media&token=affe89c1-5b83-4fd2-9081-f2bb2c2a56e0',
            },
          })
          .then(() => {
            sendSignUpEmail({ email: email, name: `${firstName} ${lastName}` });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Update creator profile
 * @param {object} profile - Creator object
 * @return {Promise} Void if success
 */
const updateCreatorProfile = (profile) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`creators/${profile.id}`)
      .update({
        first_name: profile.first_name,
        last_name: profile.last_name,
        bio: profile.bio,
        socials: profile.socials,
        image: {
          url: profile.image.url,
        },
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Update course image
 * @param {object} course - Course object
 * @return {Promise} Image object
 */
const updateCourseImage = (course) => {
  return new Promise((resolve, reject) => {
    var imageObject = {
      landscape: {
        url: '',
      },
      portrait: {
        url: '',
      },
      square: {
        url: '',
      },
    };

    var imagesPromises = [
      storeFile(`courses/${course.id}/images/landscape`, course.image),
      storeFile(`courses/${course.id}/images/portrait`, course.image),
      storeFile(`courses/${course.id}/images/square`, course.image),
    ];

    Promise.all(imagesPromises)
      .then((imagesUrls) => {
        if (imagesUrls && imagesUrls.length === 3) {
          imagesUrls.forEach((imageUrl) => {
            if (imageUrl.includes('landscape')) {
              imageObject.landscape.url = imageUrl;
            }
            if (imageUrl.includes('portrait')) {
              imageObject.portrait.url = imageUrl;
            }
            if (imageUrl.includes('square')) {
              imageObject.square.url = imageUrl;
            }
          });
          resolve(imageObject);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Update course background header image
 * @param {object} course - Course object
 * @return {Promise} Background Image url
 */
const updateCourseBackgroundImage = (course) => {
  return new Promise((resolve, reject) => {
    storeFile(`courses/${course.id}/background_image`, course.background_image)
      .then((url) => {
        if (url) {
          resolve(url);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Update course
 * @param {object} course - Course object
 * @param {boolean} updateCoverImage - Whether it should update the course cover image
 * @return {Promise} Void if success
 */
const updateCourse = (course, updateCoverImage, updateBackgroundImage) => {
  return new Promise((resolve, reject) => {
    var promises = [];

    if (updateCoverImage) {
      promises.push(updateCourseImage(course));
    }

    if (updateBackgroundImage) {
      promises.push(updateCourseBackgroundImage(course));
    }

    Promise.all(promises).then(async (res) => {
      var image = course.image;
      var backgroundImage = course.background_image;

      if (res.length > 0) {
        res.forEach((item) => {
          if (typeof item === 'string') {
            //  update background image
            backgroundImage = { url: item };
          } else if (typeof item === 'object') {
            // update cover image
            image = item;
          }
        });
      }

      var tags = null;

      if (course.category) {
        tags = {
          [course.category.id]: {
            id: course.category.id,
            name: course.category.value,
          },
        };
      }

      let slug;

      if (course.slug) {
        slug = slugify(course.slug, {
          replacement: '-',
          lower: true,
          strict: true,
        });
        
        const snapshot = await database.ref('courses')
          .orderByChild('slug')
          .equalTo(slug)
          .once("value");
        
        const response = snapshot.val();

        if (response) {
          const foundCourse = response[course.id];

          if (!foundCourse) {
            return reject('Course URL already exists, please try different course URL');
          }
        }
      }

      database
        .ref(`courses/${course.id}`)
        .update({
          title: course.title,
          subtitle: course.subtitle || null,
          description: course.description,
          launch_date: course.launchDate,
          release_frequency: course.releaseFrequency,
          image: image,
          background_image: backgroundImage,
          ...(slug ? { slug } : {}),
          ...(course.price ? { price: course.price } : {}),
          ...(course.fullPrice ? { full_price: course.fullPrice } : {}),
          currency: course.currency,
          refund_policy: course.refundPolicy,
          tags: tags,
          things_you_will_learn: course.thingsYouWillLearn,
          testimonials: course.testimonials,
          faqs: course.faqs,
          preview: course.preview || null,
          learner_welcome_email: course.learnerWelcomeEmail,
          enable_course_purchase_notification:
            course.enableCoursePurchaseNotification,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

/**
 * Update course creator's info
 * @param {Stirng} courseId 
 * @param {Object} doc 
 * @returns {Promise} void
 */
const updatedCourseCreatorInfo = (courseId, doc) => {
  return new Promise((resolve, reject) => {
    database.ref(`courses/${courseId}`)
      .update({
        creatorInfo: {
          first_name: doc.first_name,
          last_name: doc.last_name,
          bio: doc.bio || '',
          socials: doc.socials,
          image: { url: doc.image.url }
        }
      })
      .then(() => resolve())
      .catch(error => reject(error));
  });
};

/**
 * Update course status
 * @param {string} courseID - Course ID
 * @param {string} status - Course status
 * @return {Promise} Void if success
 */
const updateCourseStatus = (courseID, status) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`courses/${courseID}`)
      .update({ status: status })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

/**
 * Update course preview
 * @param {string} courseID - Course ID
 * @param {object} file - File object
 * @return {Promise} Void if success
 */
const updateCoursePreview = (courseID, file) => {
  return new Promise((resolve, reject) => {
    var key = database.ref(`courses/${courseID}/preview/id`).push().key;

    var previewObject = {
      id: key,
      file: {
        name: file.name,
        size: file.size,
        type: file.type,
        upload_timestamp: +new Date(),
      },
    };

    database
      .ref(`courses/${courseID}/preview`)
      .update(previewObject)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Add episode to course
 * @param {string} courseID - Course ID
 * @param {object} episode - Episode object
 * @return {Promise} Void if success
 */
const addEpisode = (courseID, episode) => {
  return new Promise((resolve, reject) => {
    var episodeNumber = null;
    if (parseInt(episode.number)) {
      episodeNumber = parseInt(episode.number);
    } else {
      reject();
    }

    database
      .ref(`courses/${courseID}/episodes`)
      .once('value')
      .then((snapshot) => {
        var snapshotVal = snapshot.val();
        var episodeNumberAlreadyAssigned = false;

        if (snapshotVal) {
          var keys = Object.keys(snapshotVal);
          keys.forEach((key) => {
            var episode = snapshotVal[key];
            if (episode.episode_number === episodeNumber) {
              episodeNumberAlreadyAssigned = true;
            }
          });
        }

        if (episodeNumberAlreadyAssigned) {
          reject({
            code: 'EPISODE_NUMBER_ALREADY_ASSIGNED',
            message: 'Episode number already assigned to another episode',
          });
        } else {
          var segments = [];
          episode.segments.forEach((segment, index) => {
            if (segment.start && segment.end) {
              var a = `${segment.start.hours || '00'}:${
                segment.start.minutes || '00'
              }:${segment.start.seconds || '00'}`.split(':');
              var startSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

              var a2 = `${segment.end.hours || '00'}:${
                segment.end.minutes || '00'
              }:${segment.end.seconds || '00'}`.split(':');
              var endSeconds = +a2[0] * 60 * 60 + +a2[1] * 60 + +a2[2];

              var segmentObject = {
                id: segment.id,
                segment_number: index + 1,
                title: segment.title,
                timestamps: {
                  start: startSeconds,
                  end: endSeconds,
                },
              };

              segments.push(segmentObject);
            }
          });

          var segmentsObject = {};
          segments.forEach((segment) => {
            segmentsObject[segment.id] = segment;
          });

          var episodeObject = {
            course_id: courseID,
            description: episode.description || '',
            duration: 0,
            episode_number: episodeNumber,
            free: episode.free,
            id: episode.id,
            release_timestamp: +new Date(),
            released: true,
            title: episode.title,
            url: '',
            segments: segmentsObject,
          };

          database
            .ref(`courses/${courseID}/episodes/${episode.id}`)
            .update(episodeObject)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateEpisodeOrder = (courseId, newEpisodeNumber, episodeId, episode) => {

  return new Promise((resolve, reject) => {

    if (newEpisodeNumber && courseId) {
      database
      .ref(`courses/${courseId}/episodes`)
      .once('value')
      .then(async (snapshot) => {
        var snapshotVal = snapshot.val();
        var keys = Object.keys(snapshotVal);

        var unsortedEpisodeArray = [];
        for (const key of keys) {
          var episodeFetched = snapshotVal[key];
          if (key !== episodeId) {
            unsortedEpisodeArray.push(episodeFetched)
          }
        }
        var sortedEpisodesByNumber = unsortedEpisodeArray.sort((a, b) => parseFloat(a.episode_number) - parseFloat(b.episode_number));
        sortedEpisodesByNumber.splice( newEpisodeNumber-1, 0, episode );

        for (const [index, ep] of sortedEpisodesByNumber.entries()) {
          try {
            await database.ref(`courses/${courseId}/episodes/${ep.id}`).update({ episode_number: index + 1 });
          } catch (e) {
            reject();
          }
        }

        resolve();
      });
    }
  });
  

  // get all episodes and their number
  // loop throuh sorted episodes
  // go to new number
  // increase the existing by 1 and all after
  // 


  /*
  database
    .ref(`courses/${episode.course_id}/episodes/${episode.id}`)
    .update(episodeObject)*/

}

/**
 * Update episode
 * @param {object} episode - Episode object
 * @return {Promise} Void if success
 */
const updateEpisode = (episode) => {
  return new Promise((resolve, reject) => {
    var episodeNumber = null;
    if (parseInt(episode.episode_number)) {
      episodeNumber = parseInt(episode.episode_number);
    } else {
      reject();
    }

    database
      .ref(`courses/${episode.course_id}/episodes`)
      .once('value')
      .then((snapshot) => {
        var snapshotVal = snapshot.val();
        var episodeNumberAlreadyAssigned = false;

        if (snapshotVal) {
          var keys = Object.keys(snapshotVal);
          
          if (episode.episode_number <= 0 || episode.episode_number > keys.length)  {
            reject({
              code: 'EPISODE_NUMBER_ALREADY_ASSIGNED',
              message: 'Episode number must be greater than zero and less than the total episode count.',
            });
          }
          /*
          keys.forEach((key, index) => {
            var episodeFetched = snapshotVal[key];
            if (
              episodeFetched.episode_number === episodeNumber &&
              episodeFetched.id !== episode.id
            ) {

              episodeNumberAlreadyAssigned = true;
            }
          });*/
        }
        

        updateEpisodeOrder(episode.course_id, episode.episode_number, episode.id, episode).then((epResult) => {

          // episode.episode_number = epResult.episode_number;
          if (episodeNumberAlreadyAssigned) {
            reject({
              code: 'EPISODE_NUMBER_ALREADY_ASSIGNED',
              message: 'Episode number already assigned to another episode',
            });
          } else {
            var segments = [];
            episode.segments.forEach((segment, index) => {
              if (segment.start && segment.end) {
                var a = `${segment.start.hours || '00'}:${
                  segment.start.minutes || '00'
                }:${segment.start.seconds || '00'}`.split(':');
                var startSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  
                var a2 = `${segment.end.hours || '00'}:${
                  segment.end.minutes || '00'
                }:${segment.end.seconds || '00'}`.split(':');
                var endSeconds = +a2[0] * 60 * 60 + +a2[1] * 60 + +a2[2];
  
                var segmentObject = {
                  id: segment.id,
                  segment_number: index + 1,
                  title: segment.title,
                  timestamps: {
                    start: startSeconds,
                    end: endSeconds,
                  },
                };
  
                segments.push(segmentObject);
              }
            });
  
            var segmentsObject = {};
            segments.forEach((segment) => {
              segmentsObject[segment.id] = segment;
            });
  
            var episodeObject = {
              title: episode.title,
              description: episode.description,
              episode_number: episodeNumber,
              free: episode.free,
              segments: segmentsObject,
              url: episode.url ? episode.url : '',
            };
  
            database
              .ref(`courses/${episode.course_id}/episodes/${episode.id}`)
              .update(episodeObject)
              .then(() => {
                if (!episode.file) {
                  database
                    .ref(
                      `courses/${episode.course_id}/episodes/${episode.id}/file`,
                    )
                    .remove()
                    .then(() => {
                      database
                        .ref(
                          `courses/${episode.course_id}/episodes/${episode.id}`,
                        )
                        .update({ duration: 0 })
                        .then(() => resolve())
                        .catch((error) => reject(error));
                    })
                    .catch((error) => {
                      reject(error);
                    });
                } else {
                  resolve();
                }
              })
              .catch((error) => {
                reject(error);
              });
          }
        });

        
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Delete episode
 * @param {string} courseID - Course ID
 * @param {string} episodeID - Episode ID
 * @return {Promise} Void if success
 */
const deleteEpisode = (courseID, episodeID) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`courses/${courseID}/episodes/${episodeID}`)
      .remove()
      .then(() => {
        removeProcess(`${courseID}/episodes/${episodeID}`);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * Set episode file
 * @param {string} courseID - Course ID
 * @param {string} episodeID - Episode ID
 * @param {object} file - File object
 * @return {Promise} Void if success
 */
const setEpisodeFile = (courseID, episodeID, file) => {
  return new Promise((resolve, reject) => {
    var episodeObject = {
      file: {
        name: file.name,
        size: file.size,
        type: file.type,
        upload_timestamp: +new Date(),
      },
    };

    database
      .ref(`courses/${courseID}/episodes/${episodeID}`)
      .update(episodeObject)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Set resource file
 * @param {string} episode - Episode
 * @param {string} courseID - Course ID
 * @param {object} resource - Resource
 * @param {string} url - Resource url
 * @return {Promise} Void if success
 */
const setResourceFile = (episode, courseID, resource, url) => {
  return new Promise((resolve, reject) => {
    var resourceObject = {
      id: resource.id,
      url: url,
      file: {
        name: resource.file.name,
        size: resource.file.size,
        type: resource.file.type,
        upload_timestamp: +new Date(),
      },
    };

    database
      .ref(
        `courses/${courseID}/episodes/${episode.id}/resources/${resource.id}`,
      )
      .update(resourceObject)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Remove resource from database
 * @param {object} episode - Episode
 * @param {object} resource - Resource
 * @return {Promise} Void if success
 */
const removeResourceFile = (episode, resource) => {
  return new Promise((resolve, reject) => {
    database
      .ref(
        `courses/${episode.course_id}/episodes/${episode.id}/resources/${resource.id}`,
      )
      .remove()
      .then(resolve())
      .catch((error) => reject(error));
  });
};

/**
 * Update creator's Stripe account in database
 * @param {object} req - Request object
 * @return {Promise} Void if success
 */
const updateCreatorStripeAccount = (req) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`creators/${req.creatorID}/stripe/`)
      .update({
        pending_requirements: req.pendingRequirements,
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Remove process from database
 * @param {string} path - Process path
 * @return {Promise} Void if success
 */
const removeProcess = (path) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`processes/${path}`)
      .remove()
      .then(resolve())
      .catch((error) => reject(error));
  });
};

/**
 * Check if creators exists in database
 * @param {string} email - Creator email address
 * @return {Promise} Void if success
 */
const checkIfCreatorExists = (email) => {
  return new Promise((resolve, reject) => {
    fetch(`https://avid-back-end.herokuapp.com/userID?email=${email}`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res) {
          if (res.uid) {
            fetchCreator(res.uid)
              .then((creatorRes) => {
                if (creatorRes) {
                  resolve();
                } else {
                  reject();
                }
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject();
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

/**
 * Calculate course revenue
 * @param {string} id - Course ID
 * @param {[string]} courseCreators - IDs of course creators
 * @return {Promise} Amount of course revenue
 */
const calculateCourseRevenue = (id, courseCreators) => {
  return new Promise((resolve, reject) => {
    database
      .ref(`transactions/${id}`)
      .once('value')
      .then((snapshot) => {
        var snapshotVal = snapshot.val();
        var transactions = [];
        if (snapshotVal) {
          var transactionsKeys = Object.keys(snapshotVal);
          transactionsKeys.forEach((key) => {
            var transaction = snapshotVal[key];
            if (transaction) {
              if (courseCreators) {
                if (!courseCreators.includes(transaction.user_id)) {
                  transactions.push(transaction);
                }
              } else {
                transactions.push(transaction);
              }
            }
          });
        }
        resolve({
          course_id: id,
          amount: transactions
            .map((item) => item.amount)
            .reduce((a, b) => a + b, 0),
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Create course
 * @param {string} creatorID - Creator ID
 * @param {object} course - Course object
 * @return {Promise} Return ID of course created
 */
const createCourse = async (creatorID, course) => {
  return new Promise((resolve, reject) => {
    var key = database.ref('courses').push().key;

    var courseImageObject = {
      landscape: {
        url: '',
      },
      portrait: {
        url: '',
      },
      square: {
        url: '',
      },
    };

    var currency = course.price.currency.split('(')[1].split(')')[0];

    database
      .ref(`courses/${key}`)
      .set({
        id: key,
        title: course.title,
        description: course.description,
        price: course.price.preSellAmount
          ? course.price.preSellAmount
          : course.price.amount,
        full_price: course.price.amount,
        currency: currency,
        creation_timestamp: Math.round(+new Date() / 1000),
        release_timestamp: Math.round(+new Date() / 1000),
        released: true,
        status: 'unpublished',
        students_number: 0,
        app_store_product_id: key,
        refund_policy:
          '100% satisfaction guarantee. If you don’t love it for any reason, you can request a refund within 30 days. You just need to show us you have attempted the course assignments.',
        launch_date: course.releaseDateWindow,
        things_you_will_learn: course.skills,
        creators: {
          [creatorID]: {
            id: creatorID,
          },
        },
        image: courseImageObject,
      })
      .then(() => {
        // Add course to creator's profile
        database
          .ref(`creators/${creatorID}/courses/${key}`)
          .update({ id: key })
          .then(() => {
            // Add course to user's profile
            database
              .ref(`users/${creatorID}/courses/${key}`)
              .update({ id: key })
              .then(() => {
                // Create transaction
                var transactionKey = database.ref(`transactions/${key}`).push()
                  .key;
                database
                  .ref(`transactions/${key}/${transactionKey}`)
                  .update({
                    amount: 0,
                    course_id: key,
                    id: transactionKey,
                    platform: 'web',
                    timestamp: +new Date(),
                    user_id: creatorID,
                  })
                  .then(() => {
                    // Add transaction to user's profile
                    database
                      .ref(`users/${creatorID}/transactions/${transactionKey}`)
                      .update({ id: transactionKey, course_id: key })
                      .then(() => resolve({ courseID: key }))
                      .catch((error) => {
                        console.log('ERROR', error);
                        reject(error);
                      });
                  })
                  .catch((error) => {
                    console.log('ERROR', error);
                    reject(error);
                  });
              })
              .catch((error) => {
                console.log('ERROR', error);
                reject(error);
              });
          })
          .catch((error) => {
            console.log('ERROR', error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log('ERROR', error);
        reject(error);
      });
  });
};

export {
  fetchCreator,
  fetchUser,
  fetchCourse,
  fetchEpisode,
  fetchTags,
  fetchProcess,
  listenForProcess,
  fetchStudentsTransactionsForCourse,
  fetchCourseAnalytics,
  fetchCoursePurchases,
  fetchInterestedUsers,
  createUserDatabase,
  updateCreatorProfile,
  updateCourse,
  updateCourseStatus,
  updateCoursePreview,
  addEpisode,
  updateEpisode,
  deleteEpisode,
  setEpisodeFile,
  setResourceFile,
  removeResourceFile,
  updateCreatorStripeAccount,
  removeProcess,
  checkIfCreatorExists,
  calculateCourseRevenue,
  createCourse,
  updatedCourseCreatorInfo
};
