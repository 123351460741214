import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { connect } from 'react-context-global-store';

// Initialize Firebase App
import '../../services/FirebaseService';
import * as firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Analytics Service
import {
  initialize as initializeAnalyticsService,
  identifyUser,
  setTrackingPlatform,
  setUserProperties,
  resetAnalyticsTracking,
} from '../../services/AnalyticsService';

import { fetchCreator } from '../../services/FirebaseService/Database';

import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import MyCourses from '../MyCourses/MyCourses';
import CreateCourse from '../CreateCourse/CreateCourse';
import AddEpisode from '../AddEpisode/AddEpisode';
import EditEpisode from '../EditEpisode/EditEpisode';
import EditCourse from '../EditCourse/EditCourse';
import Course from '../Course/Course';
import Settings from '../Settings/Settings';
import AnalyticsDetails from '../AnalyticsDetails/AnalyticsDetails';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

if (process.env.NODE_ENV === 'development') {
  initializeAnalyticsService('DEV_TOKEN');
} else {
  initializeAnalyticsService('2e2f7311d72add4762e9b3ede9b177c4');
}

const ROUTES = {
  default: '/',
  signIn: '/login',
  signUp: '/register',
  myCourses: '/my-courses',
  createCourse: '/create-course',
  addEpisode: '/course/:courseID/add-episode',
  editEpisode: '/course/:courseID/episode/:episodeID/edit',
  editCourse: '/course/:courseID/edit',
  course: '/course/:courseID',
  courseAnalytics: '/course/:courseID/analytics/:analyticsType',
  settings: '/settings',
};

const App = (storeProps) => {
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true,
    user: null,
  });

  React.useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        setTrackingPlatform();
        if (user) {
          fetchCreator(user.uid)
            .then((res) => {
              setAuthState({
                authenticated: true,
                initializing: false,
                user: res,
              });
              identifyUser(res.id);
              setUserProperties(res);
            })
            .catch((error) => {
              console.log(error);
              resetAnalyticsTracking();
            });
        } else {
          setAuthState({
            authenticated: false,
            initializing: false,
            user: null,
          });
          resetAnalyticsTracking();
        }
      }),
    [setAuthState],
  );

  function handleComponent(path, props) {
    if (path === ROUTES.signIn) {
      if (!authentication.authenticated) {
        return <SignIn />;
      }
      return <Redirect to={ROUTES.myCourses} />;
    }

    if (path === ROUTES.signUp) {
      if (!authentication.authenticated) {
        return <SignUp {...props} {...storeProps} />;
      } else {
        return <Redirect to="/my-courses" />;
      }
    }

    if (!authentication.authenticated && !authentication.initializing) {
      return <Redirect to={ROUTES.signIn} />;
    }

    switch (path) {
      case ROUTES.default:
        return (
          <Redirect
            to={ROUTES.myCourses}
            {...props}
            {...storeProps}
            firebaseUser={authentication.user}
          />
        );
      case ROUTES.myCourses:
        return (
          <MyCourses
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.createCourse:
        return (
          <CreateCourse
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.addEpisode:
        return (
          <AddEpisode
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.editEpisode:
        return (
          <EditEpisode
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.editCourse:
        return (
          <EditCourse
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.courseAnalytics:
        return (
          <AnalyticsDetails
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.course:
        return (
          <Course
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      case ROUTES.settings:
        return (
          <Settings
            firebaseUser={authentication.user}
            {...props}
            {...storeProps}
          />
        );
      default:
        return <LoadingScreen />;
    }
  }

  if (authentication.initializing) {
    return <LoadingScreen />;
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path={ROUTES.default}>
            <Redirect to={ROUTES.myCourses} />
          </Route>
          <Route path={ROUTES.signIn}>{handleComponent(ROUTES.signIn)}</Route>
          <Route path={ROUTES.signUp}>{handleComponent(ROUTES.signUp)}</Route>
          <Route
            path={`${ROUTES.editCourse}`}
            render={(props) => handleComponent(ROUTES.editCourse, props)}
          />
          <Route
            path={`${ROUTES.addEpisode}`}
            render={(props) => handleComponent(ROUTES.addEpisode, props)}
          />
          <Route
            path={`${ROUTES.editEpisode}`}
            render={(props) => handleComponent(ROUTES.editEpisode, props)}
          />
          <Route
            path={`${ROUTES.courseAnalytics}`}
            render={(props) => handleComponent(ROUTES.courseAnalytics, props)}
          />
          <Route
            path={`${ROUTES.course}`}
            render={(props) => handleComponent(ROUTES.course, props)}
          />
          <Route
            path={ROUTES.myCourses}
            render={(props) => handleComponent(ROUTES.myCourses, props)}
          />
          <Route
            path={ROUTES.createCourse}
            render={(props) => handleComponent(ROUTES.createCourse, props)}
          />
          <Route
            path={ROUTES.settings}
            render={(props) => handleComponent(ROUTES.settings, props)}
          ></Route>
          <Route path="*">
            <Redirect to={ROUTES.myCourses} />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default connect(App, ['data']);
