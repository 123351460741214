var mixpanel = require('mixpanel-browser');

/**
 * Initialize MixPanel
 * @param {string} token - MixPanel token
 */
const initialize = (token) => {
  mixpanel.init(token);
};

/**
 * Identify user for MixPanel
 * @param {string} id - User id
 */
const identifyUser = (id) => {
  mixpanel.identify(id);
};

/**
 * Reset analytics for MixPanel
 */
const resetAnalyticsTracking = () => {
  mixpanel.reset();
};

/**
 * Set tracking platform for events
 */
const setTrackingPlatform = () => {
  mixpanel.register({ PLATFORM: 'WEB', PRODUCT: 'CREATOR_STUDIO' });
};

/**
 * Set MixPanel user properties
 * @param {Object} user - The user object
 */
const setUserProperties = (user) => {
  var email = user.email;
  var id = user.id;
  var firstName = user.first_name;
  var lastName = user.last_name;
  var name = `${firstName} ${lastName}`;
  var created = user.signup_timestamp;

  mixpanel.people.set({
    $email: email,
    $distinct_id: id,
    $first_name: firstName,
    $last_name: lastName,
    $name: name,
    $created: created,
  });

  mixpanel.register({
    $email: email,
    $distinct_id: id,
    $first_name: firstName,
    $last_name: lastName,
    $name: name,
    $created: created,
  });
};

/**
 * Track screen
 * @param {string} screen - Screen to track
 * @param {string} properties - Additional properties
 */
const trackScreen = (screen, properties) => {
  var propertiesCopy = properties;
  propertiesCopy['SCREEN'] = screen;
  mixpanel.track('SCREEN_VIEW', propertiesCopy);
};

/**
 * Track event
 * @param {string} event - Event to track
 * @param {string} properties - Additional properties
 */
const trackEvent = (event, properties) => {
  mixpanel.track(event, properties);
};

export {
  initialize,
  identifyUser,
  resetAnalyticsTracking,
  setTrackingPlatform,
  setUserProperties,
  trackScreen,
  trackEvent,
};
