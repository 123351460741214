import React from 'react';
import { Link } from 'react-router-dom';

import styles from './CourseStatusAlert.module.scss';

import Button from '../Button/Button';

import { ReactComponent as Close } from '../../assets/img/close.svg';
import { ReactComponent as Unpublish } from '../../assets/img/unpublish.svg';
import { ReactComponent as See } from '../../assets/img/see.svg';
import { ReactComponent as Calendar } from '../../assets/img/calendar.svg';
import { ReactComponent as Rocket } from '../../assets/img/rocket.svg';
import { ReactComponent as Dancing } from '../../assets/img/dancing.svg';

export default class CourseStatusAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      courseStatus: this.props.status,
      statusSelected: this.props.status,
    };
  }

  render() {
    const { courseStatus, statusSelected } = this.state;
    const {
      courseID,
      slug,
      onSave,
      onClose,
      shouldConnectStripe,
      courseStatusAlertSuccess,
    } = this.props;

    return (
      <div className={styles.popupContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => onClose()} />
          {!courseStatusAlertSuccess && (
            <ChangeStatus
              courseStatus={courseStatus}
              statusSelected={statusSelected}
              shouldConnectStripe={shouldConnectStripe}
              onSave={onSave}
              onClose={onClose}
              onStatusSelect={(status) =>
                this.setState({ statusSelected: status })
              }
            />
          )}
          {courseStatusAlertSuccess && statusSelected === 'launch' && (
            <UpdatedStatusIllustrationAlert courseID={courseID} slug={slug} />
          )}
          {courseStatusAlertSuccess && statusSelected === 'pre-order' && (
            <UpdatedStatusAlert
              title="Your course is on pre-order"
              description="Share your link with the world!"
              slug={slug}
              showLink={true}
              courseID={courseID}
            />
          )}
          {courseStatusAlertSuccess && statusSelected === 'unpublished' && (
            <UpdatedStatusAlert
              title="Your course is unpublished"
              description="You can change this any time."
              showLink={false}
            />
          )}
          {courseStatusAlertSuccess && statusSelected === 'showcase' && (
            <UpdatedStatusAlert
              title="Your course is showcased"
              description="You can change this any time."
              showLink={false}
            />
          )}
        </div>
      </div>
    );
  }
}

const ChangeStatus = (props) => {
  const {
    courseStatus,
    statusSelected,
    shouldConnectStripe,
    onStatusSelect,
    onSave,
    onClose,
  } = props;

  var isSaveButtonEnabled = true;
  if (shouldConnectStripe) {
    if (statusSelected === 'pre-order' || statusSelected === 'launch') {
      isSaveButtonEnabled = false;
    }
  }
  return (
    <>
      <p className={styles.title}>Course status</p>
      <div className={styles.statusesContainer}>
        <CourseStatus
          name="Unpublish"
          description="Hide your course details from public view."
          current={courseStatus === 'unpublished'}
          selected={statusSelected === 'unpublished'}
          img={<Unpublish className={styles.statusImage} />}
          onClick={() => onStatusSelect('unpublished')}
        />
        <CourseStatus
          name="Showcase"
          description="Learners can see course details, but can't purchase or pre-order."
          current={courseStatus === 'showcase'}
          selected={statusSelected === 'showcase'}
          img={<See className={styles.statusImage} />}
          onClick={() => onStatusSelect('showcase')}
        />
        <CourseStatus
          name="Pre-order"
          description="Learners can pre-order your course before its official launch."
          current={courseStatus === 'pre-order'}
          shouldConnectStripe={shouldConnectStripe}
          selected={statusSelected === 'pre-order'}
          img={<Calendar className={styles.statusImage} />}
          onClick={() => onStatusSelect('pre-order')}
        />
        <CourseStatus
          name="Launch"
          description="Learners can buy and access your course instantly."
          current={courseStatus === 'launch'}
          shouldConnectStripe={shouldConnectStripe}
          selected={statusSelected === 'launch'}
          img={<Rocket className={styles.statusImage} />}
          onClick={() => onStatusSelect('launch')}
        />
      </div>
      <div
        className={styles.saveButton}
        onClick={() => (isSaveButtonEnabled ? onSave(statusSelected) : null)}
      >
        <Button
          text="Update course status"
          small
          disabled={!isSaveButtonEnabled}
        />
      </div>
      <div className={styles.cancelButton} onClick={() => onClose()}>
        <Button text="Cancel" small secondary />
      </div>
    </>
  );
};

const CourseStatus = (props) => {
  const {
    name,
    description,
    current,
    selected,
    img,
    shouldConnectStripe,
    onClick,
  } = props;

  var statusDescriptionStripeInitialText = null;
  if (name === 'Pre-order') {
    statusDescriptionStripeInitialText = (
      <span className={styles.statusDescription}>
        Before your course can be pre-ordered, you need to{' '}
        <Link to="/settings?section=payments">connect a Stripe account</Link> so
        you can take payments.
      </span>
    );
  } else if (name === 'Launch') {
    statusDescriptionStripeInitialText = (
      <span className={styles.statusDescription}>
        Before you can launch your course, you need to{' '}
        <Link to="/settings?section=payments">connect a Stripe account</Link> so
        you can take payments.
      </span>
    );
  }
  return (
    <div
      className={`${styles.status} ${selected ? styles.statusSelected : null}`}
      onClick={() => onClick()}
    >
      <div>{img}</div>
      <div className={styles.statusText}>
        <div className={styles.statusNameContainer}>
          <span className={styles.statusName}>{name}</span>
          {current && (
            <span className={styles.currentStatus}>(current status)</span>
          )}
        </div>
        {!shouldConnectStripe && (
          <span className={styles.statusDescription}>{description}</span>
        )}
        {shouldConnectStripe && statusDescriptionStripeInitialText}
      </div>
    </div>
  );
};

const UpdatedStatusIllustrationAlert = ({ slug, courseID }) => {
  const link = slug ? `s/${slug}` : courseID;

  return (
    <div className={styles.statusUpdatedAlert}>
      <Dancing className={styles.alertIllustration} />
      <p className={styles.statusUpdatedAlertTitle}>Congratulations</p>
      <p className={styles.statusUpdatedAlertDescription}>
        Your course is now live. Don’t forget to share your course link:
      </p>
      <div className={styles.courseURLBox}>
        <a
          href={`https://learner.avid.fm/course/${link}`}
          target="_blank"
          rel="noopener noreferrer"
        >{`https://learner.avid.fm/course/${link}`}</a>
      </div>
    </div>
  );
};

const UpdatedStatusAlert = ({ title, description, showLink, slug, courseID }) => {
  const link = slug ? `s/${slug}` : courseID;

  return (
    <div className={styles.statusUpdatedAlert}>
      <p className={styles.statusUpdatedAlertTitle}>{title}</p>
      <p className={styles.statusUpdatedAlertDescription}>{description}</p>
      {showLink && (
        <div className={styles.courseURLBox}>
          <a
            href={`https://learner.avid.fm/course/${link}`}
            target="_blank"
            rel="noopener noreferrer"
          >{`https://learner.avid.fm/course/${link}`}</a>
        </div>
      )}
    </div>
  );
};
