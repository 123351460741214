import React from 'react';

import styles from './FormSegments.module.scss';

import Input from '../../Input/Input';
import TimePicker from '../../TimePicker/TimePicker';

import Plus from '../../../assets/img/plus_secondary.svg';
import { ReactComponent as Warning } from '../../../assets/img/warning.svg';
import { ReactComponent as Bin } from '../../../assets/img/bin.svg';

const FormSegments = (props) => {
  const {
    label,
    description,
    titlePlaceholder,
    startPlaceholder,
    endPlaceholder,
    addSegment,
    segments,
    onTitleChange,
    onStartChange,
    onEndChange,
    onRemoveSegment,
    error,
  } = props;
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      {segments &&
        segments.map((item) => {
          var errorMessage = null;
          var errorType = null;
          if (error) {
            if (error.id === item.id) {
              errorMessage = error.error;
              errorType = error.type;
            }
          }
          return (
            <Segment
              titlePlaceholder={titlePlaceholder}
              startPlaceholder={startPlaceholder}
              endPlaceholder={endPlaceholder}
              titleValue={item.title}
              startValue={item.start}
              endValue={item.end}
              key={item.id}
              onTitleChange={(e) => onTitleChange(e, item)}
              onStartChange={(start) => onStartChange(start, item)}
              onEndChange={(end) => onEndChange(end, item)}
              onRemoveSegment={() =>
                onRemoveSegment ? onRemoveSegment(item) : null
              }
              error={errorMessage}
              errorType={errorType}
            />
          );
        })}
      <div className={styles.addSegment} onClick={addSegment}>
        <img src={Plus} alt="Add more timestamps" />
        <span className={styles.addSegmentLabel}>Add more timestamps</span>
      </div>
    </div>
  );
};

export default FormSegments;

class Segment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showStartTimePicker: false,
      showEndTimePicker: false,

      start: this.props.startValue,
      end: this.props.endValue,
    };
  }

  render() {
    const {
      titlePlaceholder,
      startPlaceholder,
      endPlaceholder,
      titleValue,
      onTitleChange,
      onStartChange,
      onEndChange,
      onRemoveSegment,
      error,
      errorType,
    } = this.props;

    const { showStartTimePicker, showEndTimePicker, start, end } = this.state;

    var startInputValue = null;
    var startHoursValue = null;
    var startMinutesValue = null;
    var startSecondsValue = null;
    if (start) {
      if (start.hours) {
        startHoursValue = `${start.hours}`;
      }
      if (start.minutes) {
        startMinutesValue = `${start.minutes}`;
      }
      if (start.seconds) {
        startSecondsValue = `${start.seconds}`;
      }
      startInputValue = `${startHoursValue || '00'}:${
        startMinutesValue || '00'
      }:${startSecondsValue || '00'}`;
    }

    var endInputValue = null;
    var endHoursValue = null;
    var endMinutesValue = null;
    var endSecondsValue = null;
    if (end) {
      if (end.hours) {
        endHoursValue = `${end.hours}`;
      }
      if (end.minutes) {
        endMinutesValue = `${end.minutes}`;
      }
      if (end.seconds) {
        endSecondsValue = `${end.seconds}`;
      }
      endInputValue = `${endHoursValue || '00'}:${endMinutesValue || '00'}:${
        endSecondsValue || '00'
      }`;
    }

    return (
      <div className={styles.inputContainer}>
        <div className={styles.inputContentContainer}>
          <Input
            type="text"
            hideLabel={true}
            placeholder={titlePlaceholder}
            value={titleValue}
            onChange={(e) => onTitleChange(e)}
            inputStyle={{ paddingRight: '50px' }}
          />
          <div className={styles.removeButton}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (onRemoveSegment) {
                  onRemoveSegment();
                }
              }}
            >
              <Bin />
            </div>
          </div>
        </div>
        {errorType === 'TITLE' && (
          <div className="errorContainer" style={{ marginBottom: '24px' }}>
            <Warning />
            <span className="errorMessage">{error}</span>
          </div>
        )}
        <div className={styles.timeContainer}>
          <div className={styles.startContainer}>
            <Input
              type="text"
              placeholder={startPlaceholder}
              value={startInputValue || ''}
              onClick={() => {
                this.setState({
                  showStartTimePicker: true,
                });
              }}
              hideLabel
            />
            {errorType === 'START' && (
              <div className="errorContainer" style={{ marginBottom: '16px' }}>
                <Warning />
                <span className="errorMessage">{error}</span>
              </div>
            )}
            {showStartTimePicker && (
              <div className={styles.timePickerContainer}>
                <TimePicker
                  hours={startHoursValue}
                  minutes={startMinutesValue}
                  seconds={startSecondsValue}
                  onHideTimePicker={() =>
                    this.setState({ showStartTimePicker: false })
                  }
                  onValueChange={(hours, minutes, seconds) => {
                    this.setState(
                      {
                        start: {
                          hours: hours,
                          minutes: minutes,
                          seconds: seconds,
                        },
                        showStartTimePicker: false,
                      },
                      () => {
                        onStartChange(this.state.start);
                      },
                    );
                  }}
                  onApply={(hours, minutes, seconds) => {
                    this.setState(
                      {
                        start: {
                          hours: hours,
                          minutes: minutes,
                          seconds: seconds,
                        },
                        showStartTimePicker: false,
                      },
                      () => {
                        onStartChange(this.state.start);
                      },
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.endContainer}>
            <Input
              type="text"
              placeholder={endPlaceholder}
              value={endInputValue || ''}
              onClick={() => {
                this.setState({
                  showEndTimePicker: true,
                });
              }}
              hideLabel
            />
            {errorType === 'END' && (
              <div className="errorContainer" style={{ marginBottom: '16px' }}>
                <Warning />
                <span className="errorMessage">{error}</span>
              </div>
            )}
            {showEndTimePicker && (
              <div className={styles.timePickerContainer}>
                <TimePicker
                  hours={endHoursValue}
                  minutes={endMinutesValue}
                  seconds={endSecondsValue}
                  onHideTimePicker={() =>
                    this.setState({ showEndTimePicker: false })
                  }
                  onValueChange={(hours, minutes, seconds) => {
                    this.setState(
                      {
                        end: {
                          hours: hours,
                          minutes: minutes,
                          seconds: seconds,
                        },
                        showEndTimePicker: false,
                      },
                      () => {
                        onEndChange(this.state.end);
                      },
                    );
                  }}
                  onApply={(hours, minutes, seconds) => {
                    this.setState(
                      {
                        end: {
                          hours: hours,
                          minutes: minutes,
                          seconds: seconds,
                        },
                        showEndTimePicker: false,
                      },
                      () => {
                        onEndChange(this.state.end);
                      },
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
