import React from 'react';
import styles from './SelectSearch.module.scss';

import Arrow from '../../assets/img/select_arrow_down.svg';

export default class SelectSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: false,
      query: '',
      selectedOption: this.props.selectedOption,
      options: this.props.options,
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showList: false });
    }
  }

  handleSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  render() {
    const { showList, selectedOption, query, options } = this.state;
    const { onSelect } = this.props;

    var searchedValues = [];
    if (options) {
      searchedValues = options.filter((item) =>
        item.value.toUpperCase().includes(query.toUpperCase()),
      );
    }

    return (
      <div className={styles.container} ref={this.wrapperRef}>
        <div
          className={styles.select}
          onClick={() => this.setState({ showList: !showList })}
        >
          <span className={styles.selectOption}>{selectedOption}</span>
          <img src={Arrow} alt="Select" className={styles.selectArrow} />
        </div>
        {showList && (
          <div className={styles.dropdownContainer}>
            <input
              type="text"
              placeholder="Search"
              className={styles.inputSearch}
              onChange={(e) => this.handleSearchChange(e)}
            />
            <ul className={styles.list}>
              {searchedValues.map((option) => {
                return (
                  <li
                    className={styles.listItem}
                    key={option.id}
                    onClick={() => {
                      this.setState({
                        selectedOption: option.value,
                        query: '',
                        showList: false,
                      });

                      if (onSelect) {
                        onSelect(option.value);
                      }
                    }}
                  >
                    {option.value}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
