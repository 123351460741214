import React from 'react';

import styles from './Toast.module.scss';

const Toast = (props) => {
  const { text, error, success } = props;

  var toastClassName = styles.toast;
  var textClassName = styles.text;

  if (error) {
    toastClassName += ` ${styles.error}`;
    textClassName += ` ${styles.errorText}`;
  }

  if (success) {
    toastClassName += ` ${styles.success}`;
    textClassName += ` ${styles.successText}`;
  }

  return (
    <div className={styles.container}>
      <div className={toastClassName}>
        <p className={textClassName}>{text}</p>
      </div>
    </div>
  );
};

export default Toast;
