import React, { useState } from 'react';
import styles from './FormCoursePurchaseEmail.module.scss';

import Checkbox from '../../Checkbox/Checkbox';

const FormCoursePurchaseEmail = (props) => {
  const { label, description, checkboxValue, onCheckboxSelection } = props;

  const [checkboxSelected, setCheckboxSelected] = useState(
    checkboxValue ? 'On' : 'Off',
  );

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.checkboxes}>
        <div className={styles.checkbox}>
          <Checkbox
            label="On"
            selected={checkboxSelected === 'On'}
            onClick={() => {
              setCheckboxSelected('On');
              onCheckboxSelection(true);
            }}
          />
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            label="Off"
            selected={checkboxSelected === 'Off'}
            onClick={() => {
              setCheckboxSelected('Off');
              onCheckboxSelection(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormCoursePurchaseEmail;
