import React from 'react';

import * as firebase from 'firebase/app';
import styles from './Settings.module.scss';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import FormInputText from '../../components/Form/FormInputText/FormInputText';
import FormChangePassword from '../../components/Form/FormChangePassword/FormChangePassword';
import FormUploadImage from '../../components/Form/FormUploadImage/FormUploadImage';
import FormSocialAccounts from '../../components/Form/FormSocialAccounts/FormSocialAccounts';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import Toast from '../../components/Toast/Toast';

import { ReactComponent as Check } from '../../assets/img/check.svg';
import { ReactComponent as Warning } from '../../assets/img/warning.svg';

import {
  fetchCreator,
  updateCreatorProfile,
  updateCreatorStripeAccount,
} from '../../services/FirebaseService/Database';

import { storeFile } from '../../services/FirebaseService/Storage';

import {
  createStripeConnectAccount,
  fetchAccountLink,
  fetchStripeAccount,
} from '../../services/StripeService';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      accountSetup: false,
      accountLogin: false,
      showToast: false,
      showAccountToast: false,
      sectionSelected: 'Profile',
      sections: ['Profile', 'Account', 'Payments'],
      authorProfile: {
        error: {
          text: '',
          category: '',
        },
        socialAccountsErrors: [],
        socialAccounts: {},
      },
      account: {
        password: '',
        newPassword: '',
        error: {
          text: '',
          category: '',
        },
      },
    };

    this.renderSection = this.renderSection.bind(this);
    this.saveProfileChanges = this.saveProfileChanges.bind(this);
    this.saveAccountChanges = this.saveAccountChanges.bind(this);
  }

  componentDidMount() {
    const { firebaseUser } = this.props;

    if (firebaseUser) {
      fetchCreator(firebaseUser.id)
        .then((res) => {
          if (res) {
            const authorProfile = res;
            authorProfile.error = {
              text: '',
              category: '',
            };
            authorProfile.full_name = `${authorProfile.first_name} ${authorProfile.last_name}`;
            authorProfile.socialAccountsErrors = [];
            authorProfile.socialAccounts = {
              twitter: {
                type: 'twitter',
                placeholder: 'Twitter (https://twitter.com/yourname)',
                url: '',
              },
              facebook: {
                type: 'facebook',
                placeholder: 'Facebook (https://facebook.com/yourname)',
                url: '',
              },
              linkedin: {
                type: 'linkedin',
                placeholder: 'LinkedIn (https://linkedin.com/yourname)',
                url: '',
              },
              instagram: {
                type: 'instagram',
                placeholder: 'Instagram (https://instagram.com/yourname)',
                url: '',
              },
              youtube: {
                type: 'youtube',
                placeholder: 'Youtube (https://youtube.com/yourname)',
                url: '',
              },
              website: {
                type: 'website',
                placeholder: 'Website (https://yourwebsite.com)',
                url: '',
              },
            };

            if (authorProfile.socials) {
              const existingSocials = Object.keys(authorProfile.socials);
              existingSocials.map(
                (key) =>
                  (authorProfile.socialAccounts[key] =
                    authorProfile.socials[key]),
              );
            } else {
              authorProfile.socials = {};
            }

            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const section = searchParams.get('section');

            var sectionSelected = 'Profile';
            if (section === 'account') {
              sectionSelected = 'Account';
            }
            if (section === 'payments') {
              sectionSelected = 'Payments';
            }

            if (authorProfile.stripe && authorProfile.stripe.id) {
              this.handleStripeAccountVerification(authorProfile.stripe.id)
                .then((res) => {
                  if (!res.details_submitted) {
                    return this.setState({
                      isLoading: false,
                      authorProfile,
                      sectionSelected,
                      accountSetup: true,
                      accountLogin: false,
                    });
                  }

                  if (
                    res.charges_enabled &&
                    res.details_submitted &&
                    !res.payouts_enabled
                  ) {
                    return this.setState({
                      isLoading: false,
                      authorProfile,
                      sectionSelected,
                      accountLogin: true,
                      accountSetup: false,
                    });
                  }

                  if (
                    res.charges_enabled &&
                    res.details_submitted &&
                    res.payouts_enabled
                  ) {
                    updateCreatorStripeAccount({
                      creatorID: firebaseUser.id,
                      pendingRequirements: false
                    })
                      .then(() => {
                        authorProfile.stripe.pending_requirements = false;
                        this.setState({
                          isLoading: false,
                          authorProfile,
                          sectionSelected,
                          accountLogin: false,
                          accountSetup: false,
                        });
                      })
                      .catch((error) => {
                        console.log('ERROR', error);
                      });
                  }
                })
                .catch((error) => {
                  console.log('ERROR', error);
                  this.setState({
                    isLoading: false,
                    authorProfile,
                    sectionSelected,
                  });
                });
            } else {
              this.setState({
                isLoading: false,
                authorProfile,
                sectionSelected,
              });
            }
          }
        })
        .catch((error) => {
          console.log('ERROR', error);
        });
    }
  }

  handleStripeAccountVerification = (stripeAccountID) => {
    // Check if Stripe Connect account is verified and connected after Stripe Connect's onboarding (return_url)
    return new Promise((resolve, reject) => {
      fetchStripeAccount(stripeAccountID)
        .then((account) => {
          resolve(account);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  scrollToElement = (id) => {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  };

  saveProfileChanges = () => {
    const { authorProfile } = this.state;

    if (authorProfile.full_name === '') {
      var authorProfileCopy = authorProfile;
      authorProfileCopy.error = {
        text: 'Please enter your full name',
        category: 'NAME',
      };
      this.setState({
        authorProfile: authorProfileCopy,
      });

      this.scrollToElement('creator_name');
      return;
    } else {
      const splittedName = authorProfile.full_name.split(' ');
      const firstName = splittedName[0];
      const lastName = splittedName.slice(1, splittedName.length).join(' ');
      // eslint-disable-next-line
      var authorProfileCopy = authorProfile;
      authorProfileCopy.first_name = firstName;
      authorProfileCopy.last_name = lastName;
      this.setState({
        authorProfile: authorProfileCopy,
      });
    }

    if (authorProfile.bio !== '') {
      // eslint-disable-next-line
      var authorProfileCopy = authorProfile;
      authorProfileCopy.error = {
        text: '',
        category: '',
      };
      this.setState({
        authorProfile: authorProfileCopy,
      });
    }

    const errors = Object.keys(authorProfile.socialAccounts)
      // eslint-disable-next-line
      .map((key) => {
        const socialAccount = authorProfile.socialAccounts[key];
        if (socialAccount.url !== '') {
          const valid = /^(http|https):\/\/[^ "]+$/.test(socialAccount.url);
          if (!valid) {
            return {
              type: socialAccount.type,
              text: 'Please enter a valid URL',
            };
          }
          // eslint-disable-next-line
          return;
        }
      })
      .filter((item) => item !== undefined);

    if (errors.length > 0) {
      // eslint-disable-next-line
      var authorProfileCopy = authorProfile;
      authorProfileCopy.socialAccountsErrors = errors;
      this.setState({
        authorProfile: authorProfileCopy,
      });

      this.scrollToElement('creator_socials');
      return;
    }

    const authorProfileObjectCopy = authorProfile;
    const socialsKeys = Object.keys(authorProfile.socialAccounts);
    socialsKeys.forEach((key) => {
      if (
        authorProfile.socialAccounts[key] &&
        authorProfile.socialAccounts[key].url !== ''
      ) {
        authorProfileObjectCopy.socials[key] =
          authorProfile.socialAccounts[key];
      } else {
        authorProfileObjectCopy.socials[key] = null;
      }
    });
    authorProfileObjectCopy.error = {
      text: '',
      category: '',
    };
    authorProfileObjectCopy.socialAccountsErrors = [];

    this.setState(
      {
        authorProfile: authorProfileObjectCopy,
      },
      () => {
        // If creator uploaded a new image, store it and then update Firebase db
        if (authorProfile.loadedImage) {
          storeFile(
            `creators/${authorProfile.id}/images/profile_picture`,
            authorProfile.loadedImage,
          )
            .then((url) => {
              if (url) {
                authorProfileObjectCopy.image.url = url;
                this.setState({ authorProfile: authorProfileObjectCopy });
                updateCreatorProfile(authorProfileObjectCopy)
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((error) => {
                    console.log('ERROR', error);
                    this.setState({ showToast: true });
                  });
              }
            })
            .catch((error) => {
              console.log('ERROR', error);
              this.setState({ showToast: true });
            });
        } else {
          updateCreatorProfile(authorProfileObjectCopy)
            .then(() => {
              window.location.reload();
            })
            .catch((error) => {
              console.log('ERROR', error);
              this.setState({ showToast: true });
            });
        }
      },
    );
  };

  saveAccountChanges = () => {
    const { account } = this.state;
    const { password, newPassword } = this.state.account;

    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password,
    );

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            this.setState({
              account: {
                password: '',
                newPassword: '',
                error: {
                  text: '',
                  category: '',
                },
              },
              showAccountToast: true,
            });

            setTimeout(() => {
              this.setState({
                showAccountToast: false,
              });
            }, 5000);
          })
          .catch((error) => {
            const accountCopy = account;
            accountCopy.error = {
              text: error.message,
              category: 'PASSWORD',
            };
            this.setState({
              account: accountCopy,
            });

            this.scrollToElement('account_password');
          });
      })
      .catch((error) => {
        if (error.code === 'auth/wrong-password') {
          var accountCopy = account;
          accountCopy.error = {
            text: 'The password you entered is wrong',
            category: 'PASSWORD',
          };
          this.setState({
            account: accountCopy,
          });

          this.scrollToElement('account_password');
        } else {
          // eslint-disable-next-line
          var accountCopy = account;
          accountCopy.error = {
            text: error.message,
            category: 'PASSWORD',
          };
          this.setState({
            account: accountCopy,
          });

          this.scrollToElement('account_password');
        }
      });

    if (account.password === '') {
      // eslint-disable-next-line
      var accountCopy = account;
      accountCopy.error = {
        text: 'Please enter your password',
        category: 'PASSWORD',
      };
      this.setState({
        account: accountCopy,
      });

      this.scrollToElement('account_password');
      return;
    }

    if (account.newPassword === '') {
      // eslint-disable-next-line
      var accountCopy = account;
      accountCopy.error = {
        text: 'Please enter your new password',
        category: 'PASSWORD',
      };
      this.setState({
        account: accountCopy,
      });

      this.scrollToElement('account_password');
      return;
    }

    const accountObjectCopy = account;
    accountObjectCopy.error = {
      text: '',
      category: '',
    };
    this.setState({
      account: accountObjectCopy,
    });
  };

  renderSection = () => {
    const {
      sectionSelected,
      authorProfile,
      account,
      accountSetup,
      accountLogin,
    } = this.state;

    if (sectionSelected === 'Profile') {
      let profilePicture = null;
      if (authorProfile.loadedImage) {
        profilePicture = authorProfile.loadedImage;
      } else if (authorProfile.image.url) {
        profilePicture = authorProfile.image.url;
      }

      return (
        <div className={styles.formsContainer}>
          <div className={styles.formContainer} id="creator_name">
            <FormInputText
              label="Your name"
              placeholder="Enter your full name"
              description="Use your full legal name, no nicknames or shortened versions."
              error={
                authorProfile.error.category === 'NAME'
                  ? authorProfile.error.text
                  : null
              }
              value={authorProfile.full_name}
              onChange={(e) => {
                const authorProfileCopy = authorProfile;
                authorProfileCopy.full_name = e.target.value;
                this.setState({
                  authorProfile: authorProfileCopy,
                });
              }}
            />
          </div>
          <div className={styles.formContainer} id="creator_bio">
            <FormInputText
              label="Your bio"
              placeholder="Enter your bio"
              description="Introduce yourself to learners. This will appear on your courses under the ‘About’ section."
              long
              error={
                authorProfile.error.category === 'BIO'
                  ? authorProfile.error.text
                  : null
              }
              value={authorProfile.bio}
              onChange={(e) => {
                const authorProfileCopy = authorProfile;
                authorProfileCopy.bio = e.target.value;
                this.setState({
                  authorProfile: authorProfileCopy,
                });
              }}
            />
          </div>
          <div className={styles.formContainer}>
            <FormUploadImage
              label="Profile picture"
              description="The recommended image formats are PNG and JPG with the width and height of 800x800 pixels."
              buttonTitle="Upload your picture"
              image={profilePicture}
              onImageLoaded={(image) => {
                const authorProfileCopy = authorProfile;
                authorProfileCopy.loadedImage = image;
                this.setState({
                  authorProfile: authorProfileCopy,
                });
              }}
            />
          </div>
          <div className={styles.formContainer} id="creator_socials">
            <FormSocialAccounts
              label="Your social media accounts"
              socialAccounts={authorProfile.socialAccounts}
              error={authorProfile.socialAccountsErrors}
              onChange={(social, e) => {
                const authorProfileCopy = JSON.parse(
                  JSON.stringify(authorProfile),
                );
                authorProfileCopy.socialAccounts[social].url = e.target.value;
                this.setState({
                  authorProfile: authorProfileCopy,
                });
              }}
            />
          </div>
          <div
            onClick={this.saveProfileChanges}
            className={styles.saveButtonContainer}
          >
            <Button text="Save" small />
          </div>
        </div>
      );
    }
    if (sectionSelected === 'Account') {
      return (
        <div className={styles.formsContainer}>
          <div className={styles.formContainer}>
            <FormInputText
              label="Email"
              value={authorProfile.email}
              type="email"
              disabled
            />
          </div>
          <div className={styles.formContainer} id="account_password">
            <FormChangePassword
              currentPasswordValue={account.password}
              newPasswordValue={account.newPassword}
              onCurrentPasswordChange={(e) => {
                const accountCopy = account;
                accountCopy.password = e.target.value;
                this.setState({
                  account: accountCopy,
                });
              }}
              onNewPasswordChange={(e) => {
                const accountCopy = account;
                accountCopy.newPassword = e.target.value;
                this.setState({
                  account: accountCopy,
                });
              }}
              error={
                account.error.category === 'PASSWORD'
                  ? account.error.text
                  : null
              }
            />
          </div>
          <div
            onClick={this.saveAccountChanges}
            className={styles.saveButtonContainer}
          >
            <Button text="Save" small />
          </div>
        </div>
      );
    }
    if (sectionSelected === 'Payments') {
      return (
        <div>
          <h3 className={styles.paymentsSectionTitle}>
            How would you like to get paid?
          </h3>
          <Payment
            title="Stripe"
            description="Stripe is an online payment processing platform for millions of online businesses worldwide. It takes less than 10 minutes to set up a Stripe account and connect it to AVID."
            buttonText="Connect"
            type="stripe"
            accountSetup={accountSetup}
            accountLogin={accountLogin}
            stripe={authorProfile.stripe}
            learnMore="https://stripe.com"
            onConnect={() => {
              this.setState({ isLoading: true });
              createStripeConnectAccount({
                email: this.props.firebaseUser.email,
                creatorID: this.props.firebaseUser.id,
                origin: window.location.origin,
              })
                .then((url) => {
                  if (url) {
                    window.location = url;
                  }
                })
                .catch((error) => {
                  console.log('ERROR', error);
                });
            }}
          />
          <Payment
            title="Other payment methods"
            description="If Stripe isn’t for you, let us know of your preferred payments provider by sending us a request."
            buttonText="Request here"
            type="other"
            onConnect={() => (window.location = 'mailto:feedback@avid.fm')}
          />
        </div>
      );
    }
  };

  render() {
    const {
      isLoading,
      showToast,
      showAccountToast,
      sectionSelected,
      sections,
    } = this.state;

    if (isLoading) {
      return <LoadingScreen />;
    }
    return (
      <>
        <Header user />
        {showToast && (
          <Toast text="An error occurred, please try again later." />
        )}
        {showAccountToast && <Toast text="Settings succesfully saved!" />}
        <div
          className={styles.container}
          style={{ marginTop: showToast || showAccountToast ? '-90px' : null }}
        >
          <h2 className={styles.title}>Settings</h2>
          <ul className={styles.navigation}>
            {sections.map((section) => {
              return (
                <li
                  className={`${styles.navigationItem} ${
                    section === sectionSelected
                      ? styles.navigationItemSelected
                      : null
                  }`}
                  key={section}
                  onClick={() => {
                    window.history.replaceState(
                      null,
                      null,
                      `${window.location.origin}${
                        window.location.pathname
                      }?section=${section.toLocaleLowerCase()}`,
                    );
                    this.setState({
                      sectionSelected: section,
                    });
                  }}
                >
                  <span>{section}</span>
                  {section === sectionSelected && (
                    <span className={styles.navigationItemSelectedIndicator} />
                  )}
                </li>
              );
            })}
          </ul>
          {this.renderSection()}
        </div>
      </>
    );
  }
}

const Payment = (props) => {
  const {
    title,
    description,
    buttonText,
    stripe,
    type,
    learnMore,
    onConnect,
    accountSetup,
    accountLogin,
  } = props;

  const handleAccountLink = () => {
    fetchAccountLink(stripe.id).then((url) => (window.location.href = url));
  };

  return (
    <div className={styles.paymentContainer}>
      <div className={styles.paymentInfo}>
        <h3 className={styles.paymentTitle}>{title}</h3>
        {description && (
          <p className={styles.paymentDescription}>{description}</p>
        )}
      </div>

      {!stripe && (
        <div className={styles.paymentButtonsContainer}>
          <div
            className={`${styles.paymentButton} ${
              type !== 'other' ? styles.connectPaymentButton : null
            }`}
            onClick={() => (onConnect ? onConnect() : null)}
          >
            {buttonText}
          </div>
          {learnMore && (
            <a
              href={learnMore}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.learnMorePaymentButton}
            >
              Learn more
            </a>
          )}
        </div>
      )}
      {stripe && stripe.pending_requirements && (
        <div className={styles.paymentMethodInfoContainer}>
          <Warning alt="Stripe needs more information. Please log in" />
          {accountSetup && (
            <span className={styles.paymentMethodFailed}>
              Stripe needs more information. Please continue to{' '}
              <span
                onClick={handleAccountLink}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                setup
              </span>
            </span>
          )}
          {accountLogin && (
            <span className={styles.paymentMethodFailed}>
              Stripe needs more information. Please{' '}
              <a
                href="https://dashboard.stripe.com"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                login
              </a>
            </span>
          )}
        </div>
      )}
      {stripe && !stripe.pending_requirements && (
        <div className={styles.paymentMethodInfoContainer}>
          <Check
            src={Check}
            className={styles.paymentMethodConnectedIcon}
            alt="Connected"
          />
          <span className={styles.paymentMethodConnected}>Connected</span>
        </div>
      )}
    </div>
  );
};
