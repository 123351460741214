import React from 'react';
import styles from './Tip.module.scss';

import { ReactComponent as Information } from '../../assets/img/information.svg';
import { ReactComponent as Play } from '../../assets/img/play_icon_outline.svg';
import { ReactComponent as Pause } from '../../assets/img/pause_icon_outline.svg';
import { ReactComponent as Read } from '../../assets/img/read_icon.svg';
import { ReactComponent as Close } from '../../assets/img/close_icon.svg';

export default class Tip extends React.Component {
  constructor(props) {
    super(props);

    this.audioRef = React.createRef();

    this.state = {
      play: false,
      isExpanded: false,
      showRead: true,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    // If audioLink is different, the component was re-rendered. Set play to false
    if (props.audioLink !== this.props.audioLink) {
      this.setState({ play: false, showRead: false });
    }

    if (props.reset) {
      this.audioRef.current.src = '';
      this.audioRef.current.src = props.audioLink;
      this.setState({ play: false });
    }
  }

  componentWillUnmount() {
    // Component was re-rendered. Set empty source to disable play/pause for keyboard inputs
    this.audioRef.current.src = '';
  }

  render() {
    const {
      title,
      text,
      audioLink,
      onTogglePlay,
      onReadAudioTip,
      onReadWrittenTip,
    } = this.props;
    const { play, isExpanded, showRead } = this.state;

    return (
      <div
        className={`${styles.container} ${
          isExpanded ? styles.containerBorder : null
        }`}
      >
        <div className={styles.headerContainer}>
          <div className={styles.textContainer}>
            <div className={styles.infoIconContainer}>
              <Information className={styles.infoIcon} />
            </div>
            <p className={styles.text}>{title}</p>
          </div>
          <div className={styles.actionsContainer}>
            <div
              className={styles.actionContainer}
              onClick={() => {
                play
                  ? this.audioRef.current.pause()
                  : this.audioRef.current.play();
                this.setState({ play: !play });
                if (onTogglePlay) {
                  onTogglePlay();
                }
                if (!play) {
                  if (onReadAudioTip) {
                    onReadAudioTip();
                  }
                }
              }}
            >
              {play ? (
                <>
                  <span>Pause</span>
                  <Pause style={{ marginLeft: '2px' }} />
                </>
              ) : (
                <>
                  <span>Listen</span>
                  <Play />
                </>
              )}
            </div>
            {showRead && (
              <div
                className={styles.actionContainer}
                onClick={() => {
                  if (!isExpanded) {
                    if (onReadWrittenTip) {
                      onReadWrittenTip();
                    }
                  }
                  this.setState({ isExpanded: !isExpanded });
                }}
              >
                <span>Read</span>
                <div className={styles.readIconContainer}>
                  {isExpanded ? (
                    <Close style={{ marginLeft: '5px' }} />
                  ) : (
                    <Read />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {isExpanded && (
          <div className={styles.tipTextContainer}>
            <p
              className={styles.tipText}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        )}
        <audio
          ref={this.audioRef}
          onPlaying={() => {
            this.setState({ play: true });
            if (onTogglePlay) {
              onTogglePlay();
            }
            if (!play) {
              if (onReadAudioTip) {
                onReadAudioTip();
              }
            }
          }}
          onPause={() => {
            this.setState({ play: false });
            if (onTogglePlay) {
              onTogglePlay();
            }
          }}
          onEnded={() => this.setState({ play: false })}
          onTimeUpdate={() => {
            var audio = this.audioRef.current;
            if (audio.currentTime > audio.duration - 15) {
              this.setState({ showRead: true });
            }
          }}
          src={audioLink}
        />
      </div>
    );
  }
}
