import React, { useState } from 'react';
import styles from './FormWelcomeEmail.module.scss';

import Checkbox from '../../Checkbox/Checkbox';
import Input from '../../Input/Input';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';
import { ReactComponent as Close } from '../../../assets/img/close.svg';
import LearnerWelcomeEmailPreview from '../../../assets/img/learner_welcome_email_preview.png';

const FormWelcomeEmail = (props) => {
  const {
    label,
    error,
    checkboxValue,
    messageValue,
    onCheckboxSelection,
    onTextChange,
  } = props;

  const [checkboxSelected, setCheckboxSelected] = useState(
    checkboxValue ? 'On' : 'Off',
  );
  const [showExample, setShowExample] = useState(false);

  return (
    <>
      {showExample && (
        <div>
          <div className={styles.popupContainer}>
            <div className={styles.popupContentContainer}>
              <Close
                className={styles.closeButton}
                onClick={() => setShowExample(false)}
              />
              <p className={styles.popupTitle}>Learner welcome email</p>
              <p className={styles.popupDescription}>This is an example</p>
              <img
                alt="Email preview"
                src={LearnerWelcomeEmailPreview}
                className={styles.emailPreview}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <p className={styles.label}>{label}</p>
        <p className={styles.description}>
          Send a customised welcome email to each learner that purchases your
          course. Their name will be included automatically, so you only need to
          write the body of the email.{' '}
          <span onClick={() => setShowExample(true)}>See an example.</span>
        </p>
        <div className={styles.checkboxes}>
          <div className={styles.checkbox}>
            <Checkbox
              label="On"
              selected={checkboxSelected === 'On'}
              onClick={() => {
                setCheckboxSelected('On');
                onCheckboxSelection(true);
              }}
            />
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              label="Off"
              selected={checkboxSelected === 'Off'}
              onClick={() => {
                setCheckboxSelected('Off');
                onCheckboxSelection(false);
              }}
            />
          </div>
        </div>
        {checkboxSelected === 'On' && (
          <Input
            hideLabel
            placeholder="Write a customised welcome message"
            veryLong
            style={{ marginTop: '16px', marginBottom: 0 }}
            value={messageValue}
            onChange={(e) => onTextChange(e)}
          />
        )}
        {checkboxSelected === 'On' && error && (
          <div className="errorContainer">
            <Warning />
            <span className="errorMessage">{error}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default FormWelcomeEmail;
