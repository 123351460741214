import React, { useState } from 'react';
import styles from './ReleaseDate.module.scss';

import Select from '../Select/Select';

import { ReactComponent as Warning } from '../../assets/img/warning.svg';

import moment from 'moment';

const ReleaseDate = (props) => {
  const { value, onMonthChange, error, tip } = props;

  const [month, setMonth] = useState(value);

  var monthOptions = [{ value: value, selected: false }];

  for (var i = 0; i < 12; i++) {
    monthOptions.push({
      value: `${moment().add(i, 'month').format('MMMM')} ${moment()
        .add(i, 'month')
        .format('YYYY')}`,
      selected: false,
    });
  }

  // Remove duplicate values in case 'value' is equal to one of the months pushed in the for loop
  var filteredMonthOptions = [
    ...new Map(monthOptions.map((item) => [item['value'], item])).values(),
  ];
  filteredMonthOptions.forEach((option) => {
    option.selected = false;
    if (option.value === month) {
      option.selected = true;
    }
  });

  return (
    <div className={styles.container}>
      <p className={styles.label}>Release date window</p>
      <div className={styles.optionsContainer}>
        <div className={styles.selectsContainer}>
          <div className={styles.selectOptionContainer}>
            <Select
              options={filteredMonthOptions}
              onChange={(e) => {
                setMonth(e.target.value);
                onMonthChange(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.releaseDateContainer}>
          <p className={styles.releaseDateLabel}>Expected release date</p>
          <p className={styles.releaseDateValue}>
            {month === 'Select month' ? '―' : `${month}`}
          </p>
        </div>
      </div>
      {error && (
        <div className="errorContainer">
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
      {tip && <div className={styles.tipContainer}>{tip}</div>}
    </div>
  );
};

export default ReleaseDate;
