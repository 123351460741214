import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Course.module.scss';

import Button from '../Button/Button';

import { ReactComponent as AVIDSound } from '../../assets/img/avid_sound.svg';
import { ReactComponent as Share } from '../../assets/img/share.svg';

const Course = (props) => {
  const { course, onShareCourseLink } = props;

  var courseRevenueFormatted = (course.revenue / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: course.currency,
  });

  return (
    <div className={styles.container}>
      <div
        className={styles.coverImage}
        style={{
          backgroundImage: `url(${course.image.square.url})`,
        }}
      >
        {!course.image.square.url && (
          <div className={styles.coverArtPlaceholderContainer}>
            <AVIDSound />
          </div>
        )}
        <span
          className={`${styles.responsiveCourseStatus} ${
            course.status === 'launch' ? styles.coursePublished : null
          }`}
        >
          {course.status.toUpperCase()}
        </span>
      </div>
      <div className={styles.infoContainer}>
        <div>
          <div className={styles.courseTitleContainer}>
            <h3 className={[`zeroMargin zeroPadding ${styles.courseTitle}`]}>
              {course.title}
            </h3>
            <span
              className={`${styles.courseStatus} ${
                course.status === 'launch' ? styles.coursePublished : null
              }`}
            >
              {course.status.toUpperCase()}
            </span>
          </div>
          <div className={styles.courseDetails}>
            <p className="textBase zeroMargin">
              Enrolled students: {course.students_number}
            </p>
            <p className="textBase zeroMargin">
              Total revenue: {courseRevenueFormatted}
            </p>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Link
            className={styles.manageCourseContainer}
            to={{ pathname: `/course/${course.id}`, course: course }}
          >
            <Button text="Manage course" small secondary />
          </Link>
          <div
            className={styles.shareButton}
            onClick={() => (onShareCourseLink ? onShareCourseLink() : null)}
          >
            <Share />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
