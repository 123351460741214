import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import styles from './CreateCourse.module.scss';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import Toast from '../../components/Toast/Toast';

import FormInputText from '../../components/Form/FormInputText/FormInputText';
import FormThingsToLearn from '../../components/Form/FormThingsToLearn/FormThingsToLearn';
import FormPricing from '../../components/Form/FormPricing/FormPricing';
import Tip from '../../components/Tip/Tip';
import EditStep from '../../components/EditStep/EditStep';
import ReleaseDate from '../../components/ReleaseDate/ReleaseDate';

import Dancing from '../../assets/img/dancing.svg';
import BackArrow from '../../assets/img/back_arrow.svg';
import { ReactComponent as Check } from '../../assets/img/check.svg';

import { createCourse } from '../../services/FirebaseService/Database';

import { trackEvent } from '../../services/AnalyticsService';

export default class CreateCourse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coursePublished: false,
      isCoursePublishing: false,
      numberOfSteps: 6,
      currentStep: 1,
      error: null,
      databaseError: null,

      steps: [
        {
          id: 'COURSE_TITLE',
          stepNumber: 1,
          title: '',
        },
        {
          id: 'COURSE_DESCRIPTION',
          stepNumber: 2,
          description: '',
        },
        {
          id: 'COURSE_THINGS_TO_LEARN',
          stepNumber: 3,
          description: '',
          skills: [
            {
              index: 0,
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              placeholder: 'E.g How to close deals',
              value: '',
            },
            {
              index: 1,
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              placeholder: 'E.g Sales skills and techniques',
              value: '',
            },
            {
              index: 2,
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              placeholder: 'E.g Go-to-market strategy',
              value: '',
            },
            {
              index: 3,
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              placeholder: 'E.g Confidence to get first 20 customers',
              value: '',
            },
            {
              index: 4,
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              placeholder: 'E.g Hire a team',
              value: '',
            },
            {
              index: 5,
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              placeholder: 'E.g How to be a Founder and a Salesperson',
              value: '',
            },
          ],
        },
        {
          id: 'COURSE_PRICING',
          stepNumber: 4,
          price: {
            currency: 'Select a currency',
            amount: 'Select a price',
            preSellAmount: 'Select a price',
          },
        },
        {
          id: 'COURSE_RELEASE_DATE',
          stepNumber: 5,
          releaseDate: `${moment().format('MMMM')} ${moment().format('YYYY')}`,
        },
      ],
    };

    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.skipStep = this.skipStep.bind(this);
    this.publishCourse = this.publishCourse.bind(this);
  }

  renderComponentForCurrentStep = () => {
    const { currentStep, steps, isCoursePublishing, error } = this.state;
    if (currentStep === 1) {
      return (
        <>
          <div className={styles.formInputContainer}>
            <div className={styles.stepHeader}>
              <h2 className={styles.stepTitle}>Let’s name your audio course</h2>
              <p className="textBase">
                Choose an easy to read, memorable title. 150 characters max.
              </p>
              <p className="textBase">
                Don’t worry, you can always come back and edit this later.
              </p>
            </div>
            <FormInputText
              label="Course title"
              placeholder="E.g My first audio course"
              error={error}
              value={steps[0].title}
              tip={
                <Tip
                  title="Let’s walk you through naming your course"
                  audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_title.mp3?alt=media&token=dfcc0f78-33f7-495f-a9d8-25b5f8e29b15"
                  text="
                Everything starts with a great title. Think of it as the ultimate first impression! That’s why it’s crucial to create a title that clearly communicates what you’ll be teaching. 
                <br><br>
                Try starting with a draft. Take some time to look at other course titles in your space. Write down a list of keywords, play around with them and see what fits best.
                <br><br>
                An impactful title answers the following questions:
                <br><br>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Target audience - Who should listen to this course?</b></li>
                </ul>
                <ul style='margin: 0; padding-left: 18px'>
                  <li><b>Outcome - What skills will you learn from this course?*</b></li>
                </ul>
                <br>
                *Remember, a course is the bridge between a <i>learner</i> and the <i>outcome they’d like to achieve</i>. For that reason, mentioning the outcome in the title is an extremely valuable selling point.
                <br><br>
                <b>Let’s deconstruct some good &amp; bad examples:</b>
                <br><br>
                <b>Hell no:</b> Become a Freelancer
                <br>
                <b>Why?</b> Not specific
                <br><br>
                <b>F*ck yes:</b> Freelance on Fiverr &amp; Become a Top Rated Seller
                <br>
                <b>Why?</b> It specifies the platform “Fiverr” and highlights the outcome by adding “top-rated seller.”
                <br><br>
                <b>***</b>
                <br><br>
                <b>Hell no:</b> Become a better storyteller
                <br>
                <b>Why?</b> Vague/generic
                <br><br>
                <b>F*ck yes:</b> Storytelling for public speaking
                <br>
                <b>Why?</b> It highlights a specific use case.
                <br><br>
                <b>***</b>
                <br><br>
                <b>Pro tip:</b> Willing to be niche is key. It’s okay to turn away some learners in the process. You’re better off attracting a specific group and really speak to their hearts.
                "
                  onReadAudioTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'AUDIO',
                    })
                  }
                  onReadWrittenTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'TEXT',
                    })
                  }
                />
              }
              onChange={(e) => {
                var stepsCopy = [...steps];
                stepsCopy[0].title = e.target.value;
                this.setState({
                  steps: stepsCopy,
                });
              }}
            />
          </div>

          <div onClick={this.nextStep}>
            <Button text="Continue" big />
          </div>
        </>
      );
    }
    if (currentStep === 2) {
      return (
        <>
          <div className={styles.formInputContainer}>
            <div className={styles.stepHeader}>
              <h2 className={styles.stepTitle}>
                Now, let’s write a course overview
              </h2>
              <p className="textBase">
                The overview should give learners a clear idea about your
                course, and get them excited about learning with you. It can be
                as short or as long as you like.
              </p>
              <p className="textBase">
                You can always come back and edit this later.
              </p>
            </div>
            <FormInputText
              label="Course overview"
              placeholder="Write about the goal of this course and what people will learn."
              long={true}
              error={error}
              value={steps[1].description}
              tip={
                <Tip
                  title="Let’s walk you through writing your course overview"
                  audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_overview.mp3?alt=media&token=ed379d2b-7e3b-447e-ae83-ef4a60fd3bfc"
                  text="
                Now that your title has managed to intrigue learners, we’ll use the course overview to win them over. 
                <br><br>
                Learners want results, so try answering these questions to help them decide if your course is a good fit:
                <br><br>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>What is the outcome?</b></li>
                  If it’s not clear in the title, expand on it here.
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>How can the skills learned be applied in different scenarios?</b></li>
                  Paint the learner a picture of how these skills come in handy.
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Why should people care about this course?</b></li>
                  This step is crucial for learners on the fence. Try to give them your best elevator pitch. Tell them why these skills are important to learn now, not later on.
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Who is the course for?</b></li>
                  You want to be specific here. Again, don’t be afraid of turning away some learners who aren’t right. You can always make adjacent courses in the future.
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Why should they learn from you <i>specifically</i>?</b></li>
                  You want to gain learners trust and showcase your credibility. Share your past experiences. What pain did you have to endure? What were the challenges you faced?
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>How much effort is required?</b></li>
                  Let’s get more practical here. How big of a program is this? How much time will your learners have to commit?
                </ul>
                "
                  onReadAudioTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'AUDIO',
                    })
                  }
                  onReadWrittenTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'TEXT',
                    })
                  }
                />
              }
              onChange={(e) => {
                var stepsCopy = [...steps];
                stepsCopy[1].description = e.target.value;
                this.setState({
                  steps: stepsCopy,
                });
              }}
            />
          </div>

          <div onClick={this.nextStep}>
            <Button text="Continue" big />
          </div>
          <div className={styles.skipButtonContainer} onClick={this.skipStep}>
            <Button text="I'll do this later" secondary big />
          </div>
        </>
      );
    }
    if (currentStep === 3) {
      return (
        <>
          <div className={styles.formInputContainer}>
            <div className={styles.stepHeader}>
              <h2 className={styles.stepTitle}>
                Next, list the key skills learners will gain
              </h2>
              <p className="textBase">
                This is a great way to give learners a snapshot of the specific
                skills they'll come away with after completing your course.
              </p>
              <p className="textBase">
                You can always come back and edit this later.
              </p>
            </div>
            <FormThingsToLearn
              label="Skills list"
              skills={steps[2].skills}
              tip={
                <Tip
                  title="Let’s walk you through listing key skills"
                  audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_skill_list.mp3?alt=media&token=1332657c-4679-4522-a8d2-6baca525adb3"
                  text="
                Great for making it this far! Now it’s time to lay out the skills learners will gain from your course. 
                <br><br>
                I know we’ve touched on learning outcomes in the course overview, but there could be more granular skills you’d like to highlight.
                <br><br>
                Break the learning outcome into smaller milestones that are specific and measurable. Think about potential milestones in between the major goals. Keep it short and sweet, bullet-point style.
                <br><br>
                Lead these skills with verbs and how-tos. Here are some examples:
                <br><br>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Manage emotions around money</b></li>
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Make smart financial decisions</b></li>
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Develop an opportunity mindset</b></li>
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Gain confidence to get your first 20 customers</b></li>
                </ul>
                <ul style='margin: 0; padding-left: 18px'>
                  <li><b>How to hire a team</b></li>
                </ul>
                <br>
                The skills list also helps your learners mark their progress, and help you plan out your course! All you need from here is to break down these milestones further to create a detailed syllabus.
                "
                  onReadAudioTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'AUDIO',
                    })
                  }
                  onReadWrittenTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'TEXT',
                    })
                  }
                />
              }
              addSkill={() => {
                var stepsCopy = [...steps];
                stepsCopy[2].skills.push({
                  index: stepsCopy[2].skills.length,
                  id:
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15),
                  placeholder: 'Enter a skill',
                  value: '',
                });
                this.setState({
                  steps: stepsCopy,
                });
              }}
              onChange={(e, index) => {
                var stepsCopy = [...steps];
                stepsCopy[2].skills[index].value = e.target.value;
                this.setState({
                  steps: stepsCopy,
                });
              }}
            />
          </div>

          <div onClick={this.nextStep}>
            <Button text="Continue" big />
          </div>
          <div className={styles.skipButtonContainer} onClick={this.skipStep}>
            <Button text="I'll do this later" secondary big />
          </div>
        </>
      );
    }
    if (currentStep === 4) {
      return (
        <>
          <div className={styles.formInputContainer}>
            <div className={styles.stepHeader}>
              <h2 className={styles.stepTitle}>
                How much would you like to charge for it?
              </h2>
              <p className="textBase">
                Set up a one-time payment fee so learners can compensate you. If
                you want to pre-sell your course, setting a discounted pre-sell
                price can help to generate early interest and sales.
              </p>
              <p className="textBase">
                You can always come back and edit this later.
              </p>
            </div>
            <FormPricing
              label="Course pricing"
              error={error}
              tip={
                <Tip
                  title="Let’s walk you through the pricing process"
                  audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_pricing.mp3?alt=media&token=1e0b409d-8051-469f-8687-59cd6d589bc9"
                  text="
                Knowledge is priceless. You deserve to benefit from your thought work and teaching.
                <br><br>
                That’s why it’s important to practice value-based pricing for your course.
                <br><br>
                Try reflecting on these questions:
                <br><br>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Why did you learn this skill in the first place?</b></li>
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>How much is the skill worth to your younger self?</b></li>
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>How much time did it take you to learn this skill?</b></li>
                </ul>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>How much did you pay to learn this skill?</b></li>
                </ul>
                <ul style='margin: 0; padding-left: 18px'>
                  <li><b>How many mistakes did you have to make to master it?</b></li>
                </ul>
                <br>
                Your true fans aren't as price sensitive as you think. The key is to focus on what value you are adding, and the pain you’re saving learners by sharing your experience.
                <br><br>
                Also, think about your own financial goals and work backwards. Here’s an example on how you can achieve the same financial goal with different pricing:
                <br><br>
                $9.99 course x 1000 sales = $9,990
                <br>
                $49.99 course x 200 sales = $9,998
                <br>
                $99.99 course x 100 sales = $9,999
                <br>
                $199 course x 50 sales = $9,950
                <br>
                $599 course x 17 sales = $10,183
                <br><br>
                To create a premium course, think about the additional value you'd want to offer your learners:
                <br><br>
                <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                  <li><b>Creating a small group - It’s easier to manage and more interaction for the learners. Community building is also valuable.</b></li>
                </ul>
                <ul style='margin: 0; padding-left: 18px'>
                  <li><b>Have a monthly Q&amp;A session - You’ll be able to update your course based on the feedback or comments.</b></li>
                </ul>
                <br>
                <b>Here’s a pro tip:</b> We encourage you to pre-sell your course before recording to test the market. As it goes along, you can continue to add new episodes or modify your course as you please!
                "
                  onReadAudioTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'AUDIO',
                    })
                  }
                  onReadWrittenTip={() =>
                    trackEvent('READ_TIP', {
                      SCREEN: 'CREATE_COURSE',
                      TYPE: 'TEXT',
                    })
                  }
                />
              }
              currencySelected={steps[3].price.currency}
              priceSelected={steps[3].price.amount}
              preSellPriceSelected={steps[3].price.preSellAmount}
              onCurrencySelect={(currency) => {
                var stepsCopy = [...steps];
                stepsCopy[3].price.currency = currency;
                this.setState({
                  steps: stepsCopy,
                });
              }}
              onPriceSelect={(amount) => {
                var stepsCopy = [...steps];
                stepsCopy[3].price.amount = amount;
                this.setState({
                  steps: stepsCopy,
                });
              }}
              onPreSellPriceSelect={(amount) => {
                var stepsCopy = [...steps];
                stepsCopy[3].price.preSellAmount = amount;
                this.setState({
                  steps: stepsCopy,
                });
              }}
            />
          </div>

          <div onClick={this.nextStep}>
            <Button text="Continue" big />
          </div>
        </>
      );
    }
    if (currentStep === 5) {
      return (
        <>
          <div className={styles.formInputContainer}>
            <div className={styles.stepHeader}>
              <h2 className={styles.stepTitle}>
                When would you like to release it?
              </h2>
              <p className="textBase">
                Give learners an idea of when to expect your course by selecting
                a release date window. When your course is ready to go live,
                simply change your course status to 'launch' and tell the world!
              </p>
              <p className="textBase">
                You can always come back and edit this later.
              </p>
            </div>
            <ReleaseDate
              value={steps[4].releaseDate}
              error={error}
              onMonthChange={(month) => {
                var stepsCopy = [...steps];
                stepsCopy[4].releaseDate = month;
                this.setState({
                  steps: stepsCopy,
                });
              }}
            />
          </div>
          <div onClick={this.nextStep}>
            <Button text="Continue" big />
          </div>
        </>
      );
    }
    if (currentStep === 6) {
      var priceFormatted = steps[3].price.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: steps[3].price.currency,
      });
      var currency = steps[3].price.currency.charAt(0);

      var fullPrice = `Full price ${currency}${priceFormatted}`;
      var preSellPrice = '';

      if (steps[3].price.preSellAmount !== 'Select a price') {
        var preSellPriceFormatted = steps[3].price.preSellAmount.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: steps[3].price.currency,
          },
        );
        preSellPrice = `Pre-sell ${currency}${preSellPriceFormatted}`;
      }

      return (
        <>
          <div className={styles.formInputContainer}>
            <div className={styles.stepHeader}>
              <h2 className={styles.stepTitle}>Review your selections</h2>
              <p className="textBase">
                You’re just one step away from creating your audio course.
              </p>
            </div>
            <div className={styles.editStepElementContainer}>
              <EditStep
                label="Course title"
                value={steps[0].title}
                onEdit={() => {
                  this.setState({ currentStep: 1 });
                }}
              />
            </div>
            <div className={styles.editStepElementContainer}>
              <EditStep
                label="Course overview"
                value={steps[1].description || '―'}
                onEdit={() => {
                  this.setState({ currentStep: 2 });
                }}
              />
            </div>
            <div className={styles.editStepElementContainer}>
              <EditSkills
                label="Skills learners will gain"
                skills={steps[2].skills.filter((skill) => skill.value !== '')}
                onEdit={() => {
                  this.setState({ currentStep: 3 });
                }}
              />
            </div>
            <div className={styles.editStepElementContainer}>
              <EditStep
                label="Course pricing"
                value={fullPrice}
                secondaryValue={preSellPrice}
                onEdit={() => {
                  this.setState({ currentStep: 4 });
                }}
              />
            </div>
            <div className={styles.editStepElementContainer}>
              <EditStep
                label="Release date window"
                value={`${steps[4].releaseDate}`}
                onEdit={() => {
                  this.setState({ currentStep: 5 });
                }}
              />
            </div>
          </div>

          <div onClick={!isCoursePublishing ? this.publishCourse : null}>
            <Button text="Create my course" big disabled={isCoursePublishing} />
          </div>
        </>
      );
    }
  };

  nextStep = () => {
    const { numberOfSteps, currentStep, steps } = this.state;

    if (currentStep === 1) {
      var courseTitle = steps[0].title;
      if (courseTitle === '') {
        this.setState({
          error: 'Please enter a title',
        });
        return;
      }
      if (courseTitle.length > 150) {
        this.setState({
          error: 'The title cannot be longer than 150 characters',
        });
        return;
      }
    }

    if (currentStep === 2) {
      var description = steps[1].description;
      if (description === '') {
        this.setState({
          error: 'Please enter a description',
        });
        return;
      }
    }

    if (currentStep === 4) {
      const { currency, amount } = steps[3].price;
      if (currency === 'Select a currency') {
        this.setState({
          error: 'Please select a currency',
        });
        return;
      }

      if (amount === 'Select a price') {
        this.setState({
          error: 'Please select a price',
        });
        return;
      }
    }

    if (currentStep === 5) {
      const { month } = steps[4].releaseDate;

      if (month === 'Select month') {
        this.setState({
          error: 'Please select a month',
        });
        return;
      }
    }

    if (currentStep < numberOfSteps) {
      this.setState({
        error: null,
        testimonialError: null,
        currentStep: currentStep + 1,
      });
    } else if (currentStep === numberOfSteps) {
      this.setState({
        error: null,
        testimonialError: null,
        coursePublished: true,
      });
    }
  };

  previousStep = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        error: null,
        testimonialError: null,
        currentStep: currentStep - 1,
      });
    }
  };

  skipStep = () => {
    const { currentStep, numberOfSteps } = this.state;
    if (currentStep < numberOfSteps) {
      const { steps } = this.state;
      var stepsCopy = [...steps];

      if (currentStep === 2) {
        stepsCopy[1].description = '';
      }

      this.setState({
        steps: stepsCopy,
        error: null,
        testimonialError: null,
        currentStep: currentStep + 1,
      });
    } else if (currentStep === numberOfSteps) {
      this.setState({
        error: null,
        testimonialError: null,
        coursePublished: true,
      });
    }
  };

  publishCourse = () => {
    const { firebaseUser } = this.props;
    const { steps } = this.state;

    var preSellAmount = null;
    if (steps[3].price.preSellAmount !== 'Select a price') {
      preSellAmount = steps[3].price.preSellAmount * 100;
    }

    var skillsObject = {};
    steps[2].skills
      .filter((skill) => skill.value !== '')
      .forEach((skill, index) => {
        skillsObject[skill.id] = skill;
        skillsObject[skill.id].index = index;
      });

    const amountStr = steps[3].price.amount;
    const amountValues = amountStr.split(' ');

    let amount = ~~(Number(amountValues[0]) * 100);

    if (amountValues.length > 1 && amountValues[1].includes("Free")) {
      amount = 0;
    }

    var course = {
      title: steps[0].title,
      description: steps[1].description,
      skills: skillsObject,
      price: {
        amount,
        preSellAmount: preSellAmount,
        currency: steps[3].price.currency,
      },
      releaseDateWindow: steps[4].releaseDate,
    };

    this.setState({
      isCoursePublishing: true,
    });

    createCourse(firebaseUser.id, course)
      .then((res) => {
        this.setState({
          isCoursePublishing: false,
          coursePublished: true,
          createdCourseID: res.courseID,
        });
      })
      .catch((error) => {
        console.log('ERROR', error);
        var databaseError = '501';
        if (error.code) {
          databaseError = error.code;
        }
        this.setState({ databaseError: databaseError });
      });
  };

  render() {
    const {
      numberOfSteps,
      currentStep,
      isCoursePublishing,
      coursePublished,
      createdCourseID,
      databaseError,
    } = this.state;

    return (
      <>
        <Header user />
        {!coursePublished && (
          <>
            <div className={styles.progressBar}>
              <div
                className={styles.progressCompleted}
                style={{ width: `${(100 * currentStep) / numberOfSteps}%` }}
              />
            </div>
          </>
        )}

        {isCoursePublishing && (
          <Toast
            text={
              databaseError
                ? `Error ${databaseError}. Try again, please.`
                : 'Creating your course, please wait.'
            }
            error={databaseError}
          />
        )}

        {!coursePublished && (
          <div
            className={styles.container}
            style={{ marginTop: isCoursePublishing ? '-88px' : null }}
          >
            <div
              className={`${styles.back} ${
                isCoursePublishing || currentStep === 1
                  ? styles.backDisabled
                  : null
              }`}
            >
              <div className={styles.backButton} onClick={this.previousStep}>
                <img src={BackArrow} alt="Back" />
                <span>Back</span>
              </div>
            </div>
            {this.renderComponentForCurrentStep()}
          </div>
        )}

        {coursePublished && (
          <div className={styles.container}>
            <img
              src={Dancing}
              alt="Nice work! Your audio course is now created."
            />
            <h2 className={styles.publishTitle}>
              Nice work! Your audio course is now created.
            </h2>
            <p className={styles.publishDescription}>
              Once you have more time, you can start adding content to your
              course.
              <br></br>
              And don’t worry, you can change every detail you added so far.
            </p>

            <div className={styles.publishButtonContainer}>
              <Link to={`/course/${createdCourseID}`}>
                <Button text="Continue editing this course" big />
              </Link>
            </div>

            <Link to="/">
              <Button text="Go to my dashboard" big secondary />
            </Link>
          </div>
        )}
      </>
    );
  }
}

const EditSkills = (props) => {
  const { label, skills, onEdit } = props;

  if (skills && skills.length > 0) {
    return (
      <div className={styles.editSkillsContainer}>
        <div style={{ width: '100%' }}>
          <p className={styles.editSkillsLabel}>{label}</p>
          <ul className={styles.editSkillsList}>
            {skills.map((skill) => {
              return (
                <li key={skill.id}>
                  <Check className={styles.editSkillsElementIcon} />
                  <span>{skill.value}</span>
                </li>
              );
            })}
          </ul>
        </div>

        <div
          className={styles.editSkillsButton}
          onClick={onEdit ? onEdit : null}
        >
          Edit
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.editEmptySkillsContainer}>
        <div style={{ width: '100%' }}>
          <p className={styles.editEmptySkillsLabel}>{label}</p>
          <p className="zeroMargin">
            <span>―</span>
          </p>
        </div>

        <div
          className={styles.editEmptySkillsButton}
          onClick={onEdit ? onEdit : null}
        >
          Edit
        </div>
      </div>
    );
  }
};
