import React from 'react';

import styles from './FormURLCompletion.module.scss';

import InputCompletion from '../../InputCompletion/InputCompletion';

const FormURLCompletion = (props) => {
  const {
    label,
    preFilledValue,
    placeholder,
    description,
    value,
    onChange,
    error,
  } = props;

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.selectContainer}>
        <InputCompletion
          preFilledValue={preFilledValue}
          placeholder={placeholder}
          value={value}
          onChange={(e) => (onChange ? onChange(e) : null)}
          error={error}
        />
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default FormURLCompletion;
