import React, { useState } from 'react';
import styles from './ReleaseFrequency.module.scss';

import Select from '../Select/Select';

import { ReactComponent as Warning } from '../../assets/img/warning.svg';

const ReleaseFrequency = (props) => {
  const { value, onFrequencyChange, onTimeframeChange, error } = props;

  const [frequency, setFrequency] = useState(value.frequency);
  const [timeframe, setTimeframe] = useState(value.timeframe);

  var frequencyOptions = [
    { value: 'Select frequency', selected: false },
    { value: 'Once', selected: false },
    { value: 'Twice', selected: false },
    { value: 'Three times', selected: false },
  ];
  // Remove duplicate values in case 'value' is equal to one of the frequencies pushed in the for loop
  var filteredFrequencyOptions = [
    ...new Map(frequencyOptions.map((item) => [item['value'], item])).values(),
  ];
  filteredFrequencyOptions.forEach((option) => {
    option.selected = false;
    if (option.value === frequency) {
      option.selected = true;
    }
  });

  var timeframeOptions = [
    { value: 'Select timeframe', selected: false },
    { value: 'Day', selected: false },
    { value: 'Week', selected: false },
    { value: 'Month', selected: false },
  ];
  // Remove duplicate values in case 'value' is equal to one of the timeframes pushed in the for loop
  var filteredTimeframeOptions = [
    ...new Map(timeframeOptions.map((item) => [item['value'], item])).values(),
  ];
  filteredTimeframeOptions.forEach((option) => {
    option.selected = false;
    if (option.value === timeframe) {
      option.selected = true;
    }
  });

  return (
    <div className={styles.container}>
      <p className={styles.label}>Episode release frequency (optional)</p>
      <div className={styles.optionsContainer}>
        <div className={styles.selectsContainer}>
          <div className={styles.selectOptionContainer}>
            <Select
              options={filteredFrequencyOptions}
              onChange={(e) => {
                setFrequency(e.target.value);
                onFrequencyChange(e.target.value);
              }}
            />
          </div>
          <div className={styles.selectOptionContainer}>
            <Select
              options={filteredTimeframeOptions}
              onChange={(e) => {
                setTimeframe(e.target.value);
                onTimeframeChange(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.releaseDateContainer}>
          <p className={styles.releaseDateLabel}>Episode release frequency</p>
          <p className={styles.releaseDateValue}>
            {frequency === 'Select frequency' ||
            timeframe === 'Select timeframe'
              ? '―'
              : `${frequency} a ${timeframe.toLowerCase()}`}
          </p>
        </div>
      </div>
      {error && (
        <div className="errorContainer">
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};

export default ReleaseFrequency;
