import React from 'react';
import styles from './Prompt.module.scss';

import { ReactComponent as CreditCard } from '../../assets/img/credit_card.svg';
import { ReactComponent as Close } from '../../assets/img/close.svg';

const Prompt = (props) => {
  const { onConnect, onClose } = props;
  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${styles.contentLeft}`}>
        <div className={styles.iconContainer}>
          <CreditCard className={styles.icon} />
        </div>
        <p className={styles.text}>
          To start getting paid for your courses, you’ll need to enable a
          payment provider.
        </p>
      </div>
      <div className={styles.content}>
        <span className={styles.button} onClick={onConnect ? onConnect : null}>
          Go to payment settings
        </span>
        <Close
          className={styles.closeIcon}
          onClick={onClose ? onClose : null}
        />
      </div>
    </div>
  );
};

export default Prompt;
