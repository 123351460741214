import React from 'react';

import styles from './FormCheckbox.module.scss';

import Checkbox from '../../Checkbox/Checkbox';

const FormCheckbox = (props) => {
  const { label, description, options, onCheckboxClick } = props;

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.checkboxesContainer}>
        {options.map((option, index) => {
          return (
            <div
              key={option.label}
              className={styles.checkboxElement}
              style={{ marginRight: index === options.length - 1 ? '0' : null }}
            >
              <Checkbox
                label={option.label}
                selected={option.selected}
                onClick={() =>
                  onCheckboxClick ? onCheckboxClick(option) : null
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormCheckbox;
