import React from 'react';
import styles from './EditStep.module.scss';

const EditStep = (props) => {
  const { label, value, secondaryValue, onEdit } = props;
  return (
    <div className={styles.container}>
      <div className={styles.valuesContainer}>
        <p className={styles.label}>{label}</p>
        {value.split('\n').map((item, index) => {
          return (
            <p className="zeroMargin" key={index}>
              {item}
              <br />
            </p>
          );
        })}
        {secondaryValue && (
          <>
            <span className={styles.valueSeparator}>|</span>
            <span>{secondaryValue}</span>
          </>
        )}
      </div>
      <div className={styles.editButton} onClick={onEdit ? onEdit : null}>
        Edit
      </div>
    </div>
  );
};

export default EditStep;
