import React from 'react';
import { Redirect, Link } from 'react-router-dom';

import styles from './EditCourse.module.scss';

import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import Toast from '../../components/Toast/Toast';

import Select from '../../components/Select/Select';
import Tip from '../../components/Tip/Tip';
import FormInputText from '../../components/Form/FormInputText/FormInputText';
import FormThingsToLearn from '../../components/Form/FormThingsToLearn/FormThingsToLearn';
import FormUploadImage from '../../components/Form/FormUploadImage/FormUploadImage';
import FormCategory from '../../components/Form/FormCategory/FormCategory';
// import FormURLCompletion from '../../components/Form/FormURLCompletion/FormURLCompletion';
import FormPricing from '../../components/Form/FormPricing/FormPricing';
import FormTestimonials from '../../components/Form/FormTestimonials/FormTestimonials';
import FormFAQs from '../../components/Form/FormFAQs/FormFAQs';
import FormUpload from '../../components/Form/FormUpload/FormUpload';
import ReleaseDate from '../../components/ReleaseDate/ReleaseDate';
import ReleaseFrequency from '../../components/ReleaseFrequency/ReleaseFrequency';
import FormCoursePurchaseEmail from '../../components/Form/FormCoursePurchaseEmail/FormCoursePurchaseEmail';
import FormWelcomeEmail from '../../components/Form/FormWelcomeEmail/FormWelcomeEmail';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import {
  fetchCreator,
  fetchCourse,
  fetchTags,
  fetchProcess,
  removeProcess,
  updateCourse,
  updateCoursePreview,
} from '../../services/FirebaseService/Database';

import { removeContent } from '../../services/FirebaseService/Storage';

import { trackEvent } from '../../services/AnalyticsService';

import axios from 'axios';
import Profile from '../Settings/forms/Profile';
import FormURLCompletion from '../../components/Form/FormURLCompletion/FormURLCompletion';

export default class EditCourse extends React.Component {
  constructor(props) {
    super(props);

    const {
      firebaseUser: { email },
    } = props;

    this.state = {
      sections: [
        { value: 'Details', selected: true },
        { value: 'Media', selected: false },
        { value: 'Payments', selected: false },
        { value: 'Testimonials', selected: false },
        { value: "FAQ's", selected: false },
        { value: 'Emails', selected: false },
        email === 'course@avid.fm'
          ? {
              value: 'Creator Info',
              selected: false,
            }
          : {},
      ],
      showTips: false,
      canAccessPage: false,
      isLoading: true,
      isCourseUpdating: false,
      courseDidFinishUpdate: false,
      error: {
        text: '',
        category: '',
      },
      databaseError: null,
      testimonialError: null,
      course: {},
      categories: [],
      coverArt: null,
      heroImage: null,
      testimonials: [
        {
          id: '_' + Math.random().toString(16).slice(2),
          name: '',
          testimonial: '',
          addedAt: Math.floor(+Date.now() / 1000),
        },
      ],
      audioFile: null,
      trailerStatus: null,
      hasError: null,
    };

    this.setupCourse = this.setupCourse.bind(this);
    this.handleCategorySelected = this.handleCategorySelected.bind(this);
    this.addSkill = this.addSkill.bind(this);
    this.addTestimonial = this.addTestimonial.bind(this);
    this.addFile = this.addFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    const { course } = this.props.location;
    const { courseID } = this.props.match.params;

    this.checkIfUserCanAccessPage().then((canAccess) => {
      if (canAccess) {
        if (course) {
          this.setupCourse(course);
        } else {
          fetchCourse(courseID).then((res) => {
            if (res) {
              this.setupCourse(res);
            }
          });
        }
      } else {
        this.setState({ canAccessPage: false, isLoading: false });
      }
    });
  }

  checkIfUserCanAccessPage = () => {
    const { courseID } = this.props.match.params;
    const { firebaseUser } = this.props;

    return new Promise((resolve, reject) => {
      fetchCreator(firebaseUser.id)
        .then((creator) => {
          if (creator && creator.courses) {
            var courses = creator.courses;
            var coursesIDs = Object.keys(courses);
            if (coursesIDs.includes(courseID)) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          console.log('ERROR', error);
          reject(error);
        });
    });
  };

  setupCourse = (course) => {
    fetchTags().then((tagsFetched) => {
      var tagsFetchedKeys = Object.keys(tagsFetched);
      var categoriesCopy = tagsFetchedKeys.map((key) => {
        var category = tagsFetched[key];
        return {
          id: category.id,
          value: category.name,
          selected: false,
        };
      });
      categoriesCopy.splice(0, 0, {
        id: 0,
        value: 'Select a category',
        selected: true,
      });
      if (course.tags) {
        var tagsKeys = Object.keys(course.tags);
        var tags = tagsKeys.map((key) => course.tags[key]);
        if (tags) {
          var tag = tags[0];
          var selectedCategoryIndex = categoriesCopy.findIndex(
            (category) =>
              category.value.toUpperCase() === tag.name.toUpperCase(),
          );
          categoriesCopy.forEach((category) => (category.selected = false));
          categoriesCopy[selectedCategoryIndex].selected = true;
        }
      }

      if (course.release_frequency) {
        course.releaseFrequency = {
          frequency:
            course.release_frequency.frequency.charAt(0).toUpperCase() +
            course.release_frequency.frequency.slice(1),
          timeframe:
            course.release_frequency.timeframe.charAt(0).toUpperCase() +
            course.release_frequency.timeframe.slice(1),
        };
      } else {
        course.releaseFrequency = {
          frequency: 'Select frequency',
          timeframe: 'Select timeframe',
        };
      }

      if (course.price !== null && course.price !== undefined) {
        if (course.price === '0.00') {
          course.preSellPrice = '0.00 (Free)';
        } else {
          var formattedPrice = course.price / 100;
          course.preSellPrice = formattedPrice;
        }
      }

      if (course.full_price) {
        var formattedFullPrice = course.full_price / 100;
        course.price = formattedFullPrice;
      }

      if (course.currency === 'GBP') {
        course.currency = '£ (GBP)';
      }
      if (course.currency === 'USD') {
        course.currency = '$ (USD)';
      }
      if (course.currency === 'EUR') {
        course.currency = '€ (EUR)';
      }

      var skills = [];
      if (!course.things_you_will_learn) {
        skills = [
          {
            index: 0,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            placeholder: 'E.g How to close deals',
            value: '',
          },
          {
            index: 1,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            placeholder: 'E.g Sales skills and techniques',
            value: '',
          },
          {
            index: 2,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            placeholder: 'E.g Go-to-market strategy',
            value: '',
          },
          {
            index: 3,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            placeholder: 'E.g Confidence to get first 20 customers',
            value: '',
          },
          {
            index: 4,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            placeholder: 'E.g Hire a team',
            value: '',
          },
          {
            index: 5,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            placeholder: 'E.g How to be a Founder and a Salesperson',
            value: '',
          },
        ];
      } else {
        var skillsKeys = Object.keys(course.things_you_will_learn);
        skillsKeys.forEach((key) => {
          var skill = course.things_you_will_learn[key];
          skills.push({
            index: skill.index,
            id: skill.id,
            value: skill.value,
            placeholder: 'Enter a skill',
          });
        });
        skills.sort((a, b) => (a.index > b.index ? 1 : -1));
      }

      var testimonials = [];
      if (!course.testimonials) {
        var ID =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        testimonials.push({
          id: ID,
          name: '',
          testimonial: '',
          addedAt: Math.floor(+Date.now() / 1000),
        });
      } else {
        var testimonialsKeys = Object.keys(course.testimonials);
        testimonials = testimonialsKeys
          .map((key) => course.testimonials[key])
          .sort((item1, item2) => (item1.addedAt > item2.addedAt ? 1 : -1));
      }

      var faqs = [];
      if (!course.faqs) {
        // eslint-disable-next-line
        var ID =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        faqs.push({
          id: ID,
          question: '',
          answer: '',
          added_at: Math.floor(+Date.now() / 1000),
        });
      } else {
        var faqsKeys = Object.keys(course.faqs);
        faqs = faqsKeys
          .map((key) => course.faqs[key])
          .sort((item1, item2) => (item1.added_at > item2.added_at ? 1 : -1));
      }

      var coursePreview = null;
      if (course.preview) {
        coursePreview = {
          name: course.preview.file.name,
          size: course.preview.file.size,
          type: course.preview.file.type,
        };
      }

      if (!course.learner_welcome_email) {
        course.learner_welcome_email = {
          enabled: false,
          message: null,
        };
      }

      if (!course.enable_course_purchase_notification) {
        course.enable_course_purchase_notification = false;
      }

      fetchProcess(`${course.id}/trailer`)
        .then((process) => {
          var trailerStatus = null;
          if (process) {
            if (process.error) {
              trailerStatus = 'failed';
            } else if (!process.completed && process.processing) {
              trailerStatus = 'uploading';
            } else if (course.preview && course.preview.url !== '') {
              trailerStatus = 'success';
            }
          }
          this.setState({
            canAccessPage: true,
            course: course,
            isLoading: false,
            categories: categoriesCopy,
            testimonials: testimonials,
            faqs: faqs,
            skills: skills,
            audioFile: coursePreview,
            trailerStatus: trailerStatus,
          });
        })
        .catch((error) => {
          console.log('ERROR', error);
        });
    });
  };

  handleNavigationSelection = (section, callback) => {
    const { sections } = this.state;
    var sectionsCopy = [...sections];
    sectionsCopy.forEach(
      (_, index) =>
        (sectionsCopy[index].selected =
          section === sectionsCopy[index].value ? true : false),
    );
    this.setState({ sections: sectionsCopy }, () => {
      if (callback) {
        callback();
      }
    });
  };

  handleCategorySelected = (e) => {
    const { categories } = this.state;
    var categoriesCopy = categories;
    var categoryAlreadySelectedIndex = categoriesCopy.findIndex(
      (item) => item.selected === true,
    );
    categoriesCopy[categoryAlreadySelectedIndex].selected = false;
    var categorySelectedIndex = categoriesCopy.findIndex(
      (item) => item.value === e.target.value,
    );
    categoriesCopy[categorySelectedIndex].selected = true;
    this.setState({
      categories: categoriesCopy,
    });
  };

  addSkill = () => {
    const { skills } = this.state;
    var skillsCopy = skills;
    var ID =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    skillsCopy.push({
      index: skillsCopy.length,
      id: ID,
      placeholder: 'Enter a skill',
      value: '',
    });
    this.setState({
      skills: skillsCopy,
    });
  };

  handleSkillChange = (e, index) => {
    const { skills } = this.state;
    var skillsCopy = skills;
    skillsCopy[index].value = e.target.value;
    this.setState({
      skills: skillsCopy,
    });
  };

  addTestimonial = () => {
    const { testimonials } = this.state;
    var testimonialsCopy = testimonials;
    var ID =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    testimonialsCopy.push({
      id: ID,
      name: '',
      testimonial: '',
      addedAt: Math.floor(+Date.now() / 1000),
    });
    this.setState({
      testimonials: testimonialsCopy,
    });
  };

  handleTestimonialNameChange = (e, testimonial) => {
    const { testimonials } = this.state;
    var testimonialsCopy = testimonials;
    var index = testimonialsCopy.findIndex(
      (item) => item.id === testimonial.id,
    );
    testimonialsCopy[index].name = e.target.value;
    this.setState({
      testimonials: testimonialsCopy,
    });
  };

  handleTestimonialChange = (e, testimonial) => {
    const { testimonials } = this.state;
    var testimonialsCopy = testimonials;
    var index = testimonialsCopy.findIndex(
      (item) => item.id === testimonial.id,
    );
    testimonialsCopy[index].testimonial = e.target.value;
    this.setState({
      testimonials: testimonialsCopy,
    });
  };

  removeTestimonial = (testimonial) => {
    const { testimonials } = this.state;
    var testimonialsCopy = testimonials;
    var index = testimonialsCopy.findIndex(
      (item) => item.id === testimonial.id,
    );
    testimonialsCopy.splice(index, 1);
    this.setState({
      testimonials: testimonialsCopy,
    });
  };

  addFaq = () => {
    const { faqs } = this.state;
    var faqsCopy = faqs;
    var ID =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    faqsCopy.push({
      id: ID,
      question: '',
      answer: '',
      added_at: Math.floor(+Date.now() / 1000),
    });
    this.setState({
      faqs: faqsCopy,
    });
  };

  handleFAQQuestionChange = (e, faq) => {
    const { faqs } = this.state;
    var faqsCopy = faqs;
    var index = faqsCopy.findIndex((item) => item.id === faq.id);
    faqsCopy[index].question = e.target.value;
    this.setState({
      faqs: faqsCopy,
    });
  };

  handleFAQAnswerChange = (e, faq) => {
    const { faqs } = this.state;
    var faqsCopy = faqs;
    var index = faqsCopy.findIndex((item) => item.id === faq.id);
    faqsCopy[index].answer = e.target.value;
    this.setState({
      faqs: faqsCopy,
    });
  };

  removeFAQ = (faq) => {
    const { faqs } = this.state;
    var faqsCopy = faqs;
    var index = faqsCopy.findIndex((item) => item.id === faq.id);
    faqsCopy.splice(index, 1);
    this.setState({
      faqs: faqsCopy,
    });
  };

  addFile = (file) => {
    this.setState({
      audioFile: file,
      trailerStatus: 'success',
    });
  };

  removeFile = () => {
    var courseCopy = this.state.course;
    courseCopy.preview = null;
    this.setState({
      audioFile: null,
      course: courseCopy,
    });
  };

  scrollToElement = (id) => {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  };

  save = () => {
    const {
      course,
      coverArt,
      heroImage,
      categories,
      skills,
      testimonials,
      faqs,
      audioFile,
    } = this.state;

    if (course.title === '') {
      this.setState({
        error: {
          text: 'Please enter a title',
          category: 'EPISODE_TITLE',
        },
      });

      this.handleNavigationSelection('Details', () =>
        this.scrollToElement('course_title'),
      );

      return;
    }
    if (course.title.length > 150) {
      this.setState({
        error: {
          text: 'The title cannot be longer than 150 characters',
          category: 'EPISODE_TITLE',
        },
      });

      this.handleNavigationSelection('Details', () =>
        this.scrollToElement('course_title'),
      );

      return;
    }

    if (course?.subtitle?.length > 150) {
      this.setState({
        error: {
          text: 'The subtitle cannot be longer than 150 characters',
          category: 'COURSE_SUBTITLE',
        },
      });

      this.handleNavigationSelection('Details', () =>
        this.scrollToElement('course_subtitle'),
      );

      return;
    }

    if (course.releaseFrequency.frequency !== 'Select frequency') {
      if (course.releaseFrequency.timeframe === 'Select timeframe') {
        this.setState({
          error: {
            text: 'Please select a timeframe',
            category: 'COURSE_RELEASE_FREQUENCY',
          },
        });

        this.handleNavigationSelection('Details', () =>
          this.scrollToElement('course_release_frequency'),
        );

        return;
      }
    }
    if (course.releaseFrequency.timeframe !== 'Select timeframe') {
      if (course.releaseFrequency.frequency === 'Select frequency') {
        this.setState({
          error: {
            text: 'Please select a frequency',
            category: 'COURSE_RELEASE_FREQUENCY',
          },
        });

        this.handleNavigationSelection('Details', () =>
          this.scrollToElement('course_release_frequency'),
        );
        return;
      }
    }

    if (course.description === '') {
      this.setState({
        error: {
          text: 'Please enter a description',
          category: 'EPISODE_DESCRIPTION',
        },
      });

      this.handleNavigationSelection('Details', () =>
        this.scrollToElement('course_description'),
      );

      return;
    }

    if (course.slug === '') {
      this.setState({
        error: {
          text: 'Please enter your course URL',
          category: 'EPISODE_URL',
        },
      });
      return;
    }

    if (course.price === '') {
      this.setState({
        error: {
          text: 'Please enter an amount',
          category: 'EPISODE_PRICE',
        },
      });

      this.handleNavigationSelection('Payments', () =>
        this.scrollToElement('course_pricing'),
      );

      return;
    }

    if (course.refund_policy === '') {
      this.setState({
        error: {
          text: 'Please enter a refund policy',
          category: 'EPISODE_REFUND_POLICY',
        },
      });

      this.handleNavigationSelection('Payments', () =>
        this.scrollToElement('course_refund_policy'),
      );

      return;
    }

    if (course.learner_welcome_email.enabled) {
      if (!course.learner_welcome_email.message) {
        this.setState({
          error: {
            text: 'Please enter a welcome message',
            category: 'COURSE_WELCOME_EMAIL',
          },
        });

        this.handleNavigationSelection('Emails', () =>
          this.scrollToElement('course_welcome_email'),
        );

        return;
      }
    }

    const fileLimit = 500000000; // 500MB
    if (audioFile && audioFile.size > fileLimit) {
      this.setState({
        error: {
          text: 'The size of the audio file cannot exceed 500MB',
          category: 'EPISODE_UPLOAD',
        },
      });

      this.handleNavigationSelection('Media', () =>
        this.scrollToElement('course_preview'),
      );

      return;
    }

    var testimonialError = null;
    testimonials.forEach((testimonial) => {
      if (testimonial.name !== '' || testimonial.testimonial !== '') {
        var errorName = testimonials.find((item) => item.name === '');
        var errorTestimonial = testimonials.find(
          (item) => item.testimonial === '',
        );

        if (errorName) {
          testimonialError = {
            id: errorName.id,
            error: 'Please enter a valid name',
            type: 'NAME',
          };
        } else if (errorTestimonial) {
          testimonialError = {
            id: errorTestimonial.id,
            error: 'Please enter a valid testimonial',
            type: 'TESTIMONIAL',
          };
        }
      }
    });

    if (testimonialError) {
      this.setState({
        testimonialError: testimonialError,
      });

      this.handleNavigationSelection('Testimonials', () =>
        this.scrollToElement('course_testimonials'),
      );

      return;
    }

    var faqsError = null;
    faqs.forEach((faq) => {
      if (faq.question !== '' || faq.answer !== '') {
        var errorQuestion = faqs.find((item) => item.question === '');
        var errorAnswer = faqs.find((item) => item.answer === '');

        if (errorQuestion) {
          faqsError = {
            id: errorQuestion.id,
            error: 'Please enter a valid question',
            type: 'QUESTION',
          };
        } else if (errorAnswer) {
          faqsError = {
            id: errorAnswer.id,
            error: 'Please enter a valid answer',
            type: 'ANSWER',
          };
        }
      }
    });

    if (faqsError) {
      this.setState({
        faqsError: faqsError,
      });

      this.handleNavigationSelection("FAQ's", () =>
        this.scrollToElement('course_faqs'),
      );

      return;
    }

    this.setState({
      error: {
        text: '',
        category: '',
      },
      testimonialError: null,
      faqsError: null,
    });

    var categoryFound = categories.find((item) => item.selected === true);

    var skillsObject = {};
    skills
      .filter((skill) => skill.value !== '')
      .forEach((skill, index) => {
        skillsObject[skill.id] = skill;
        skillsObject[skill.id].index = index;
      });

    var testimonialsObject = {};
    testimonials.forEach((testimonial) => {
      if (testimonial.name !== '' || testimonial.testimonial !== '') {
        testimonialsObject[testimonial.id] = testimonial;
      }
    });

    var faqsObject = {};
    faqs.forEach((faq) => {
      if (faq.question !== '' || faq.answer !== '') {
        faqsObject[faq.id] = faq;
      }
    });

    var courseCurrency = course.currency.split('(')[1].split(')')[0];

    let preSellPrice;
    let price;

    if (course.price === '0.00 (Free)' || course.price === 'Select a price') {
      price = '0';
    } else {
      price = course.price * 100;
    }

    if (course.preSellPrice && course.preSellPrice !== 'Select a price') {
      if (course.preSellPrice === '0.00 (Free)') {
        preSellPrice = '0';
      } else {
        preSellPrice = course.preSellPrice * 100;
      }
    }

    if (course.id === "-MVvbURtw2c1Ts1XoLjg") {
      price = "997";
    }

    var selctedReleaseFrequency = null;
    if (
      course.releaseFrequency.frequency !== 'Select frequency' &&
      course.releaseFrequency.timeframe !== 'Select timeframe'
    ) {
      selctedReleaseFrequency = {
        frequency: course.releaseFrequency.frequency.toLowerCase(),
        timeframe: course.releaseFrequency.timeframe.toLowerCase(),
      };
    }

    var courseObject = {
      id: course.id,
      title: course.title,
      subtitle: course.subtitle || null,
      description: course.description,
      releaseFrequency: selctedReleaseFrequency,
      launchDate: course.launch_date,
      image: coverArt ? coverArt : course.image,
      background_image: heroImage ? heroImage : course.background_image || null,
      category:
        categoryFound.value === 'Select a category' ? null : categoryFound,
      slug: course.slug,
      currency: courseCurrency,
      fullPrice: price,
      price: preSellPrice,
      refundPolicy: course.refund_policy,
      thingsYouWillLearn: skillsObject,
      testimonials: testimonialsObject,
      faqs: faqsObject,
      preview: course.preview,
      learnerWelcomeEmail: course.learner_welcome_email,
      enableCoursePurchaseNotification:
        course.enable_course_purchase_notification,
    };

    this.handleCourseUpdate(courseObject);
  };

  handleCourseUpdate = (courseObject) => {
    const { course, coverArt, heroImage, audioFile } = this.state;

    this.setState({ isCourseUpdating: true });

    var updateCoverImage = coverArt !== null;
    var updateBackgroundImage = heroImage !== null;

    updateCourse(courseObject, updateCoverImage, updateBackgroundImage)
      .then(() => {
        if (audioFile === null) {
          removeContent(`courses/${course.id}/trailer/m3u8`)
            .then(() => {
              removeProcess(`${course.id}/trailer`);
            })
            .catch((error) => {
              console.log('ERROR', error);
            });
        }
        if (audioFile && audioFile instanceof File) {
          const formData = new FormData();
          formData.append('audio_file', audioFile);
          formData.append(
            'additional_data',
            JSON.stringify({ course_id: course.id, type: 'trailer' }),
          );
          axios
            .post('https://avid-back-end.herokuapp.com/upload', formData)
            .then(() => {
              updateCoursePreview(course.id, audioFile)
                .then(() => {
                  this.setState({
                    courseDidFinishUpdate: true,
                    databaseError: null,
                  });
                  setTimeout(() => {
                    window.location.href = `/course/${course.id}`;
                  }, 1000);
                })
                .catch((error) => {
                  console.log('ERROR', error);
                });
            })
            .catch((error) => {
              this.setState({
                courseDidFinishUpdate: true,
                databaseError: error,
              });
            });
        } else {
          setTimeout(() => {
            this.setState({ courseDidFinishUpdate: true, databaseError: null });
          }, 1000);

          setTimeout(() => {
            window.location.href = `/course/${course.id}`;
          }, 1000);
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
        if (
          typeof error === 'string' &&
          error.includes('Course URL already exists')
        ) {
          this.setState({ hasError: error, isCourseUpdating: false });

          return setTimeout(() => {
            this.setState({ hasError: null });
          }, 2500);
        }

        var databaseError = '501';
        if (error.code) {
          databaseError = error.code;
        }
        this.setState({
          courseDidFinishUpdate: true,
          databaseError: databaseError,
        });
      });
  };

  render() {
    const {
      hasError,
      sections,
      showTips,
      canAccessPage,
      isLoading,
      isCourseUpdating,
      courseDidFinishUpdate,
      error,
      testimonialError,
      faqsError,
      course,
      categories,
      skills,
      testimonials,
      faqs,
      audioFile,
      coverArt,
      databaseError,
      trailerStatus,
      heroImage,
    } = this.state;

    if (isLoading) {
      return <LoadingScreen />;
    }

    if (!canAccessPage) {
      return <Redirect to="my-courses" />;
    }

    let courseImage = null;
    if (coverArt) {
      courseImage = coverArt;
    } else if (course.image.square.url) {
      courseImage = course.image.square.url;
    }

    let backgroundImage = null;
    if (heroImage) {
      backgroundImage = heroImage;
    } else if (course.background_image?.url) {
      backgroundImage = course.background_image?.url;
    }

    var sectionSelected = sections.find((item) => item.selected).value;

    return (
      <>
        <Header user />
        {hasError && <Toast text={hasError} error={true} />}

        {isCourseUpdating && !courseDidFinishUpdate && (
          <Toast text="Updating your course, please wait." />
        )}

        {courseDidFinishUpdate && (
          <Toast
            text={
              databaseError
                ? `Error ${databaseError}. Try again, please.`
                : 'Course successfully updated!'
            }
            error={databaseError}
            success={!databaseError}
          />
        )}

        <div
          className={styles.container}
          style={{ marginTop: isCourseUpdating ? '-90px' : null }}
        >
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>Edit course</h2>
            <div className={styles.helpContainer}>
              <span className={styles.helpLabel}>Need help?</span>
              <span
                className={styles.tipsButton}
                onClick={() => this.setState({ showTips: !showTips })}
              >
                {showTips ? 'Hide tips' : 'Show tips'}
              </span>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.responsiveNavigationList}>
              <Select
                options={sections}
                onChange={(e) => this.handleNavigationSelection(e.target.value)}
              />
            </div>
            <ul className={styles.navigationList}>
              {sections.map((section) => (
                <li
                  key={section.value}
                  className={
                    sectionSelected === section.value
                      ? styles.navigationItemSelected
                      : null
                  }
                  onClick={() => this.handleNavigationSelection(section.value)}
                >
                  {section.value}
                </li>
              ))}
            </ul>
            <div className={styles.sections}>
              {sectionSelected === 'Details' && (
                <>
                  <div className={styles.formContainer} id="course_title">
                    <FormInputText
                      label="Course title"
                      placeholder="Enter your course title"
                      description="Pick an easy to read, memorable title. 150 characters max."
                      error={
                        error.category === 'EPISODE_TITLE' ? error.text : null
                      }
                      value={course.title}
                      onChange={(e) => {
                        var courseCopy = course;
                        courseCopy.title = e.target.value.substring(0, 150);
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      tip={
                        !showTips ? null : (
                          <Tip
                            reset={this.state.tipPlaying !== 'COURSE_TITLE'}
                            showReadByDefault={true}
                            title="Let’s walk you through naming your course"
                            audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_title.mp3?alt=media&token=dfcc0f78-33f7-495f-a9d8-25b5f8e29b15"
                            text="
                  Everything starts with a great title. Think of it as the ultimate first impression! That’s why it’s crucial to create a title that clearly communicates what you’ll be teaching. 
                  <br><br>
                  Try starting with a draft. Take some time to look at other course titles in your space. Write down a list of keywords, play around with them and see what fits best.
                  <br><br>
                  An impactful title answers the following questions:
                  <br><br>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Target audience - Who should listen to this course?</b></li>
                  </ul>
                  <ul style='margin: 0; padding-left: 18px'>
                    <li><b>Outcome - What skills will you learn from this course?*</b></li>
                  </ul>
                  <br>
                  *Remember, a course is the bridge between a <i>learner</i> and the <i>outcome they’d like to achieve</i>. For that reason, mentioning the outcome in the title is an extremely valuable selling point.
                  <br><br>
                  <b>Let’s deconstruct some good &amp; bad examples:</b>
                  <br><br>
                  <b>Hell no:</b> Become a Freelancer
                  <br>
                  <b>Why?</b> Not specific
                  <br><br>
                  <b>F*ck yes:</b> Freelance on Fiverr &amp; Become a Top Rated Seller
                  <br>
                  <b>Why?</b> It specifies the platform “Fiverr” and highlights the outcome by adding “top-rated seller.”
                  <br><br>
                  <b>***</b>
                  <br><br>
                  <b>Hell no:</b> Become a better storyteller
                  <br>
                  <b>Why?</b> Vague/generic
                  <br><br>
                  <b>F*ck yes:</b> Storytelling for public speaking
                  <br>
                  <b>Why?</b> It highlights a specific use case.
                  <br><br>
                  <b>***</b>
                  <br><br>
                  <b>Pro tip:</b> Willing to be niche is key. It’s okay to turn away some learners in the process. You’re better off attracting a specific group and really speak to their hearts.
                  "
                            onTogglePlay={() =>
                              this.setState({ tipPlaying: 'COURSE_TITLE' })
                            }
                            onReadAudioTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'AUDIO',
                              })
                            }
                            onReadWrittenTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'TEXT',
                              })
                            }
                          />
                        )
                      }
                    />
                  </div>
                  <div className={styles.formContainer} id="course_subtitle">
                    <FormInputText
                      label="Course subtitle (optional)"
                      placeholder="Enter your course subtitle"
                      description="A subtitle should compliment your course title by adding more context. Really good subtitles summarise what a course is about in just a few words. 150 characters max."
                      long={true}
                      error={
                        error.category === 'COURSE_SUBTITLE' ? error.text : null
                      }
                      value={course.subtitle}
                      onChange={(e) => {
                        var courseCopy = course;
                        courseCopy.subtitle = e.target.value.substring(0, 150);
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                    />
                  </div>
                  <div
                    className={styles.formContainer}
                    id="course_release_date"
                  >
                    <ReleaseDate
                      value={course.launch_date}
                      error={
                        error.category === 'COURSE_RELEASE_DATE'
                          ? error.text
                          : null
                      }
                      onMonthChange={(month) => {
                        var courseCopy = course;
                        courseCopy.launch_date = month;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                    />
                  </div>
                  <div
                    className={styles.formContainer}
                    id="course_release_frequency"
                  >
                    <ReleaseFrequency
                      value={course.releaseFrequency}
                      error={
                        error.category === 'COURSE_RELEASE_FREQUENCY'
                          ? error.text
                          : null
                      }
                      onFrequencyChange={(frequency) => {
                        var courseCopy = course;
                        courseCopy.releaseFrequency.frequency = frequency;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      onTimeframeChange={(timeframe) => {
                        var courseCopy = course;
                        courseCopy.releaseFrequency.timeframe = timeframe;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.formContainer} id="course_description">
                    <FormInputText
                      label="Course description"
                      placeholder="Enter your course description"
                      description="The overview should give learners a clear idea about your course, and get them excited about learning with you. It can be as short or as long as you like."
                      long={true}
                      error={
                        error.category === 'EPISODE_DESCRIPTION'
                          ? error.text
                          : null
                      }
                      value={course.description}
                      onChange={(e) => {
                        var courseCopy = course;
                        courseCopy.description = e.target.value;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      tip={
                        !showTips ? null : (
                          <Tip
                            reset={
                              this.state.tipPlaying !== 'COURSE_DESCRIPTION'
                            }
                            showReadByDefault={true}
                            title="Let’s walk you through writing your course overview"
                            audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_overview.mp3?alt=media&token=ed379d2b-7e3b-447e-ae83-ef4a60fd3bfc"
                            text="
                  Now that your title has managed to intrigue learners, we’ll use the course overview to win them over. 
                  <br><br>
                  Learners want results, so try answering these questions to help them decide if your course is a good fit:
                  <br><br>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>What is the outcome?</b></li>
                    If it’s not clear in the title, expand on it here.
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>How can the skills learned be applied in different scenarios?</b></li>
                    Paint the learner a picture of how these skills come in handy.
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Why should people care about this course?</b></li>
                    This step is crucial for learners on the fence. Try to give them your best elevator pitch. Tell them why these skills are important to learn now, not later on.
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Who is the course for?</b></li>
                    You want to be specific here. Again, don’t be afraid of turning away some learners who aren’t right. You can always make adjacent courses in the future.
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Why should they learn from you <i>specifically</i>?</b></li>
                    You want to gain learners trust and showcase your credibility. Share your past experiences. What pain did you have to endure? What were the challenges you faced?
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>How much effort is required?</b></li>
                    Let’s get more practical here. How big of a program is this? How much time will your learners have to commit?
                  </ul>
                  "
                            onTogglePlay={() =>
                              this.setState({
                                tipPlaying: 'COURSE_DESCRIPTION',
                              })
                            }
                            onReadAudioTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'AUDIO',
                              })
                            }
                            onReadWrittenTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'TEXT',
                              })
                            }
                          />
                        )
                      }
                    />
                  </div>
                  <div className={styles.formContainer}>
                    <FormThingsToLearn
                      label="Skills learners will gain"
                      description="Give learners an idea of the types of skills they’ll acquire from your course. Keep these short and sweet, bullet-point style."
                      skills={skills}
                      addSkill={this.addSkill}
                      onChange={(e, index) => this.handleSkillChange(e, index)}
                      tip={
                        !showTips ? null : (
                          <Tip
                            reset={this.state.tipPlaying !== 'COURSE_SKILLS'}
                            showReadByDefault={true}
                            title="Let’s walk you through listing key skills"
                            audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_skill_list.mp3?alt=media&token=1332657c-4679-4522-a8d2-6baca525adb3"
                            text="
                  Great for making it this far! Now it’s time to lay out the skills learners will gain from your course. 
                  <br><br>
                  I know we’ve touched on learning outcomes in the course overview, but there could be more granular skills you’d like to highlight.
                  <br><br>
                  Break the learning outcome into smaller milestones that are specific and measurable. Think about potential milestones in between the major goals. Keep it short and sweet, bullet-point style.
                  <br><br>
                  Lead these skills with verbs and how-tos. Here are some examples:
                  <br><br>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Manage emotions around money</b></li>
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Make smart financial decisions</b></li>
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Develop an opportunity mindset</b></li>
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Gain confidence to get your first 20 customers</b></li>
                  </ul>
                  <ul style='margin: 0; padding-left: 18px'>
                    <li><b>How to hire a team</b></li>
                  </ul>
                  <br>
                  The skills list also helps your learners mark their progress, and help you plan out your course! All you need from here is to break down these milestones further to create a detailed syllabus.
                  "
                            onTogglePlay={() =>
                              this.setState({ tipPlaying: 'COURSE_SKILLS' })
                            }
                            onReadAudioTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'AUDIO',
                              })
                            }
                            onReadWrittenTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'TEXT',
                              })
                            }
                          />
                        )
                      }
                    />
                  </div>
                  <div className={styles.formContainer} id="course_category">
                    <FormCategory
                      label="Course category"
                      description="What category best fits the knowledge you'll share?"
                      error={
                        error.category === 'EPISODE_CATEGORY'
                          ? error.text
                          : null
                      }
                      options={categories}
                      onChange={(e) => {
                        this.handleCategorySelected(e);
                      }}
                    />
                  </div>
                  <div className={styles.formContainer} id="course_bio">
                    <div className={styles.editCourseBio}>
                      <span className={styles.editCourseBioLabel}>
                        Course bio
                      </span>
                      <p className={styles.editCourseBioDescription}>
                        To edit your name, bio, social media accounts or profile
                        picture,{' '}
                        <Link to="/settings">go to your settings.</Link>
                      </p>
                    </div>
                  </div>
                </>
              )}
              {sectionSelected === 'Media' && (
                <>
                  <div className={styles.formContainer} id="course_preview">
                    <FormUpload
                      label="Course trailer (optional)"
                      description="Upload an audio trailer for your course. Recommended format is .mp3, up to 500MB in size."
                      buttonTitle="Upload audio file"
                      error={
                        error.category === 'EPISODE_UPLOAD' ? error.text : null
                      }
                      file={audioFile}
                      addFile={this.addFile}
                      removeFile={this.removeFile}
                      status={trailerStatus}
                    />
                  </div>
                  <div className={styles.formContainer} id="course_image">
                    <FormUploadImage
                      label="Cover art"
                      description="Make your course stand out by uploading custom cover art. Recommended formats are JPG and PNG, 800x800 pixels."
                      buttonTitle={'Upload your cover art'}
                      error={
                        error.category === 'EPISODE_IMAGE' ? error.text : null
                      }
                      image={courseImage}
                      onImageLoaded={(image) => {
                        this.setState({
                          coverArt: image,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.formContainer} id="background_image">
                    <FormUploadImage
                      id="background-image"
                      label="Background header image"
                      description="Add some more personality to your course page by customizing the header background. Recommended formats are JPG and PNG, 1440x780 pixels."
                      buttonTitle={'Upload your header image'}
                      error={
                        error.category === 'COURSE_BACKGROUND_IMAGE'
                          ? error.text
                          : null
                      }
                      image={backgroundImage}
                      onImageLoaded={(image) => {
                        this.setState({
                          heroImage: image,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              {sectionSelected === 'Payments' && (
                <>
                  <div className={styles.formContainer} id="course_pricing">
                    <FormPricing
                      label="Course pricing"
                      error={
                        error.category === 'EPISODE_PRICE' ? error.text : null
                      }
                      currencySelected={course.currency}
                      priceSelected={course.price}
                      preSellPriceSelected={course.preSellPrice}
                      onCurrencySelect={(currency) => {
                        var courseCopy = course;
                        courseCopy.currency = currency;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      onPriceSelect={(amount) => {
                        var courseCopy = course;
                        courseCopy.price = amount;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      onPreSellPriceSelect={(amount) => {
                        var courseCopy = course;
                        courseCopy.preSellPrice = amount;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      tip={
                        !showTips ? null : (
                          <Tip
                            reset={this.state.tipPlaying !== 'COURSE_PRICE'}
                            showReadByDefault={true}
                            title="Let’s walk you through the pricing process"
                            audioLink="https://firebasestorage.googleapis.com/v0/b/avid-8d85c.appspot.com/o/audio_tips%2Fcourse_pricing.mp3?alt=media&token=1e0b409d-8051-469f-8687-59cd6d589bc9"
                            text="
                  Knowledge is priceless. You deserve to benefit from your thought work and teaching.
                  <br><br>
                  That’s why it’s important to practice value-based pricing for your course.
                  <br><br>
                  Try reflecting on these questions:
                  <br><br>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Why did you learn this skill in the first place?</b></li>
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>How much is the skill worth to your younger self?</b></li>
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>How much time did it take you to learn this skill?</b></li>
                  </ul>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>How much did you pay to learn this skill?</b></li>
                  </ul>
                  <ul style='margin: 0; padding-left: 18px'>
                    <li><b>How many mistakes did you have to make to master it?</b></li>
                  </ul>
                  <br>
                  Your true fans aren't as price sensitive as you think. The key is to focus on what value you are adding, and the pain you’re saving learners by sharing your experience.
                  <br><br>
                  Also, think about your own financial goals and work backwards. Here’s an example on how you can achieve the same financial goal with different pricing:
                  <br><br>
                  $9.99 course x 1000 sales = $9,990
                  <br>
                  $49.99 course x 200 sales = $9,998
                  <br>
                  $99.99 course x 100 sales = $9,999
                  <br>
                  $199 course x 50 sales = $9,950
                  <br>
                  $599 course x 17 sales = $10,183
                  <br><br>
                  To create a premium course, think about the additional value you'd want to offer your learners:
                  <br><br>
                  <ul style='margin: 0; margin-bottom: 8px; padding-left: 18px'>
                    <li><b>Creating a small group - It’s easier to manage and more interaction for the learners. Community building is also valuable.</b></li>
                  </ul>
                  <ul style='margin: 0; padding-left: 18px'>
                    <li><b>Have a monthly Q&amp;A session - You’ll be able to update your course based on the feedback or comments.</b></li>
                  </ul>
                  <br>
                  <b>Here’s a pro tip:</b> We encourage you to pre-sell your course before recording to test the market. As it goes along, you can continue to add new episodes or modify your course as you please!
                  "
                            onTogglePlay={() =>
                              this.setState({ tipPlaying: 'COURSE_PRICE' })
                            }
                            onReadAudioTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'AUDIO',
                              })
                            }
                            onReadWrittenTip={() =>
                              trackEvent('READ_TIP', {
                                SCREEN: 'EDIT_COURSE',
                                TYPE: 'TEXT',
                              })
                            }
                          />
                        )
                      }
                    />
                  </div>
                  <div
                    className={styles.formContainer}
                    id="course_refund_policy"
                  >
                    <FormInputText
                      label="Refund policy"
                      placeholder="Enter your refund policy"
                      description="You can change this anytime but we advise to offer an open and fair refund policy to your students."
                      long={true}
                      error={
                        error.category === 'EPISODE_REFUND_POLICY'
                          ? error.text
                          : null
                      }
                      value={course.refund_policy}
                      onChange={(e) => {
                        var courseCopy = course;
                        courseCopy.refund_policy = e.target.value;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              {sectionSelected === 'Testimonials' && (
                <>
                  <div
                    className={styles.formContainer}
                    id="course_testimonials"
                  >
                    <FormTestimonials
                      label="Course testimonials (optional)"
                      description="Build more trust with learners by sharing great testimonials about your course or skillset. Don’t have any to share yet? No worries, you can add them later."
                      namePlaceholder="Enter name"
                      testimonialPlaceholder="Enter testimonial"
                      error={testimonialError}
                      testimonials={testimonials}
                      addTestimonial={this.addTestimonial}
                      onNameChange={(e, testimonial) => {
                        this.handleTestimonialNameChange(e, testimonial);
                      }}
                      onTestimonialChange={(e, testimonial) => {
                        this.handleTestimonialChange(e, testimonial);
                      }}
                      onRemoveTestimonial={(testimonial) =>
                        this.removeTestimonial(testimonial)
                      }
                    />
                  </div>
                </>
              )}
              {sectionSelected === "FAQ's" && (
                <>
                  <div className={styles.formContainer} id="course_faqs">
                    <FormFAQs
                      label="FAQ's"
                      description="Adding FAQ’s will help learners to understand your course offering in more detail. Ask yourself, what would I want to know about a course before buying it? Then add those questions to your FAQs!"
                      questionPlaceholder="Enter question"
                      answerPlaceholder="Enter answer"
                      error={faqsError}
                      faqs={faqs}
                      addFaq={this.addFaq}
                      onQuestionChange={(e, faq) => {
                        this.handleFAQQuestionChange(e, faq);
                      }}
                      onAnswerChange={(e, faq) => {
                        this.handleFAQAnswerChange(e, faq);
                      }}
                      onRemoveFAQ={(faq) => this.removeFAQ(faq)}
                    />
                  </div>
                </>
              )}
              {sectionSelected === 'Emails' && (
                <>
                  <div
                    className={styles.formContainer}
                    id="course_purchase_email"
                  >
                    <FormCoursePurchaseEmail
                      label="New course purchase"
                      description="Get notified every time a new learner buys your course."
                      checkboxValue={course.enable_course_purchase_notification}
                      onCheckboxSelection={(value) => {
                        var courseCopy = { ...course };
                        courseCopy.enable_course_purchase_notification = value;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                    />
                  </div>
                  <div
                    className={styles.formContainer}
                    id="course_welcome_email"
                  >
                    <FormWelcomeEmail
                      label="Learner welcome email (optional)"
                      error={
                        error.category === 'COURSE_WELCOME_EMAIL'
                          ? error.text
                          : null
                      }
                      checkboxValue={course.learner_welcome_email.enabled}
                      messageValue={course.learner_welcome_email.message || ''}
                      onCheckboxSelection={(value) => {
                        var courseCopy = { ...course };
                        courseCopy.learner_welcome_email.enabled = value;
                        courseCopy.learner_welcome_email.message = null;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                      onTextChange={(e) => {
                        var courseCopy = { ...course };
                        courseCopy.learner_welcome_email.message =
                          e.target.value;
                        this.setState({
                          course: courseCopy,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              {sectionSelected === 'Creator Info' && (
                <Profile
                  userId={this.props.firebaseUser.id}
                  user={course.creatorInfo}
                  courseId={course.id}
                />
              )}
              {sectionSelected === 'Details' && (
                <div className={styles.formContainer}>
                  <FormURLCompletion
                    label="Course URL"
                    preFilledValue="learner.avid.fm/course/s/"
                    placeholder="Enter your course URL"
                    description="This should be a unique and concise name that reflects your course title. The URL will be your link to share with your followers to purchase the course."
                    error={error.category === 'EPISODE_URL' ? error.text : null}
                    value={course.slug}
                    onChange={(e) => {
                      var courseCopy = course;
                      courseCopy.slug = e.target.value;
                      this.setState({
                        course: courseCopy,
                      });
                    }}
                  />
                </div>
              )}
              <div onClick={isCourseUpdating ? null : this.nextStep}>
                {sectionSelected !== 'Creator Info' && (
                  <div
                    className={styles.saveButtonContainer}
                    onClick={isCourseUpdating ? null : this.save}
                  >
                    <Button text="Save changes" loading={isCourseUpdating} />
                  </div>
                )}
                <div
                  onClick={() =>
                    isCourseUpdating
                      ? null
                      : this.props.history.push(`/course/${course.id}`)
                  }
                >
                  <Button text="Cancel" secondary />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
