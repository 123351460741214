import React from 'react';

import styles from './EmptyBox.module.scss';

const EmptySection = (props) => {
  const { img, title, description, isEmptyEpisodesSection } = props;
  return (
    <div className={styles.emptyBox}>
      <img src={img} alt={title} />
      <p className={styles.title}>{title}</p>
      <p className={styles.description} style={{ opacity: 0.6 }}>
        {description}
      </p>
      {isEmptyEpisodesSection && (
        <p className={`${styles.description} ${styles.secondaryDescription}`}>
          Need some help creating episodes?{' '}
          <a
            href="https://www.notion.so/avidteam/Creator-Resources-f83b96b762a747f49af27c7381631f36"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.secondaryDescription}
          >
            Get episode tips
          </a>
        </p>
      )}
    </div>
  );
};

export default EmptySection;
