import React from 'react';
import styles from './Button.module.scss';

import { ReactComponent as Spinner } from '../../assets/img/spinner.svg';

const Button = (props) => {
  const {
    text,
    secondary,
    tertiary,
    green,
    red,
    big,
    small,
    zeroHorizontalPadding,
    icon,
    loading,
    disabled,
  } = props;
  let buttonStyle = styles.button;
  if (secondary) {
    buttonStyle = styles.buttonSecondary;
  }
  if (tertiary) {
    buttonStyle = styles.buttonTertiary;
  }
  if (small) {
    buttonStyle += ` ${styles.small}`;
  }
  if (big) {
    buttonStyle += ` ${styles.big}`;
  }
  if (green) {
    buttonStyle += ` ${styles.green}`;
  }
  if (red) {
    buttonStyle += ` ${styles.red}`;
  }
  if (zeroHorizontalPadding) {
    buttonStyle += ` ${styles.zeroHorizontalPadding}`;
  }
  if (disabled) {
    buttonStyle += ` ${styles.disabled}`;
  }

  if (loading) {
    return (
      <div className={buttonStyle}>
        <div style={{ height: '24px', width: '24px' }}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={buttonStyle}>
      {icon && <img src={icon} className={styles.icon} alt={text} />}
      {text}
    </div>
  );
};

export default Button;
