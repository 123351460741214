import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import styles from './AnalyticsDetails.module.scss';

import Header from '../../components/Header/Header';
import EmptyBox from '../../components/EmptyBox/EmptyBox';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import ListeningMusic from '../../assets/img/listening_music.svg';
import BackArrow from '../../assets/img/back_arrow.svg';

import {
  fetchCourse,
  fetchCoursePurchases,
  fetchInterestedUsers,
} from '../../services/FirebaseService/Database';

export default class AnalyticsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      analyticsFound: true,
      purchases: [],
    };
  }

  componentDidMount() {
    const { analyticsType } = this.props.match.params;

    if (analyticsType === 'purchased-course') {
      this.fetchPurchases();
    } else if (analyticsType === 'signed-up-emails') {
      this.fetchSignedUpEmails();
    } else {
      this.setState({ isLoading: false, analyticsFound: false });
    }
  }

  fetchPurchases = () => {
    this.setState({ isLoading: true });

    const { params } = this.props.match;
    const { state } = this.props.location;

    var purchasesPeriod = 'All';
    if (state && state.period) {
      purchasesPeriod = state.period;
    }

    fetchCourse(params.courseID)
      .then((course) => {
        if (!course) {
          this.setState({ isLoading: false, analyticsFound: true });
          return;
        }

        const courseCreators = Object.keys(course.creators);
        fetchCoursePurchases(params.courseID, purchasesPeriod, courseCreators)
          .then((purchases) => {
            this.setState({ purchases: purchases }, () =>
              this.setState({ isLoading: false, analyticsFound: true }),
            );
          })
          .catch((error) => {
            console.log('ERROR', error);
            this.setState({ isLoading: false, analyticsFound: true });
          });
      })
      .catch((error) => {
        console.log('ERROR', error);
        this.setState({ isLoading: false, analyticsFound: true });
      });
  };

  fetchSignedUpEmails = () => {
    this.setState({ isLoading: true });

    const { courseID } = this.props.match.params;
    const { state } = this.props.location;

    var emailsPeriod = 'All';
    if (state && state.period) {
      emailsPeriod = state.period;
    }

    fetchInterestedUsers(courseID)
      .then((users) => {
        if (users) {
          var usersIDs = Object.keys(users);
          var interestedUsers = usersIDs.map((id) => users[id]);

          var filteredUsers = [];
          interestedUsers.forEach((user) => {
            var userTimestampNumber = user.timestamp;
            var missingDigits = 13 - `${userTimestampNumber}`.length;
            if (missingDigits > 0) {
              userTimestampNumber =
                userTimestampNumber * Math.pow(10, missingDigits);
            }
            var userTimestamp = moment(userTimestampNumber).format(
              'YYYY-MM-DD',
            );
            var periodTimestamp = moment().format('YYYY-MM-DD');
            if (emailsPeriod === 'Last week') {
              periodTimestamp = moment()
                .subtract(1, 'weeks')
                .format('YYYY-MM-DD');
            } else if (emailsPeriod === 'Last month') {
              periodTimestamp = moment()
                .subtract(1, 'months')
                .format('YYYY-MM-DD');
            } else if (emailsPeriod === 'All') {
              periodTimestamp = moment('2019-01-01').format('YYYY-MM-DD');
            }

            if (moment(userTimestamp).isAfter(periodTimestamp)) {
              filteredUsers.push(user);
            }
            this.setState({ signedUpEmails: filteredUsers }, () =>
              this.setState({ isLoading: false, analyticsFound: true }),
            );
          });
        } else {
          this.setState({
            signedUpEmails: [],
            isLoading: false,
            analyticsFound: true,
          });
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
        this.setState({ isLoading: false, analyticsFound: true });
      });
  };

  renderSection = () => {
    const { analyticsType } = this.props.match.params;
    const { purchases, signedUpEmails } = this.state;

    if (analyticsType === 'purchased-course') {
      return (
        <>
          <h2 className={styles.title}>
            Purchased course{' '}
            {purchases.length > 0 ? ` (${purchases.length})` : ''}
          </h2>
          {!purchases ||
            (purchases.length === 0 && (
              <EmptyBox
                img={ListeningMusic}
                title="No purchases yet"
                description="Once there are purchases, they will show up here."
              />
            ))}
          {purchases && purchases.length > 0 && (
            <PurchasesTable purchases={purchases} />
          )}
        </>
      );
    } else if (analyticsType === 'signed-up-emails') {
      return (
        <>
          <h2 className={styles.title}>
            Signed up to receive emails{' '}
            {signedUpEmails.length > 0 ? ` (${signedUpEmails.length})` : ''}
          </h2>
          {!signedUpEmails ||
            (signedUpEmails.length === 0 && (
              <EmptyBox
                img={ListeningMusic}
                title="No emails yet"
                description="Once there are emails, they will show up here."
              />
            ))}
          {signedUpEmails && signedUpEmails.length > 0 && (
            <SignedUpEmailsTable signedUpEmails={signedUpEmails} />
          )}
        </>
      );
    }
  };

  render() {
    const { isLoading, analyticsFound } = this.state;

    if (isLoading) {
      return <LoadingScreen />;
    }

    if (!analyticsFound) {
      return <Redirect to="/my-courses" />;
    }

    return (
      <>
        <Header user />
        <div className={styles.container}>
          <div className={styles.back}>
            <div
              className={styles.backButton}
              onClick={() => this.props.history.goBack()}
            >
              <img src={BackArrow} alt="Back" />
              <span>Back</span>
            </div>
          </div>
          {this.renderSection()}
        </div>
      </>
    );
  }
}

const PurchasesTable = (props) => {
  const [pagination, setPagination] = useState(1);

  const { purchases } = props;
  var sortedPurchases = purchases.sort((a, b) =>
    a.timestamp < b.timestamp ? 1 : -1,
  );

  var visiblePurchases = [];
  var index = 0;

  if (sortedPurchases.length >= 20 * pagination) {
    index = 20 * pagination;
  } else {
    // Reached the end of the list
    index = sortedPurchases.length;
  }
  for (var i = 0; i < index; i++) {
    visiblePurchases.push(sortedPurchases[i]);
  }

  const showMore = () => {
    var newPagination = pagination + 1;
    setPagination(newPagination);
  };

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr className={styles.studentsTableHeader}>
            <th scope="col">NAME</th>
            <th scope="col">EMAIL</th>
            <th scope="col">DATE</th>
          </tr>
        </thead>
        <tbody>
          {visiblePurchases.map((purchase, index) => {
            var transactionTimestampNumber = purchase.timestamp;
            var missingDigits = 13 - `${transactionTimestampNumber}`.length;
            if (missingDigits > 0) {
              transactionTimestampNumber =
                transactionTimestampNumber * Math.pow(10, missingDigits);
            }
            var transactionTimestamp = moment(transactionTimestampNumber);
            var transactionTimestampFormatted = transactionTimestamp.format(
              'DD.MM.YY hh:mmA',
            );
            return (
              <React.Fragment
                key={`${purchase.user.email} ${purchase.user.email} ${transactionTimestampFormatted}`}
              >
                {index === 0 && (
                  <tr
                    className={styles.tablePadding}
                    style={{ height: '16px' }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                <tr className={styles.tr}>
                  <td data-label="NAME">{`${purchase.user.first_name} ${purchase.user.last_name}`}</td>
                  <td data-label="EMAIL">
                    <span className={styles.lightText}>
                      {purchase.user.email}
                    </span>
                  </td>
                  <td data-label="DATE">
                    <span className={styles.lightText}>
                      {transactionTimestampFormatted}
                    </span>
                  </td>
                </tr>
                {index === visiblePurchases.length - 1 && (
                  <tr
                    className={styles.tablePadding}
                    style={{ height: '16px !important' }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {visiblePurchases.length < sortedPurchases.length && (
        <div className={styles.showMore}>
          <div className={styles.showMoreButton} onClick={() => showMore()}>
            <span>
              Show{' '}
              {sortedPurchases.length - visiblePurchases.length > 20
                ? 20
                : sortedPurchases.length - visiblePurchases.length}{' '}
              more
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const SignedUpEmailsTable = (props) => {
  const [pagination, setPagination] = useState(1);

  const { signedUpEmails } = props;
  var sortedEmails = signedUpEmails.sort((a, b) =>
    a.timestamp < b.timestamp ? 1 : -1,
  );

  var visibleEmails = [];
  var index = 0;

  if (sortedEmails.length >= 20 * pagination) {
    index = 20 * pagination;
  } else {
    // Reached the end of the list
    index = sortedEmails.length;
  }
  for (var i = 0; i < index; i++) {
    visibleEmails.push(sortedEmails[i]);
  }

  const showMore = () => {
    var newPagination = pagination + 1;
    setPagination(newPagination);
  };

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr className={styles.studentsTableHeader}>
            <th scope="col">EMAIL</th>
            <th scope="col">DATE</th>
          </tr>
        </thead>
        <tbody>
          {visibleEmails.map((email, index) => {
            var transactionTimestampNumber = email.timestamp;
            var missingDigits = 13 - `${transactionTimestampNumber}`.length;
            if (missingDigits > 0) {
              transactionTimestampNumber =
                transactionTimestampNumber * Math.pow(10, missingDigits);
            }
            var transactionTimestamp = moment(transactionTimestampNumber);
            var transactionTimestampFormatted = transactionTimestamp.format(
              'DD.MM.YY hh:mmA',
            );
            return (
              <React.Fragment key={email.id}>
                {index === 0 && (
                  <tr
                    className={styles.tablePadding}
                    style={{ height: '16px' }}
                  >
                    <td></td>
                    <td></td>
                  </tr>
                )}
                <tr className={styles.tr}>
                  <td data-label="EMAIL">
                    <span className={styles.lightText}>{email.email}</span>
                  </td>
                  <td data-label="DATE">
                    <span className={styles.lightText}>
                      {transactionTimestampFormatted}
                    </span>
                  </td>
                </tr>
                {index === visibleEmails.length - 1 && (
                  <tr
                    className={styles.tablePadding}
                    style={{ height: '16px !important' }}
                  >
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {visibleEmails.length < sortedEmails.length && (
        <div className={styles.showMore}>
          <div className={styles.showMoreButton} onClick={() => showMore()}>
            <span>
              Show{' '}
              {sortedEmails.length - visibleEmails.length > 20
                ? 20
                : sortedEmails.length - visibleEmails.length}{' '}
              more
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
