import React from 'react';

import styles from './TimePicker.module.scss';

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: this.props.hours,
      minutes: this.props.minutes,
      seconds: this.props.seconds,
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      const { onHideTimePicker } = this.props;
      if (onHideTimePicker) {
        onHideTimePicker();
      }
    }
  }

  render() {
    const { onApply, onValueChange } = this.props;
    const { hours, minutes, seconds } = this.state;

    var timePickerValues = [...Array(60).keys()];
    for (var i = 0; i < timePickerValues.length; i++) {
      timePickerValues[i] = String(timePickerValues[i]).padStart(2, '0');
    }
    return (
      <div className={styles.timePickerContainer} ref={this.wrapperRef}>
        <div className={styles.timePickerComponents}>
          <div className={styles.timePickerComponentLabel}>Hours</div>
          <div className={styles.timePickerComponentLabel}>Minutes</div>
          <div className={styles.timePickerComponentLabel}>Seconds</div>
        </div>
        <div className={styles.timePicker}>
          <div className={styles.timePickerElement}>
            <ul>
              {timePickerValues.map((item) => {
                return (
                  <li
                    onClick={() => {
                      this.setState({
                        hours: item,
                      });
                      onValueChange(item, minutes, seconds);
                    }}
                    className={hours === item ? styles.selected : null}
                    key={item}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.timePickerElement}>
            <ul>
              {timePickerValues.map((item) => {
                return (
                  <li
                    onClick={() => {
                      this.setState({
                        minutes: item,
                      });
                      onValueChange(hours, item, seconds);
                    }}
                    className={minutes === item ? styles.selected : null}
                    key={item}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.timePickerElement}>
            <ul>
              {timePickerValues.map((item) => {
                return (
                  <li
                    onClick={() => {
                      this.setState({
                        seconds: item,
                      });
                      onValueChange(hours, minutes, item);
                    }}
                    className={seconds === item ? styles.selected : null}
                    key={item}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={styles.applyContainer}>
          <div
            className={styles.apply}
            onClick={() => onApply(hours, minutes, seconds)}
          >
            <span>Apply</span>
          </div>
        </div>
      </div>
    );
  }
}
