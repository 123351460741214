import React from 'react';
import styles from './FormPricing.module.scss';

import SelectSearch from '../../SelectSearch/SelectSearch';
import SelectSectionsSearch from '../../SelectSectionsSearch/SelectSectionsSearch';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

const getPrice = value => {
  if (value === null || value === undefined) {
    return 'Select a price';
  }

  return value === 0 ? '0.00 (Free)' : value;
};

const FormPricing = (props) => {
  const {
    label,
    tip,
    currencySelected,
    priceSelected,
    preSellPriceSelected,
    onCurrencySelect,
    onPriceSelect,
    onPreSellPriceSelect,
    error,
  } = props;

  var currencyOptions = [
    {
      id: 0,
      value: '£ (GBP)',
    },
    {
      id: 1,
      value: '$ (USD)',
    },
    {
      id: 2,
      value: '€ (EUR)',
    },
  ];

  var priceOptions = [
    {
      title: 'Most popular',
      options: [
        {
          id: 0,
          value: '49.99',
        },
        {
          id: 1,
          value: '99.99',
        },
        {
          id: 2,
          value: '129.99',
        },
        {
          id: 3,
          value: '159.99',
        },
      ],
    },
    {
      title: 'All',
      options: [
        {
          id: 0,
          value: '0.00 (Free)',
        },
        {
          id: 1,
          value: '0.99',
        },
        {
          id: 2,
          value: '1.99',
        },
        {
          id: 3,
          value: '2.99',
        },
        {
          id: 4,
          value: '3.99',
        },
        {
          id: 5,
          value: '4.99',
        },
        {
          id: 6,
          value: '5.99',
        },
        {
          id: 7,
          value: '6.99',
        },
        {
          id: 8,
          value: '7.99',
        },
        {
          id: 9,
          value: '8.99',
        },
        {
          id: 10,
          value: '9.99',
        },
        {
          id: 11,
          value: '10.99',
        },
        {
          id: 12,
          value: '11.99',
        },
        {
          id: 13,
          value: '12.99',
        },
        {
          id: 14,
          value: '13.99',
        },
        {
          id: 15,
          value: '14.99',
        },
        {
          id: 16,
          value: '15.99',
        },
        {
          id: 17,
          value: '16.99',
        },
        {
          id: 18,
          value: '17.99',
        },
        {
          id: 19,
          value: '18.99',
        },
        {
          id: 20,
          value: '19.99',
        },
        {
          id: 21,
          value: '20.99',
        },
        {
          id: 22,
          value: '21.99',
        },
        {
          id: 23,
          value: '22.99',
        },
        {
          id: 24,
          value: '23.99',
        },
        {
          id: 25,
          value: '24.99',
        },
        {
          id: 26,
          value: '25.99',
        },
        {
          id: 27,
          value: '26.99',
        },
        {
          id: 28,
          value: '27.99',
        },
        {
          id: 29,
          value: '28.99',
        },
        {
          id: 30,
          value: '29.99',
        },
        {
          id: 31,
          value: '30.99',
        },
        {
          id: 32,
          value: '31.99',
        },
        {
          id: 33,
          value: '32.99',
        },
        {
          id: 34,
          value: '33.99',
        },
        {
          id: 35,
          value: '34.99',
        },
        {
          id: 36,
          value: '35.99',
        },
        {
          id: 37,
          value: '36.99',
        },
        {
          id: 38,
          value: '37.99',
        },
        {
          id: 39,
          value: '38.99',
        },
        {
          id: 40,
          value: '39.99',
        },
        {
          id: 41,
          value: '40.99',
        },
        {
          id: 42,
          value: '41.99',
        },
        {
          id: 43,
          value: '42.99',
        },
        {
          id: 44,
          value: '43.99',
        },
        {
          id: 45,
          value: '44.99',
        },
        {
          id: 46,
          value: '45.99',
        },
        {
          id: 47,
          value: '46.99',
        },
        {
          id: 48,
          value: '47.99',
        },
        {
          id: 49,
          value: '48.99',
        },
        {
          id: 50,
          value: '49.99',
        },
        {
          id: 51,
          value: '54.99',
        },
        {
          id: 52,
          value: '59.99',
        },
        {
          id: 53,
          value: '64.99',
        },
        {
          id: 54,
          value: '69.99',
        },
        {
          id: 55,
          value: '74.99',
        },
        {
          id: 56,
          value: '79.99',
        },
        {
          id: 57,
          value: '84.99',
        },
        {
          id: 58,
          value: '89.99',
        },
        {
          id: 59,
          value: '94.99',
        },
        {
          id: 60,
          value: '99.99',
        },
        {
          id: 61,
          value: '109.99',
        },
        {
          id: 62,
          value: '119.99',
        },
        {
          id: 63,
          value: '124.99',
        },
        {
          id: 64,
          value: '129.99',
        },
        {
          id: 65,
          value: '139.99',
        },
        {
          id: 66,
          value: '149.99',
        },
        {
          id: 67,
          value: '159.99',
        },
        {
          id: 68,
          value: '169.99',
        },
        {
          id: 69,
          value: '174.99',
        },
        {
          id: 70,
          value: '179.99',
        },
        {
          id: 71,
          value: '189.99',
        },
        {
          id: 72,
          value: '199.99',
        },
        {
          id: 73,
          value: '209.99',
        },
        {
          id: 74,
          value: '219.99',
        },
        {
          id: 75,
          value: '229.99',
        },
        {
          id: 76,
          value: '239.99',
        },
        {
          id: 77,
          value: '249.99',
        },
        {
          id: 78,
          value: '299.99',
        },
        {
          id: 79,
          value: '349.99',
        },
        {
          id: 80,
          value: '399.99',
        },
        {
          id: 81,
          value: '449.99',
        },
        {
          id: 82,
          value: '499.99',
        },
        {
          id: 83,
          value: '599.99',
        },
        {
          id: 84,
          value: '699.99',
        },
        {
          id: 85,
          value: '799.99',
        },
        {
          id: 86,
          value: '999.99',
        },
        {
          id: 87,
          value: '999.99',
        },
      ],
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.selectElements}>
        <div className={styles.selectCurrency}>
          <p className={styles.label}>{label}</p>
          <SelectSearch
            options={currencyOptions}
            selectedOption={
              currencySelected ? currencySelected : 'Select a currency'
            }
            onSelect={(option) => {
              if (onCurrencySelect) {
                onCurrencySelect(option);
              }
            }}
          />
        </div>
        <div className={styles.selectPrice}>
          <p className={styles.secondaryLabel}>Full price</p>
          <SelectSectionsSearch
            options={priceOptions}
            selectedOption={getPrice(priceSelected)}
            onSelect={(option) => {
              if (onPriceSelect) {
                onPriceSelect(option);
              }
            }}
          />
        </div>
        <div className={styles.selectPrice}>
          <p className={styles.secondaryLabel}>
            Pre-sell price{' '}
            <span className={styles.optionalLabel}> (Optional)</span>
          </p>
          <SelectSectionsSearch
            options={priceOptions}
            selectedOption={getPrice(preSellPriceSelected)}
            onSelect={(option) => {
              if (onPreSellPriceSelect) {
                onPreSellPriceSelect(option);
              }
            }}
          />
        </div>
      </div>
      {error && (
        <div className="errorContainer">
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
      {tip && <div className={styles.tipContainer}>{tip}</div>}
    </div>
  );
};

export default FormPricing;
