import React from 'react';

import styles from './FormInputText.module.scss';

import Input from '../../Input/Input';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

const FormInputText = (props) => {
  const {
    disabled,
    label,
    placeholder,
    description,
    type,
    long,
    value,
    tip,
    onChange,
    error,
  } = props;

  var inputType = type;

  if (type) {
    inputType = type;
  }

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      {description && <p className={styles.description}>{description}</p>}
      <Input
        hideLabel={true}
        disabled={disabled}
        type={inputType}
        placeholder={placeholder}
        long={long}
        value={value}
        onChange={(e) => (onChange ? onChange(e) : null)}
        style={{ marginBottom: 0, marginTop: description ? null : '8px' }}
      />
      {error && (
        <div className="errorContainer">
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
      {tip && <div className={styles.tipContainer}>{tip}</div>}
    </div>
  );
};

export default FormInputText;
