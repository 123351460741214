import React from 'react';
import styles from './AnalyticsTable.module.scss';

import { ReactComponent as Arrow } from '../../assets/img/arrow_right.svg';

const AnalyticsTable = (props) => {
  const { sections, onSectionClick } = props;
  return (
    <table className={styles.table}>
      <col className={styles.eventCol} />
      <col className={styles.totalCol} />
      <thead>
        <tr className={styles.header}>
          <th scope="col">EVENT</th>
          <th scope="col">TOTAL</th>
        </tr>
      </thead>
      <tbody>
        {sections.map((section, index) => {
          return (
            <>
              {index === 0 && (
                <tr className={styles.tablePadding} style={{ height: '16px' }}>
                  <td></td>
                  <td></td>
                </tr>
              )}
              <tr
                key={section.name}
                className={
                  section.action
                    ? `${styles.rowAction} ${styles.tr}`
                    : styles.tr
                }
                onClick={() =>
                  onSectionClick ? onSectionClick(section) : null
                }
              >
                <td data-label="EVENT">
                  <span style={{ marginRight: '5px' }}>{section.name}</span>
                  {section.secondaryName && (
                    <span
                      className={styles.secondaryValue}
                      style={{ fontWeight: 'unset' }}
                    >
                      {section.secondaryName}
                    </span>
                  )}
                </td>
                <td data-label="TOTAL">
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <span className={styles.value}>{section.value}</span>
                      {section.secondaryValue && (
                        <span className={styles.secondaryValue}>
                          {section.secondaryValue}
                        </span>
                      )}
                    </div>
                    {section.action && <Arrow />}
                  </div>
                </td>
              </tr>
              {index === sections.length - 1 && (
                <tr
                  className={styles.tablePadding}
                  style={{ height: '16px !important' }}
                >
                  <td></td>
                  <td></td>
                </tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default AnalyticsTable;
