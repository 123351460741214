const SERVER_URL = 'https://avid-back-end.herokuapp.com';

/**
 * Send welcome email to user
 * @param {string} user - User object
 * @return {Promise} Void if success
 */
const sendSignUpEmail = (user) => {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}/emails/send?type=welcome`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: user.email, name: user.name }),
    })
      .then(() => {
        console.log('DONE');
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { sendSignUpEmail };
