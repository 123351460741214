import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.scss';

import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import { ReactComponent as Menu } from '../../assets/img/menu.svg';

import { signOut } from '../../services/FirebaseService/Auth';

const Header = (props) => {
  const { user } = props;
  const [isMenuVisible, showMenu] = useState(false);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        showMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <header>
      <div className={styles.headerContent}>
        {user && (
          <Link to="/my-courses">
            <Logo className={styles.logo} />
          </Link>
        )}
        {!user && (
          <a href="https://www.avid.fm">
            <Logo className={styles.logo} />
          </a>
        )}

        {user && (
          <div className={styles.userContainer}>
            <div
              className={styles.profileImageContainer}
              onClick={() => showMenu(!isMenuVisible)}
            >
              <Menu />
            </div>
            {isMenuVisible && (
              <ul className={styles.menu} ref={wrapperRef}>
                <Link to="/my-courses">
                  <li>My courses</li>
                </Link>
                <Link to="/settings">
                  <li>Settings</li>
                </Link>
                <li
                  onClick={() => {
                    signOut()
                      .then(() => {
                        window.location.reload();
                      })
                      .catch((error) => console.log(error));
                  }}
                >
                  Log out
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
