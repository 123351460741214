import React from 'react';
import styles from './SelectSectionsSearch.module.scss';

import Arrow from '../../assets/img/select_arrow_down.svg';

export default class SelectSectionsSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: false,
      query: '',
      selectedOption: this.props.selectedOption,
      options: this.props.options,
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showList: false });
    }
  }

  handleSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  render() {
    const { showList, selectedOption, query, options } = this.state;
    const { onSelect } = this.props;

    var searchedValues = options;
    if (options) {
      var valuesFound = [];
      options.forEach((option) => {
        var sectionOptions = option.options;
        var filteredOptions = sectionOptions.filter((item) => {
          return item.value.toUpperCase().includes(query.toUpperCase());
        });
        if (filteredOptions.length > 0) {
          var optionCopy = Object.assign({}, option);
          optionCopy.options = filteredOptions;
          valuesFound.push(optionCopy);
        }
      });
      searchedValues = valuesFound;
    }

    return (
      <div className={styles.container} ref={this.wrapperRef}>
        <div
          className={styles.select}
          onClick={() => this.setState({ showList: !showList })}
        >
          <span className={styles.selectOption}>{selectedOption}</span>
          <img src={Arrow} alt="Select" className={styles.selectArrow} />
        </div>
        {showList && (
          <div className={styles.dropdownContainer}>
            <input
              type="text"
              placeholder="Search"
              className={styles.inputSearch}
              onChange={(e) => this.handleSearchChange(e)}
            />
            {searchedValues.map((section, index) => {
              return (
                <div key={section.title}>
                  <p className={styles.sectionTitle}>{section.title}</p>
                  <ul className={styles.list}>
                    {section.options.map((item) => {
                      return (
                        <li
                          className={styles.listItem}
                          key={item.id}
                          onClick={() => {
                            this.setState({
                              selectedOption: item.value,
                              query: '',
                              showList: false,
                            });

                            if (onSelect) {
                              onSelect(item.value);
                            }
                          }}
                        >
                          {item.value}
                        </li>
                      );
                    })}
                  </ul>
                  {index !== searchedValues.length - 1 && (
                    <div className={styles.sectionSeparator} />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
