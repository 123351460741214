import React from 'react';

import styles from './FormTestimonials.module.scss';

import Input from '../../Input/Input';

import Plus from '../../../assets/img/plus_secondary.svg';
import { ReactComponent as Warning } from '../../../assets/img/warning.svg';
import { ReactComponent as Bin } from '../../../assets/img/bin.svg';

const FormTestimonials = (props) => {
  const {
    label,
    description,
    namePlaceholder,
    testimonialPlaceholder,
    addTestimonial,
    testimonials,
    onNameChange,
    onTestimonialChange,
    onRemoveTestimonial,
    error,
  } = props;
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.description}>{description}</p>
      {testimonials.map((item) => {
        var errorMessage = null;
        var errorType = null;
        if (error) {
          if (error.id === item.id) {
            errorMessage = error.error;
            errorType = error.type;
          }
        }
        return (
          <Testimonial
            namePlaceholder={namePlaceholder}
            testimonialPlaceholder={testimonialPlaceholder}
            nameValue={item.name}
            testimonialValue={item.testimonial}
            key={item.id}
            onNameChange={(e) => onNameChange(e, item)}
            onTestimonialChange={(e) => onTestimonialChange(e, item)}
            onRemoveTestimonial={() =>
              onRemoveTestimonial ? onRemoveTestimonial(item) : null
            }
            error={errorMessage}
            errorType={errorType}
          />
        );
      })}
      <div className={styles.addTestimonial} onClick={addTestimonial}>
        <img src={Plus} alt="Add testimonial" />
        <span className={styles.addTestimoniaLabel}>Add testimonial</span>
      </div>
    </div>
  );
};

export default FormTestimonials;

const Testimonial = (props) => {
  const {
    namePlaceholder,
    testimonialPlaceholder,
    nameValue,
    testimonialValue,
    onNameChange,
    onTestimonialChange,
    onRemoveTestimonial,
    error,
    errorType,
  } = props;
  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputContentContainer}>
        <Input
          type="text"
          hideLabel={true}
          placeholder={namePlaceholder}
          value={nameValue}
          onChange={(e) => onNameChange(e)}
          inputStyle={{ paddingRight: '50px' }}
        />
        <div className={styles.removeButton}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (onRemoveTestimonial) {
                onRemoveTestimonial();
              }
            }}
          >
            <Bin />
          </div>
        </div>
      </div>

      {errorType === 'NAME' && (
        <div className="errorContainer" style={{ marginBottom: '16px' }}>
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
      <Input
        type="text"
        placeholder={testimonialPlaceholder}
        value={testimonialValue}
        onChange={(e) => onTestimonialChange(e)}
        long
        hideLabel
      />
      {errorType === 'TESTIMONIAL' && (
        <div className="errorContainer" style={{ marginBottom: '16px' }}>
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};
