import React from 'react';
import FormInputText from '../../../components/Form/FormInputText/FormInputText';
import Button from '../../../components/Button/Button';
import FormSocialAccounts from '../../../components/Form/FormSocialAccounts/FormSocialAccounts';
import FormUploadImage from '../../../components/Form/FormUploadImage/FormUploadImage';
import styles from '../Settings.module.scss';
import { updatedCourseCreatorInfo } from '../../../services/FirebaseService/Database';
import { storeFile } from '../../../services/FirebaseService/Storage';
import Toast from '../../../components/Toast/Toast';

const SOCIAL_ACCOUNTS_PLACEHOLDER = {
  twitter: {
    type: 'twitter',
    placeholder: 'Twitter (https://twitter.com/yourname)',
    url: '',
  },
  facebook: {
    type: 'facebook',
    placeholder: 'Facebook (https://facebook.com/yourname)',
    url: '',
  },
  linkedin: {
    type: 'linkedin',
    placeholder: 'LinkedIn (https://linkedin.com/yourname)',
    url: '',
  },
  instagram: {
    type: 'instagram',
    placeholder: 'Instagram (https://instagram.com/yourname)',
    url: '',
  },
  youtube: {
    type: 'youtube',
    placeholder: 'Youtube (https://youtube.com/yourname)',
    url: '',
  },
  website: {
    type: 'website',
    placeholder: 'Website (https://yourwebsite.com)',
    url: '',
  },
};

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      isLoading: false,
      authorProfile: {
        error: {
          text: '',
          category: '',
        },
        socialAccountsErrors: [],
        socialAccounts: {},
        image: { url: '' },
      },
    };
  }

  componentDidMount() {
    const { user = {} } = this.props;

    const doc = {
      ...user,
      full_name: user.first_name ? `${user.first_name || ''} ${user.last_name || ''}` : '',
      socialAccountsErrors: [],
      socialAccounts: SOCIAL_ACCOUNTS_PLACEHOLDER,
    };

    if (doc.socials) {
      Object.keys(doc.socials).map(
        (key) => (doc.socialAccounts[key] = doc.socials[key]),
      );
    } else {
      doc.socials = {};
    }

    this.setState((prevState) => ({
      authorProfile: {
        ...prevState.authorProfile,
        ...doc,
      },
    }));
  }

  scrollToElement = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView();
    }
  };

  handleSave = () => {
    const { authorProfile } = this.state;

    const splittedName = authorProfile.full_name.split(' ');
    const firstName = splittedName[0];
    const lastName = splittedName.slice(1, splittedName.length).join(' ');

    // eslint-disable-next-line
    var authorProfileCopy = authorProfile;
    authorProfileCopy.first_name = firstName;
    authorProfileCopy.last_name = lastName;
    this.setState({
      authorProfile: authorProfileCopy,
    });

    if (authorProfile.bio !== '') {
      // eslint-disable-next-line
      var authorProfileCopy = authorProfile;
      authorProfileCopy.error = {
        text: '',
        category: '',
      };
      this.setState({
        authorProfile: authorProfileCopy,
      });
    }

    const errors = Object.keys(authorProfile.socialAccounts)
      // eslint-disable-next-line
      .map((key) => {
        const socialAccount = authorProfile.socialAccounts[key];
        if (socialAccount.url !== '') {
          const valid = /^(http|https):\/\/[^ "]+$/.test(socialAccount.url);
          if (!valid) {
            return {
              type: socialAccount.type,
              text: 'Please enter a valid URL',
            };
          }
          // eslint-disable-next-line
          return;
        }
      })
      .filter((item) => item !== undefined);

    if (errors.length > 0) {
      // eslint-disable-next-line
      var authorProfileCopy = authorProfile;
      authorProfileCopy.socialAccountsErrors = errors;
      this.setState({
        authorProfile: authorProfileCopy,
      });

      this.scrollToElement('creator_socials');
      return;
    }

    const authorProfileObjectCopy = authorProfile;
    const socialsKeys = Object.keys(authorProfile.socialAccounts);
    socialsKeys.forEach((key) => {
      if (
        authorProfile.socialAccounts[key] &&
        authorProfile.socialAccounts[key].url !== ''
      ) {
        authorProfileObjectCopy.socials[key] =
          authorProfile.socialAccounts[key];
      } else {
        authorProfileObjectCopy.socials[key] = null;
      }
    });
    authorProfileObjectCopy.error = {
      text: '',
      category: '',
    };
    authorProfileObjectCopy.socialAccountsErrors = [];

    const { userId, courseId } = this.props;

    this.setState(
      { isLoading: true, authorProfile: authorProfileObjectCopy },
      () => {
        if (authorProfile.loadedImage) {
          storeFile(
            `creators/${userId}/courses/${courseId}/images/profile_picture`,
            authorProfile.loadedImage,
          ).then((url) => {
            if (url) {
              authorProfileObjectCopy.image.url = url;
              this.setState({ authorProfile: authorProfileObjectCopy });
              updatedCourseCreatorInfo(courseId, authorProfileObjectCopy)
                .then(() => window.location.reload())
                .catch((error) =>
                  this.setState({ isLoading: false, showToast: true }),
                );
            }
          });
        } else {
          updatedCourseCreatorInfo(courseId, authorProfileObjectCopy)
            .then(() => window.location.reload())
            .catch((error) => {
              this.setState({ isLoading: false, showToast: true })
            });
        }
      },
    );
  };

  renderContent = () => {
    const { authorProfile } = this.state;

    return (
      <div className={styles.formsContainer}>
        <div className={styles.formContainer} id="creator_name">
          <FormInputText
            label="Name"
            placeholder="Enter creator's full name"
            error={
              authorProfile.error.category === 'NAME'
                ? authorProfile.error.text
                : null
            }
            value={authorProfile.full_name}
            onChange={(e) => {
              const authorProfileCopy = authorProfile;
              authorProfileCopy.full_name = e.target.value;
              this.setState({
                authorProfile: authorProfileCopy,
              });
            }}
          />
        </div>
        <div className={styles.formContainer} id="creator_bio">
          <FormInputText
            label="Bio"
            placeholder="Enter creator's bio"
            long
            error={
              authorProfile.error.category === 'BIO'
                ? authorProfile.error.text
                : null
            }
            value={authorProfile.bio}
            onChange={(e) => {
              const authorProfileCopy = authorProfile;
              authorProfileCopy.bio = e.target.value;
              this.setState({
                authorProfile: authorProfileCopy,
              });
            }}
          />
        </div>
        <div className={styles.formContainer}>
          <FormUploadImage
            label="Profile picture"
            description="The recommended image formats are PNG and JPG with the width and height of 800x800 pixels."
            buttonTitle="Upload your picture"
            image={authorProfile.loadedImage || authorProfile.image.url}
            onImageLoaded={(image) => {
              const authorProfileCopy = authorProfile;
              authorProfileCopy.loadedImage = image;
              this.setState({
                authorProfile: authorProfileCopy,
              });
            }}
          />
        </div>
        <div className={styles.formContainer} id="creator_socials">
          <FormSocialAccounts
            label="Social media accounts"
            socialAccounts={authorProfile.socialAccounts}
            error={authorProfile.socialAccountsErrors}
            onChange={(social, e) => {
              const authorProfileCopy = JSON.parse(
                JSON.stringify(authorProfile),
              );
              authorProfileCopy.socialAccounts[social].url = e.target.value;
              this.setState({
                authorProfile: authorProfileCopy,
              });
            }}
          />
        </div>
        <div onClick={this.handleSave} className={styles.saveButtonContainer}>
          <Button text="Save changes" small />
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, showToast } = this.state;

    return (
      <>
        {isLoading && <Toast text="Updating creator info..." />}
        {showToast && (
          <Toast text="An error occured, please try again later." />
        )}
        {this.renderContent()}
      </>
    );
  }
}

export default Profile;
