import React from 'react';
import styles from './InputCompletion.module.scss';

const InputCompletion = (props) => {
  const { placeholder, preFilledValue, value, onChange, error } = props;
  return (
    <div className={styles.inputContainer}>
      <div className={styles.valuesContainer}>
        <span className={styles.preFilledValue}>{preFilledValue}</span>
        <input
          placeholder={placeholder}
          type="text"
          className={styles.input}
          value={value}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
      {error && <p className="errorLabel">{error}</p>}
    </div>
  );
};

export default InputCompletion;
