import React from 'react';

import styles from './FormChangePassword.module.scss';

import Input from '../../Input/Input';

import { ReactComponent as Warning } from '../../../assets/img/warning.svg';

const FormChangePassword = (props) => {
  const {
    currentPasswordValue,
    newPasswordValue,
    onCurrentPasswordChange,
    onNewPasswordChange,
    error,
  } = props;

  return (
    <div className={styles.container}>
      <p className={styles.label}>Change password</p>
      <Input
        type="password"
        placeholder="Enter your current password"
        value={currentPasswordValue}
        onChange={(e) =>
          onCurrentPasswordChange ? onCurrentPasswordChange(e) : null
        }
      />
      <Input
        hideLabel={true}
        type="password"
        placeholder="Enter your new password"
        value={newPasswordValue}
        onChange={(e) => (onNewPasswordChange ? onNewPasswordChange(e) : null)}
        style={{ marginBottom: 0 }}
      />
      {error && (
        <div className="errorContainer" style={{ marginTop: '16px' }}>
          <Warning />
          <span className="errorMessage">{error}</span>
        </div>
      )}
    </div>
  );
};

export default FormChangePassword;
