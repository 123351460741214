import * as firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAsa4VgYtOj78MgHzVSGOToW_RlfWNY7O4',
  authDomain: 'avid-8d85c.firebaseapp.com',
  databaseURL: 'https://avid-8d85c.firebaseio.com',
  projectId: 'avid-8d85c',
  storageBucket: 'avid-8d85c.appspot.com',
  messagingSenderId: '884888751157',
  appId: '1:884888751157:web:cf3e7a243a12b49fd9757c',
  measurementId: 'G-VCBPGDJNKZ',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
