import React from 'react';
import styles from './LoadingScreen.module.scss';
import Logo from '../../assets/img/logo.svg';

const LoadingScreen = (props) => {
  const { text } = props;
  return (
    <div className={styles.loadingScreen}>
      <img src={Logo} alt="AVID" />
      <p>{text}</p>
    </div>
  );
};

export default LoadingScreen;
